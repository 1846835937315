import { combineReducers } from "redux";
import home from "./home";
import company_details from "./company_details";
import client from "./client";
import project_details from "./project_details";
import benchmark_details from "./benchmark_details";
import special_report_details from "./specialreport_details";
import market_forecast_details from "./market_forecast_details";
import market_landscape_details from "./market_landscape_details";
import customer_study_details from "./customer_study_details";
import ecosystem_details from "./ecosystem_details";

const rootReducer = combineReducers({
  home,
  company_details,
  client,
  project_details,
  benchmark_details,
  market_forecast_details,
  market_landscape_details,
  special_report_details,
  customer_study_details,
  ecosystem_details,
});

export default rootReducer;
