import { combineReducers } from "redux";
import * as DETAILS_REF from "./reducerReference/projectDetailsReference";

const projectCategories = DETAILS_REF.projectCategories;
const projectSegments = DETAILS_REF.projectSegments;
const projectDetails = DETAILS_REF.projectDetails;
const categories = DETAILS_REF.categories;
const projectContent = DETAILS_REF.projectContent;
const projectTemplates = DETAILS_REF.projectTemplates;
const projectTransactionDetails = DETAILS_REF.projectTransactionDetails;

const projectDetailsReducer = combineReducers({
  projectCategories,
  projectSegments,
  projectDetails,
  categories,
  projectContent,
  projectTemplates,
  projectTransactionDetails,
});

export default projectDetailsReducer;
