import React, { useState, useEffect } from "react";
import login_building from "../Images/login_building.png";
import login_map from "../Images/login_map.png";
import logo from "../Images/INSIGHTCENTER@2x.png";
import TBRLogo from "../Images/TBRLogo";
import constants from "../Utils/constants";
import { Container, Row, Form } from "reactstrap";
import { UserServices } from "../Services/User";
import Notification from "../Components/Notification";
import { formatRoute } from "react-router-named-routes";
import DocumentTitle from "../Utils/DocumentTitle";
import Toast from "../Utils/Toast";
import { useHistory, useParams } from "react-router-dom";

function VerifyEmail() {
  const [apiError, setApiError] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const history = useHistory();
  const { code } = useParams();

  useEffect(() => {
    DocumentTitle("Account Verification");

    if (localStorage.getItem("token")) {
      UserServices.getUserLogout()
        .then(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("SSO");
        })
        .catch((error) => {
          Toast(
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
            "error"
          );
        });
    }

    UserServices.verifyEmail(code)
      .then(() => {
        setIsVerified(true);
        setSuccessMessage(<>{constants.VERIFY_EMAIL.SUCCESS}</>);
        setTimeout(() => {
          history.push(
            formatRoute(constants.APPLICATION_ROUTE.RESET_PASSWORD.ROUTE, {
              code: code,
            })
          );
        }, 3000);
      })
      .catch((error) => {
        if (error.status === 451) {
          setIsVerified(true);
          setSuccessMessage(<>{constants.VERIFY_EMAIL.SUCCESS2}</>);
          setTimeout(() => {
            history.push(
              formatRoute(constants.APPLICATION_ROUTE.RESET_PASSWORD.ROUTE, {
                code: code,
              })
            );
          }, 3000);
        } else if (error.status === 450) {
          setApiError(
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG
          );
          setTimeout(() => {
            history.push(
              formatRoute(constants.APPLICATION_ROUTE.LOGIN.ROUTE, {})
            );
          }, 3000);
        } else {
          setApiError(
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG
          );
        }
      });
  }, [code, history]);

  return (
    <div className="wrapper login_page">
      <main>
        <Container fluid>
          <Row>
            <aside className="col-md-6 px-0 hide-in-mobile">
              <div className="login_left">
                <figure className="login_banner">
                  <img
                    src={login_building}
                    className="img-fluid"
                    alt="login banner"
                    title="login banner"
                  />
                </figure>
                <a href={process.env.PUBLIC_URL + "/"}>
                  <TBRLogo />
                  <span>{constants.PAGE.LOGIN.LOGO_TEXT}</span>
                </a>
              </div>
            </aside>
            <aside className="col-md-6 px-0">
              <div className="login_right">
                <figure>
                  <img src={login_map} className="img-fluid" alt="map Banner" />
                </figure>
                <Form method="post">
                  <span className="login_formlogos">
                    <img src={logo} className="img-fluid" alt="logo" />
                  </span>
                  {apiError && (
                    <Notification color={"danger"} message={apiError} />
                  )}
                  {isVerified && (
                    <h2 className={`verify_email_success`}>{successMessage}</h2>
                  )}
                </Form>
              </div>
            </aside>
          </Row>
        </Container>
      </main>
    </div>
  );
}

export default VerifyEmail;
