import { combineReducers } from "redux";
import * as DETAILS_REF from "./reducerReference/marketForecastDetailsReference";

const marketForecastCategories = DETAILS_REF.marketForecastCategories;
const marketForecastSegments = DETAILS_REF.marketForecastSegments;
const marketForecastDetails = DETAILS_REF.marketForecastDetails;
const categories = DETAILS_REF.categories;
const marketForecastContent = DETAILS_REF.marketForecastContent;
const marketForecastTemplates = DETAILS_REF.marketForecastTemplates;
const marketForecastTransactionDetails =
  DETAILS_REF.marketForecastTransactionDetails;

const marketForecastDetailsReducer = combineReducers({
  marketForecastCategories,
  marketForecastSegments,
  marketForecastDetails,
  categories,
  marketForecastContent,
  marketForecastTemplates,
  marketForecastTransactionDetails,
});

export default marketForecastDetailsReducer;
