import React, { useEffect, useState, useCallback, useMemo } from "react";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import constants from "../../Utils/constants";
import Search from "../../Components/Search";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { MasterServices } from "../../Services/Master";
import DynamicTableComponentNew from "../../Components/DynamicTableComponentsNew";
import { activeMenuTab } from "../../Store/Actions/User";
import CustomEllipsis from "../../Utils/CustomEllipsis";
import CustomReactTooltip from "../../Components/CustomReactTooltip";
import ComapnyDetailsDateTimeFormatter from "../../Utils/ComapnyDetailsDateTimeFormatter";
import AclAction from "../../Components/ACL/AclAction";
import Slugify from "../../Utils/Slugify";
import { formatRoute } from "react-router-named-routes";

// Table header configuration defining columns and their properties.
const tableHeader = [
  {
    id: "group_name",
    title: "Name",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "lobs_name",
    title: "LOB's",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "created_at",
    title: "Created On",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "created_by",
    title: "Created By",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "is_active",
    title: "Status",
    sortable: true,
    headerClass: "text-center",
    rowStyle: { textAlign: "center" },
    visibility: true,
  },
  {
    id: "actions",
    title: "Actions",
    sortable: false,
    rowStyle: { minWidth: "80px", textAlign: "center" },
    headerClass: "text-center",
    visibility: true,
  },
];

const SegmentGroupTemplate = () => {
  const history = useHistory(); // To handle navigation.
  const dispatch = useDispatch(); // To dispatch Redux actions.

  // State management for component.
  const [state, setState] = useState({
    searchValue: "",
    groupList: [],
    pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
    pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
    sortColumn: constants.PAGINATION.DEFAULT_REPORTS3_SORT_COLUMN,
    sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
    count: 0,
    tableChange: true,
  });

  const [statusModal, setStatusModal] = useState(false); // Modal visibility state.
  const [statusData, setStatusData] = useState(null); // Data for selected status change.

  // Memoized breadcrumb configuration.
  const breadcrumbs = useMemo(
    () => [
      {
        title:
          constants.APPLICATION_ROUTE.SEGMENT_GROUP_TEMPLATE.LIST.HEADER_TITLE,
        link: constants.APPLICATION_ROUTE.SEGMENT_GROUP_TEMPLATE.LIST.ROUTE,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.SEGMENT_GROUP_TEMPLATE.LIST.NAME,
        link: null,
        is_active: true,
      },
    ],
    []
  );

  // Handlers for pagination.
  const pagePrevious = () => {
    setState((prev) => ({
      ...prev,
      pageNo: prev.pageNo - 1,
      tableChange: true,
    }));
  };

  const pageNext = () => {
    setState((prev) => ({
      ...prev,
      pageNo: prev.pageNo + 1,
      tableChange: true,
    }));
  };

  const handlePage = (input) => {
    setState((prev) => ({ ...prev, pageNo: input, tableChange: true }));
  };

  const handlePageSize = (e) => {
    let pageSizeNum = parseInt(e.target.value);
    setState((prev) => ({
      ...prev,
      pageNo: 1,
      pageSize: pageSizeNum,
      tableChange: true,
    }));
  };

  // Sorting handler.
  const handleSort = (clickedColumn) => {
    setState((prev) => ({
      ...prev,
      sortColumn: clickedColumn,
      sortOrder: state.sortOrder === "asc" ? "desc" : "asc",
      tableChange: true,
    }));
  };

  // Navigate to edit page with the ID.
  const handleEdit = (id) => {
    history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.SEGMENT_GROUP_TEMPLATE.EDIT.ROUTE,
        { id }
      ),
    });
  };

  // Update the status of a metric group.
  const handleStatusSubmit = () => {
    const { id, is_active } = statusData;
    const data = { is_active: is_active === 1 ? 0 : 1 };

    MasterServices.updateMetricGroupStatus(id, data).then((res) => {
      if (res.status === 200) {
        setStatusModal(false);
        setStatusData(null);
        fetchMetricGroups();
      }
    });
  };

  // Fetch metric group data with filters applied.
  const fetchMetricGroups = useCallback(() => {
    const { pageNo, pageSize, searchValue, sortColumn, sortOrder } = state;
    const queryParams = `?page=${pageNo}&limit=${pageSize}&order=${sortOrder}&sort=${sortColumn}${
      searchValue ? `&name=${searchValue}` : ""
    }`;

    MasterServices.fetchMetricGroups(`metric-groups/list${queryParams}`).then(
      (res) => {
        let lobRes = res.data.data;
        lobRes = lobRes.map((data) => {
          let categoryRepoName = CustomEllipsis(data.group_name);
          return {
            id: data.id,
            group_name: data.group_name ? (
              <label className={`mb-0`}>
                <span key={data.id} className={"datagrid_tooltip"}>
                  {categoryRepoName.text}
                  {categoryRepoName.ellipsis && (
                    <span
                      className="material-icons-outlined icon"
                      data-for={"Tooltip-" + data.id}
                      data-tip={categoryRepoName.fullText}
                      data-iscapture="true"
                    >
                      info
                      <CustomReactTooltip
                        id={"Tooltip-" + data.id}
                        multiline={true}
                      />
                    </span>
                  )}
                </span>
              </label>
            ) : (
              "-"
            ),
            lobs_name: (
              <div style={{ width: "600px", textWrap: "auto" }}>
                {data.lob_details.map((i) => i.name).join(", ")}
              </div>
            ),
            created_by: data.created_by_user
              ? `${data.created_by_user.first_name} ${data.created_by_user.last_name}`
              : "-",
            created_at: data.created_at
              ? ComapnyDetailsDateTimeFormatter(data.created_at, false)
              : ComapnyDetailsDateTimeFormatter(new Date(), false),

            // Display a toggle switch for is_active
            is_active: (
              <div className="custom-control custom-switch success-toggle">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={`customSwitch${data.id}`}
                  checked={data.is_active === 1}
                  onChange={(e) => {
                    setStatusModal(true);
                    setStatusData(data);
                  }}
                  disabled={false}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`customSwitch${data.id}`}
                ></label>
              </div>
            ),
            // Display an edit icon for actions
            actions: (
              <AclAction
                type={constants.ACL.PERMISSION_KEY}
                entity={constants.ACL.METRIC_RULE.TITLE}
                action={constants.ACL.METRIC_RULE.ACTIONS.EDIT}
              >
                <button
                  tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                  id={`tooltip-${Slugify(
                    constants.APPLICATION_ROUTE.TEMPLATE.LIST
                      .EDIT_TEMPLATE_TOOLTIP
                  )}-${data.id}`}
                  onClick={() => handleEdit(data.id)}
                  className={"btn btn-md d-inline-flex align-items-center"}
                  data-for={`tooltip-${Slugify(
                    constants.APPLICATION_ROUTE.TEMPLATE.LIST
                      .EDIT_TEMPLATE_TOOLTIP
                  )}-${data.id}`}
                  data-tip={
                    constants.APPLICATION_ROUTE.TEMPLATE.LIST.EDIT_TEMPLATE
                  }
                  data-iscapture="true"
                >
                  <span className="material-icons-outlined text-blue-clr icon-fs-20">
                    edit
                  </span>
                </button>
              </AclAction>
            ),
          };
        });
        setState((prev) => ({
          ...prev,
          groupList: lobRes,
          count: res.data.total,
          tableChange: false,
        }));
      }
    );
  }, [state]);

  // Debounce or throttle search input.
  const handleSearch = useCallback(() => {
    setState((prev) => ({ ...prev, tableChange: true }));
  }, []);

  const handleInput = useCallback((e) => {
    setState((prev) => ({ ...prev, searchValue: e.target.value }));
  }, []);

  // Navigate to create page.
  const handleCreate = useCallback(() => {
    history.push(
      constants.APPLICATION_ROUTE.SEGMENT_GROUP_TEMPLATE.CREATE.ROUTE
    );
  }, [history]);

  // Set the active menu tab on initial render.
  useEffect(() => {
    dispatch(
      activeMenuTab(
        constants.APPLICATION_ROUTE.SEGMENT_GROUP_TEMPLATE.LIST.ROUTE
      )
    );
  }, [dispatch]);

  // Fetch data whenever tableChange is true.
  useEffect(() => {
    if (state.tableChange) fetchMetricGroups();
  }, [state.tableChange, fetchMetricGroups]);

  return (
    <aside className="main_content">
      <div className="m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height">
        <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0">
          <CustomBreadcrumb breadcrumbs={breadcrumbs} />
        </div>
        <div className="d-flex justify-between">
          <Search
            handleSearch={handleSearch}
            handleInput={handleInput}
            value={state.searchValue}
            placeHolder={"Search"}
          />
          <button
            className="btn btn-custom btn-primary mb-3"
            type="button"
            onClick={handleCreate}
          >
            + Create New
          </button>
        </div>

        <div className="custom-table-block custom-table-block-2 first-col-fixed-block nowrap-table-block th-nowrap sort-table-block center-th-align">
          <DynamicTableComponentNew
            columnData={tableHeader}
            rowData={state.groupList}
            pagePrevious={pagePrevious}
            pageNext={pageNext}
            pageSize={state.pageSize}
            pageNo={state.pageNo}
            handlePage={handlePage}
            handleSort={handleSort}
            sortColumn={state.sortColumn}
            sortOrder={state.sortOrder}
            count={state.count}
            colSpan={tableHeader.filter((h) => h.visibility).length}
            showActions={true}
            showPagination={true}
            paginationClass={"center-pagination custom-pagination-new"}
            handlePageSize={handlePageSize}
          />
        </div>
      </div>
      <Modal
        isOpen={statusModal}
        toggle={() => {
          setStatusModal(false);
          setStatusData(null);
        }}
        className={
          "modal-sm modal-w-header custom-modal modal-without-header primary-modal"
        }
      >
        <ModalHeader
          toggle={() => {
            setStatusModal(false);
            setStatusData(null);
          }}
        >
          <div className="modal-header">
            <h3 className="text-darkblue2 font-weight-bold">
              {" "}
              {statusData?.is_active ? "De-" : ""}Activation
            </h3>
          </div>
        </ModalHeader>
        <ModalBody style={{ paddingTop: "20px !important" }}>
          <div
            className={statusData?.is_active ? "remove-body" : "activate-body"}
          >
            <h2 className="font-weight-semibold mb-0">
              Are you sure want to{" "}
              {statusData?.is_active ? "de-active" : "active"}{" "}
              {statusData?.group_name} group.
            </h2>
          </div>
        </ModalBody>
        <ModalFooter
          className="status-model-footer"
          style={{ justifyContent: "center !important" }}
        >
          <Button
            className={"modal-right-btn modal-btn"}
            onClick={() => {
              setStatusModal(false);
              setStatusData(null);
            }}
          >
            Cancel
          </Button>{" "}
          <Button
            className={`modal-btn ${
              statusData?.is_active ? "btn-danger" : "btn-success"
            } `}
            type="submit"
            onClick={handleStatusSubmit}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </aside>
  );
};

export default SegmentGroupTemplate;
