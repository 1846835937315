import { benchmark_details as DETAILS } from "../Reducers/actionConstants";

const benchmarkCategories = (payload) => {
  return {
    type: DETAILS.BENCHMARK_CATEGORIES,
    payload,
  };
};

const benchmarkSegments = (payload) => {
  return {
    type: DETAILS.BENCHMARK_SEGMENTS,
    payload,
  };
};

const benchmarkDetails = (payload) => {
  return {
    type: DETAILS.BENCHMARK_DETAILS,
    payload,
  };
};

const categories = (payload) => {
  return {
    type: DETAILS.CATEGORIES,
    payload,
  };
};

const benchmarkContent = (payload) => {
  return {
    type: DETAILS.BENCHMARK_CONTENT,
    payload,
  };
};

const benchmarkTemplates = (payload) => {
  return {
    type: DETAILS.BENCHMARK_TEMPLATES,
    payload,
  };
};

const benchmarkTransactionDetails = (payload) => {
  return {
    type: DETAILS.BENCHMARK_TRANSACTION_DETAILS,
    payload,
  };
};

export {
  benchmarkCategories,
  benchmarkSegments,
  benchmarkDetails,
  categories,
  benchmarkContent,
  benchmarkTemplates,
  benchmarkTransactionDetails,
};
