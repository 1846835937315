import { Axios } from "../lib/axios";
export const ApiCredentialServices = {
  getAllApiClients,
  updateUserApiStatus,
  updateClientApiStatus,
};
function getAllApiClients(queryParams, body) {
  return Axios.get(`open-apis/keys-all${queryParams}`, body);
}
function updateUserApiStatus(user_id, body) {
  return Axios.patch(`open-apis/keys/${user_id}/status`, body);
}
function updateClientApiStatus(client_id, body) {
  return Axios.patch(`open-apis/client/keys/${client_id}/status`, body);
}
