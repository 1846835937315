import React, { useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const Dailog = (props) => {
  const { isOpen } = props;

  useEffect(() => {
    if (!isOpen) {
      document.querySelector("body").classList.remove("modal-open");
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={props.decline}
      className={`${props.modalStyleType} ${
        !(props.config && props.config.type) ? "modal-wihout-footer" : ""
      } modal-confirm modal-sm custom-modal`}
    >
      <ModalHeader toggle={props.decline}>{props.header}</ModalHeader>
      <ModalBody>{props.content}</ModalBody>
      {props.config && props.config.type && (
        <ModalFooter>
          <Button
            className={"btn-outline-primary modal-btn"}
            onClick={props.decline}
          >
            Cancel
          </Button>{" "}
          <Button
            className={"modal-right-btn modal-btn"}
            onClick={() => {
              props.accept(props.config);
            }}
          >
            Confirm
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default Dailog;
