import { Axios } from "../lib/axios";
export const MasterServices = {
  getCurrencies,
  getTotalMetricTypes,
  getMetricTypes,
  getMetricTypeDetails,
  addMetricType,
  editMetricType,
  toggleMetricType,
  removeMetricType,
  getTotalMetrics,
  getMetrics,
  getMetricDetails,
  addMetric,
  editMetric,
  toggleMetric,
  removeMetric,
  getTotalLobs,
  getLobs,
  getLobDetails,
  addLob,
  editLob,
  toggleLob,
  removeLob,
  getTotalLobSubCategories,
  getLobSubCategories,
  getLobSubCategoryDetails,
  addLobSubCategory,
  editLobSubCategory,
  toggleLobSubCategory,
  removeLobSubCategory,
  getTotalVerticals,
  getVerticals,
  getVerticalDetails,
  addVertical,
  editVertical,
  toggleVertical,
  removeVertical,
  getTotalVerticalCategories,
  getVerticalCategories,
  getVerticalCategoryDetails,
  addVerticalCategory,
  editVerticalCategory,
  toggleVerticalCategory,
  removeVerticalCategory,
  getTotalVerticalSubCategories,
  getVerticalSubCategories,
  getVerticalSubCategoryDetails,
  addVerticalSubCategory,
  editVerticalSubCategory,
  toggleVerticalSubCategory,
  removeVerticalSubCategory,
  getTotalGeographies,
  getGeographies,
  getGeographyDetails,
  addGeography,
  editGeography,
  toggleGeography,
  removeGeography,
  getTotalGeoSubCategories,
  getGeoSubCategories,
  getGeoSubCategoryDetails,
  addGeoSubCategory,
  editGeoSubCategory,
  toggleGeoSubCategory,
  removeGeoSubCategory,
  getTotalCountries,
  getCountries,
  getCountryDetails,
  addCountry,
  editCountry,
  toggleCountry,
  removeCountry,
  getMasters,
  getTotalMetricUnits,
  getMetricUnits,
  getMetricUnitDetails,
  addMetricUnit,
  editMetricUnit,
  toggleMetricUnit,
  removeMetricUnit,
  getCategories,
  addMetricCategory,
  editMetricCategory,
  addMetricRule,
  editMetricRule,
  metricTemplateUpload,
  getFiles,
  AddTransations,
  getTemplateRecord,
  approveTransactions,
  rejectTransactions,
  addMetricGroup,
  fetchMetricGroups,
  fetchMetricGroupsWithID,
  editMetricGroup,
  updateMetricGroupStatus,
};
function getCurrencies() {
  return Axios.get("currencies");
}
// metric types
function getTotalMetricTypes(url) {
  return Axios.get(url);
}
function getMetricTypes(url) {
  return Axios.get(url);
}
function getMetricTypeDetails(id) {
  return Axios.get(`metrictype/${id}`);
}
function addMetricType(body) {
  return Axios.post(`metrictype`, body);
}
function addMetricRule(body) {
  return Axios.post(`metric-rules`, body);
}

function editMetricRule(body) {
  return Axios.put(`metric-rules/update`, body);
}
function editMetricType(metricTypeId, body) {
  return Axios.patch(`metrictype/${metricTypeId}`, body);
}
function toggleMetricType(metricTypeId, body) {
  return Axios.patch(`metrictype/${metricTypeId}/status/${body.is_active}`);
}
function removeMetricType(metricTypeId) {
  return Axios.delete(`metrictype/${metricTypeId}`);
}

// metric units
function getTotalMetricUnits(url) {
  return Axios.get(url);
}
function getMetricUnits(url) {
  return Axios.get(url);
}
function getMetricUnitDetails(id) {
  return Axios.get(`metricUnits/${id}`);
}
function addMetricUnit(body) {
  return Axios.post(`metricUnits`, body);
}
function editMetricUnit(metricTypeId, body) {
  return Axios.patch(`metricUnits/${metricTypeId}`, body);
}
function toggleMetricUnit(metricTypeId, body) {
  return Axios.patch(`metricUnits/${metricTypeId}/status/${body.is_active}`);
}
function removeMetricUnit(metricTypeId) {
  return Axios.delete(`metricUnits/${metricTypeId}`);
}

// metrics
function getTotalMetrics(url) {
  return Axios.get(url);
}
function getMetrics(url) {
  return Axios.get(url);
}
function getMetricDetails(id) {
  return Axios.get(`metric/${id}`);
}
function addMetric(body) {
  return Axios.post(`metric`, body);
}
function editMetric(metricId, body) {
  return Axios.patch(`metric/${metricId}`, body);
}
function toggleMetric(metricId, body) {
  return Axios.patch(`metric/${metricId}/status/${body.is_active}`);
}
function removeMetric(metricId) {
  return Axios.delete(`metric/${metricId}`);
}
function getTotalLobs(url) {
  return Axios.get(url);
}
function getLobs(url) {
  return Axios.get(url);
}
function getLobDetails(id) {
  return Axios.get(`lob/${id}`);
}
function addLob(body) {
  return Axios.post(`lob`, body);
}
function editLob(lobId, body) {
  return Axios.patch(`lob/${lobId}`, body);
}
function toggleLob(lobId, body) {
  return Axios.patch(`lob/${lobId}/status/${body.is_active}`);
}
function removeLob(lobId) {
  return Axios.delete(`lob/${lobId}`);
}
function getTotalLobSubCategories(url) {
  return Axios.get(url);
}
function getLobSubCategories(url) {
  return Axios.get(url);
}
function getLobSubCategoryDetails(id) {
  return Axios.get(`lobsubcategory/${id}`);
}
function addLobSubCategory(body) {
  return Axios.post(`lobsubcategory`, body);
}
function editLobSubCategory(lobSubCategoryId, body) {
  return Axios.patch(`lobsubcategory/${lobSubCategoryId}`, body);
}
function toggleLobSubCategory(lobSubCategoryId, body) {
  return Axios.patch(
    `lobsubcategory/${lobSubCategoryId}/status/${body.is_active}`
  );
}
function removeLobSubCategory(lobSubCategoryId) {
  return Axios.delete(`lobsubcategory/${lobSubCategoryId}`);
}
function getTotalVerticals(url) {
  return Axios.get(url);
}
function getVerticals(url) {
  return Axios.get(url);
}
function getVerticalDetails(id) {
  return Axios.get(`vertical/${id}`);
}
function addVertical(body) {
  return Axios.post(`vertical`, body);
}
function editVertical(verticalId, body) {
  return Axios.patch(`vertical/${verticalId}`, body);
}
function toggleVertical(verticalId, body) {
  return Axios.patch(`vertical/${verticalId}/status/${body.is_active}`);
}
function removeVertical(verticalId) {
  return Axios.delete(`vertical/${verticalId}`);
}

function getTotalVerticalCategories(url) {
  return Axios.get(url);
}
function getVerticalCategories(url) {
  return Axios.get(url);
}
function getVerticalCategoryDetails(id) {
  return Axios.get(`verticalcategory/${id}`);
}
function addVerticalCategory(body) {
  return Axios.post(`verticalcategory`, body);
}
function editVerticalCategory(verticalCategoryId, body) {
  return Axios.patch(`verticalcategory/${verticalCategoryId}`, body);
}
function toggleVerticalCategory(verticalCategoryId, body) {
  return Axios.patch(
    `verticalcategory/${verticalCategoryId}/status/${body.is_active}`
  );
}
function removeVerticalCategory(verticalCategoryId) {
  return Axios.delete(`verticalcategory/${verticalCategoryId}`);
}

function getTotalVerticalSubCategories(url) {
  return Axios.get(url);
}
function getVerticalSubCategories(url) {
  return Axios.get(url);
}
function getVerticalSubCategoryDetails(id) {
  return Axios.get(`vertical-sub-category/${id}`);
}
function addVerticalSubCategory(body) {
  return Axios.post(`vertical-sub-category`, body);
}
function editVerticalSubCategory(verticalSubCategoryId, body) {
  return Axios.patch(`vertical-sub-category/${verticalSubCategoryId}`, body);
}
function toggleVerticalSubCategory(verticalSubCategoryId, body) {
  return Axios.patch(
    `vertical-sub-category/${verticalSubCategoryId}/status/${body.is_active}`
  );
}
function removeVerticalSubCategory(verticalSubCategoryId) {
  return Axios.delete(`vertical-sub-category/${verticalSubCategoryId}`);
}

function getTotalGeographies(url) {
  return Axios.get(url);
}
function getGeographies(url) {
  return Axios.get(url);
}
function getGeographyDetails(id) {
  return Axios.get(`geography/${id}`);
}
function addGeography(body) {
  return Axios.post(`geography`, body);
}
function editGeography(geographyId, body) {
  return Axios.patch(`geography/${geographyId}`, body);
}
function toggleGeography(geographyId, body) {
  return Axios.patch(`geography/${geographyId}/status/${body.is_active}`);
}
function removeGeography(geographyId) {
  return Axios.delete(`geography/${geographyId}`);
}
function getTotalGeoSubCategories(url) {
  return Axios.get(url);
}
function getGeoSubCategories(url) {
  return Axios.get(url);
}
function getGeoSubCategoryDetails(id) {
  return Axios.get(`geosubcategory/${id}`);
}
function addGeoSubCategory(body) {
  return Axios.post(`geosubcategory`, body);
}
function editGeoSubCategory(geoSubCategoryId, body) {
  return Axios.patch(`geosubcategory/${geoSubCategoryId}`, body);
}
function toggleGeoSubCategory(geoSubCategoryId, body) {
  return Axios.patch(
    `geosubcategory/${geoSubCategoryId}/status/${body.is_active}`
  );
}
function removeGeoSubCategory(geoSubCategoryId) {
  return Axios.delete(`geosubcategory/${geoSubCategoryId}`);
}
function getTotalCountries(url) {
  return Axios.get(url);
}
function getCountries(url) {
  return Axios.get(url);
}
function getCountryDetails(id) {
  return Axios.get(`country/${id}`);
}
function addCountry(body) {
  return Axios.post(`country`, body);
}
function editCountry(countryId, body) {
  return Axios.patch(`country/${countryId}`, body);
}
function toggleCountry(countryId, body) {
  return Axios.patch(`country/${countryId}/status/${body.is_active}`);
}
function removeCountry(countryId) {
  return Axios.delete(`country/${countryId}`);
}
function getMasters() {
  return Axios.get("masterdatalist");
}

function getCategories(url) {
  return Axios.get(url);
}
function addMetricCategory(body) {
  return Axios.post(`metric_category`, body);
}
function editMetricCategory(id, body) {
  return Axios.patch(`metric_category/${id}`, body);
}
function metricTemplateUpload(body) {
  return Axios.post(`metric_template/upload`, body);
}
function getFiles(url) {
  return Axios.get(url);
}

function AddTransations(body) {
  return Axios.post(`metric_template/addTransactions`, body);
}

function getTemplateRecord(url) {
  return Axios.get(url);
}
function approveTransactions(body) {
  return Axios.post(`metric_template/addQuantData`, body);
}

function rejectTransactions(body) {
  return Axios.post(`metric_template/rejectQuantData`, body);
}

function addMetricGroup(body) {
  return Axios.post(`metric-groups`, body);
}

function fetchMetricGroups(url) {
  return Axios.get(url);
}

function fetchMetricGroupsWithID(id) {
  return Axios.get(`metric-groups/${id}`);
}

function editMetricGroup(id, body) {
  return Axios.put(`metric-groups/${id}`, body);
}

function updateMetricGroupStatus(id, body) {
  return Axios.put(`metric-groups/status/${id}`, body);
}
