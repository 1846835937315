import React from "react";
import CustomCheckBox from "./CustomCheckBox";
import MetricsRuleView from "./MetricsRuleView";
import "./MetricCategoryTreeView.css";
import { flattenDeep } from "lodash";
import { findParentIdsBottomToTop } from "../../Utils/helper";

function MetricCategoryTreeView({
  treeData,
  setTreeData,
  title,
  checkedIds,
  setCheckedIds,
  expandedIds,
  setExpandIds,
  rule_view,
  metrics,
  isMetrics,
  setDataChange,
  IsEdit,
  groups,
}) {
  const setExpandedSegments = (id) => {
    setExpandIds(id);
  };

  const handleMetricsTrigger = (checked) => {
    setCheckedIds(checked);

    if (IsEdit) {
      setDataChange(true);
    }
  };

  return (
    <div
      className={rule_view ? "metrics-view-main-div" : "tree-view-main-div"}
      style={{
        maxWidth: groups
          ? "100%"
          : rule_view
          ? metrics
            ? "40%"
            : "20%"
          : "24%",
        minWidth: groups
          ? "100%"
          : rule_view
          ? metrics
            ? "40%"
            : "20%"
          : "24%",
      }}
    >
      <div className="tree-view-header">
        <span className="header-span">
          {rule_view ? "Selected " : "Select "}
          {title}
        </span>
      </div>

      {metrics ? (
        <MetricsRuleView data={treeData} />
      ) : (
        <div className="segments-tree custom-checkbox-tree">
          <CustomCheckBox
            nodes={treeData}
            checked={checkedIds}
            expanded={expandedIds}
            onCheck={(checked) => handleMetricsTrigger(checked)}
            showArrow={true}
            handleButtonClick={() => {}}
            onExpand={(expanded) => setExpandedSegments(expanded)}
            key={title}
            disabled={rule_view}
            rule_view={rule_view}
            title={title}
            isMetrics={isMetrics}
          />
        </div>
      )}
    </div>
  );
}

export default MetricCategoryTreeView;
