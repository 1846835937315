/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import constants from "../../Utils/constants";
import Search from "../../Components/Search";
import AclAction from "../../Components/ACL/AclAction";
import DynamicTableComponentNew from "../../Components/DynamicTableComponentsNew";
import { MasterServices } from "../../Services/Master";
import ComapnyDetailsDateTimeFormatter from "../../Utils/ComapnyDetailsDateTimeFormatter";
import MetricCategoriesModel from "../../Containers/v2/MetricCategoriesModel";
import { useHistory } from "react-router-dom";
import { DropdownList } from "react-widgets";
import InActivationModal from "./InActivationModal";
import Toast from "../../Utils/Toast";
import { useDispatch } from "react-redux";
import { activeMenuTab } from "../../Store/Actions/User";
import ActivationModal from "./ActivationModal";
import "./StatusModal.css";
import CustomEllipsis from "../../Utils/CustomEllipsis";
import CustomReactTooltip from "../../Components/CustomReactTooltip";
import Slugify from "../../Utils/Slugify";

export default function DynamicCategoryComponent({
  title,
  typeId,
  maxLevel,
  successMessage,
  editMessage,
  inActiveMessage,
  match,
  inActivateConfirmMessage,
  activateConfirmMessage,
  activeMessage,
}) {
  const history = useHistory();
  const dispatch = useDispatch();

  // State variables

  const [searchName, setSearchName] = useState("");
  const [categoryModal, setCategoryModal] = useState(false);
  const [allCategoryList, setAllCategoryList] = useState([]);
  const [pageNo, setPageNo] = useState(constants.PAGINATION.DEFAULT_PAGE_NO);
  const [pageSize, setPageSize] = useState(
    constants.PAGINATION.DEFAULT_PAGE_SIZE
  );
  const [count, setCount] = useState(0);
  const [level, setLevel] = useState("All");
  const [categoryObj, setCategoryObj] = useState({});
  const [activeModal, setActiveModal] = useState(false);

  const [inActiveModal, setInActiveModel] = useState(false);

  const [parentCategoryList, setParentCategoryList] = useState([]);
  const [parentFilterValue, setParentFilterValue] = useState();

  const [sortColumn, setSortColumn] = useState(
    constants.PAGINATION.DEFAULT_REPORTS3_SORT_COLUMN
  );
  const [sortOrder, setSortOrder] = useState(
    constants.PAGINATION.DEFAULT_SORT_ORDER
  );

  const [currentValue, setCurrentValue] = useState(true);

  // Table header definition

  const tableHeader = [
    {
      id: "name",
      title: "name",
      sortable: true,
      headerStyle: { textTransform: "capitalize", textAlign: "left" },
      rowStyle: {
        textTransform: "capitalize",
        textAlign: "left",
        paddingLeft: "18px",
        background: "#fff",
      },
      visibility: true,
    },
    {
      id: "level",
      title: "Level",
      sortable: true,
      headerStyle: { textTransform: "capitalize", textAlign: "right" },
      rowStyle: {
        textTransform: "capitalize",
        textAlign: "left",
      },
      visibility: true,
    },
    {
      id: "parent_name",
      title: `Parent ${title}`,
      sortable: false,
      headerStyle: { textTransform: "capitalize", textAlign: "left" },
      rowStyle: { textTransform: "capitalize", textAlign: "left" },
      visibility: true,
    },
    {
      id: "created_at",
      title: "Created On",
      sortable: true,
      headerStyle: { textAlign: "left" },
      rowStyle: { textAlign: "left" },
      visibility: true,
    },
    {
      id: "created_by",
      title: "Created By",
      sortable: false,
      headerStyle: { textTransform: "capitalize", textAlign: "left" },
      rowStyle: { textTransform: "capitalize", textAlign: "left" },
      visibility: true,
    },

    {
      id: "is_active",
      title: "Status",
      sortable: true,
      headerStyle: {},
      rowStyle: { textAlign: "center" },
      headerClass: "text-center",
      visibility: true,
    },
    {
      id: "actions",
      title: "Actions",
      sortable: false,
      headerStyle: {},
      rowStyle: { textAlign: "center", position: "inherit" },
      headerClass: "text-center",
      rowClass: "position-inherit-important",
      visibility: true,
    },
  ];

  // Breadcrumbs definition

  const breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.MASTER_DATA_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: title,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };
  // Function to handle search
  const handleSearch = () => {
    setPageNo(1);
    setCurrentValue(true);
  };

  // Function to handle input change
  const handleInput = (e) => {
    setSearchName(e.target.value);
  };

  // Function to open the category modal for adding a new category
  const addMetricCategories = () => {
    setCategoryObj({});
    setCategoryModal(true);
  };

  // Function to close the category modal
  const closeCategoryModal = () => {
    setCategoryModal(false);
  };

  // Function to set page number
  const pagePrevious = () => {
    setPageNo(pageNo - 1);
    setCurrentValue(true);
  };

  const pageNext = () => {
    setPageNo(pageNo + 1);
    setCurrentValue(true);
  };

  const handlePage = (input) => {
    setPageNo(input);
    setCurrentValue(true);
  };

  // Function to handle sort column and sort order
  const handleSort = (clickedColumn, key) => {
    setSortColumn(clickedColumn);
    setSortOrder(sortOrder === "desc" ? "asc" : "desc");
    setCurrentValue(true);
  };

  const handleEdit = (item) => {
    setCategoryObj(item);
    setCategoryModal(true);
  };

  // FUnction to handle category level
  const handleLevel = (e) => {
    setParentFilterValue();
    setLevel(e);
    setPageNo(1);
    setCurrentValue(true);
  };

  // Fetches all parent categories based on the current level and metric category type
  const fetchAllParentCategories = () => {
    // Determine the parent level based on the current level or use maxLevel if "All" is selected
    let parentLevel = level === "All" ? maxLevel : level;

    // Call MasterServices to get all parent categories
    MasterServices.getCategories(
      `all_parent_level_metric_categories?metric_category_type_id=${typeId}&level=${parentLevel}`
    )
      .then((res) => {
        // Process the response data and set the state with the parent category list
        let allParentLevelData = res.data.data;
        setParentCategoryList(allParentLevelData);
      })
      .catch((error) => {
        // Handle errors and display a toast message
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  const handleParentLevelFilter = (e) => {
    setParentFilterValue(e);
    setCurrentValue(true);
  };

  // Fetches category data based on provided parameters
  const fetchCategoryData = () => {
    // Set default values if parameters are not provided
    let queryParams = "";

    // Build query parameters based on conditions
    if (sortOrder) {
      queryParams = "&sort_order=" + sortOrder + "&sort_column=" + sortColumn;
    }
    if (searchName !== "") {
      queryParams += "&search_column=name&search_value=" + searchName;
    }
    if (level !== "All") {
      queryParams += "&level=" + level;
    }
    if (parentFilterValue) {
      queryParams += "&parent_id=" + parentFilterValue.id;
    }

    // Update the browser history
    history.push({
      pathname: history.pathname,
      search: "?page=" + pageNo + "&page_size=" + pageSize + queryParams,
    });

    // Fetch categories using MasterServices
    MasterServices.getCategories(
      `metric_category?metric_category_type_id=${typeId}&page=${pageNo}&page_size=${pageSize}${queryParams}`
    ).then((res) => {
      // Process the response data
      let lobRes = res.data.data;
      lobRes = lobRes.map((data) => {
        let categoryRepoName = CustomEllipsis(data.name);
        return {
          id: data.id,
          name: data.name ? (
            <label className={`mb-0`}>
              <span key={data.id} className={"datagrid_tooltip"}>
                {categoryRepoName.text}
                {categoryRepoName.ellipsis && (
                  <span
                    className="material-icons-outlined icon"
                    data-for={"Tooltip-" + data.id}
                    data-tip={categoryRepoName.fullText}
                    data-iscapture="true"
                  >
                    info
                    <CustomReactTooltip
                      id={"Tooltip-" + data.id}
                      multiline={true}
                    />
                  </span>
                )}
              </span>
            </label>
          ) : (
            "-"
          ),
          level: data.level,
          created_by: data.created_by_user
            ? `${data.created_by_user.first_name} ${data.created_by_user.last_name}`
            : "-",
          created_at: data.created_at
            ? ComapnyDetailsDateTimeFormatter(data.created_at, false)
            : ComapnyDetailsDateTimeFormatter(new Date(), false),
          parent_name: data.parent_data?.name || "-",
          parent_id: data.parent_data?.id || null,
          // Display a toggle switch for is_active
          is_active: (
            <div className="custom-control custom-switch success-toggle">
              <input
                type="checkbox"
                className="custom-control-input"
                id={`customSwitch${data.id}`}
                checked={data.is_active === 1}
                onChange={(e) => {
                  setCategoryObj(data);
                  if (data.is_active === 1) {
                    setInActiveModel(true);
                  } else {
                    setActiveModal(true);
                  }
                }}
                disabled={false}
              />
              <label
                className="custom-control-label"
                htmlFor={`customSwitch${data.id}`}
              ></label>
            </div>
          ),
          // Display an edit icon for actions
          actions: (
            <AclAction
              type={constants.ACL.PERMISSION_KEY}
              entity={constants.ACL.METRIC_RULE.TITLE}
              action={constants.ACL.METRIC_RULE.ACTIONS.EDIT}
            >
              <button
                tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                id={`tooltip-${Slugify(
                  constants.APPLICATION_ROUTE.TEMPLATE.LIST
                    .EDIT_TEMPLATE_TOOLTIP
                )}-${data.id}`}
                onClick={() => handleEdit(data)}
                className={"btn btn-md d-inline-flex align-items-center"}
                data-for={`tooltip-${Slugify(
                  constants.APPLICATION_ROUTE.TEMPLATE.LIST
                    .EDIT_TEMPLATE_TOOLTIP
                )}-${data.id}`}
                data-tip={
                  constants.APPLICATION_ROUTE.TEMPLATE.LIST.EDIT_TEMPLATE
                }
                data-iscapture="true"
              >
                <span className="material-icons-outlined text-blue-clr icon-fs-20">
                  edit
                </span>
              </button>
            </AclAction>
          ),
        };
      });
      // Set the state with the processed data
      setCount(res.data.meta.total_count);
      setAllCategoryList(lobRes);
      setCurrentValue(false);
    });
  };

  // Function to handle page size
  const handlePageSize = (e) => {
    setPageSize(e.target.value);
    setPageNo(1);
    setCurrentValue(true);
  };

  // Handles toggling the category status (active/inactive)
  const handleCategoryStatus = () => {
    // Create an object to represent the updated category status
    let addCategoryObj = {
      id: categoryObj.id,
      // Toggle the is_active status: if it's true, set to 0; if false, set to 1
      is_active: categoryObj?.is_active ? 0 : 1,
    };

    // Check if the category has a valid ID
    if (categoryObj?.id) {
      // Call MasterServices to edit the metric category with the updated status
      MasterServices.editMetricCategory(categoryObj?.id, addCategoryObj)
        .then((res) => {
          // Display a success toast message and update the UI
          Toast(
            categoryObj?.is_active ? inActiveMessage : activeMessage,
            "success"
          );
          setActiveModal(false);
          setInActiveModel(false);
          // Refresh the category data
          fetchCategoryData();
        })
        .catch((error) => {
          // Handle errors and display an error toast message
          Toast(
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
            "error"
          );
        });
    }
  };

  useEffect(() => {
    dispatch(activeMenuTab(match.path));
  }, []);

  useEffect(() => {
    if (currentValue) {
      fetchCategoryData();
    }
  }, [currentValue]);

  useEffect(() => {
    fetchAllParentCategories();
  }, [level]);

  return (
    <>
      <aside className="main_content">
        <div className="m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height">
          <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0">
            <CustomBreadcrumb breadcrumbs={breadcrumbs()} />
          </div>
          <div className="comapnay_tbl">
            <div className="">
              <div className="d-flex">
                <div className="border-search search-box-shadow-2 mb-3 d-flex">
                  <Search
                    handleSearch={handleSearch}
                    handleInput={handleInput}
                    value={searchName}
                    placeHolder={`Filter ${title}`}
                  />

                  <DropdownList
                    value={level}
                    data={["All", ...Array(maxLevel)].map(
                      (item, index) => item || index
                    )}
                    className="drop-down-list"
                    style={{ width: "106px" }}
                    onChange={(e) => handleLevel(e)}
                    filter="contains"
                  />

                  <DropdownList
                    // value={1}
                    placeholder={`Select Parent ${title}`}
                    data={parentCategoryList}
                    className="drop-down-list"
                    style={{ width: "206px" }}
                    disabled={level === 1 ? true : false}
                    valueField="id"
                    textField="name"
                    value={parentFilterValue}
                    filter="contains"
                    onChange={(e) => handleParentLevelFilter(e)}
                  />
                </div>
                <div className="d-flex justify-content-end align-self-end flex-wrap ml-auto">
                  <AclAction
                    type="permissions"
                    entity={constants.ACL.LOB_RESOURCE.TITLE}
                    action={constants.ACL.LOB_RESOURCE.ACTIONS.CREATE}
                  >
                    <div
                      tbrentitytype={constants.ACL.ENTITY_TYPE.DIV}
                      className=""
                    >
                      <button
                        className={
                          "btn btn-custom btn-primary icon-text-btn title-btn d-inline-flex align-items-center mb-3"
                        }
                        type="button"
                        onClick={addMetricCategories}
                      >
                        <span className="material-icons-round icon-fs-16 mr-1">
                          add
                        </span>
                        {" " +
                          constants.APPLICATION_ROUTE.MASTER_DATA_LOB.LIST
                            .ADD_BUTTON_TITLE}
                      </button>
                    </div>
                  </AclAction>
                </div>
              </div>

              <div className="custom-table-block custom-table-block-2 first-col-fixed-block nowrap-table-block th-nowrap sort-table-block center-th-align">
                <DynamicTableComponentNew
                  columnData={tableHeader}
                  rowData={allCategoryList}
                  pagePrevious={pagePrevious}
                  pageNext={pageNext}
                  pageSize={pageSize}
                  pageNo={pageNo}
                  handlePage={handlePage}
                  handleSort={handleSort}
                  sortColumn={sortColumn}
                  sortOrder={sortOrder}
                  count={count}
                  colSpan={tableHeader.filter((h) => h.visibility).length}
                  showActions={true}
                  showPagination={true}
                  paginationClass={"center-pagination custom-pagination-new"}
                  handlePageSize={handlePageSize}
                />
              </div>
            </div>
          </div>
        </div>
      </aside>

      <MetricCategoriesModel
        categoryModal={categoryModal}
        closeCategoryModal={closeCategoryModal}
        masterName={searchName}
        title={title}
        fetchCategoryData={fetchCategoryData}
        maxLevel={maxLevel}
        typeId={typeId}
        categoryObj={categoryObj}
        successMessage={successMessage}
        editMessage={editMessage}
        setLevel={setLevel}
        setParentFilterValue={setParentFilterValue}
        setSortOrder={setSortOrder}
        setSortColumn={setSortColumn}
        setSearchName={setSearchName}
      />

      <InActivationModal
        inActiveModal={inActiveModal}
        setActiveModal={setActiveModal}
        handleCategoryStatus={handleCategoryStatus}
        inActivateConfirmMessage={inActivateConfirmMessage}
        setInActiveModal={setInActiveModel}
      />

      <ActivationModal
        activeModal={activeModal}
        setActiveModal={setActiveModal}
        handleCategoryStatus={handleCategoryStatus}
        activateConfirmMessage={activateConfirmMessage}
      />
    </>
  );
}
