import React from "react";

const UserPermissions = (props) => {
  return (
    props.permissions &&
    props.permissions.length > 0 && (
      <div className={`row px-3 pb-1 pt-2`}>
        {props.permissions
          .sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase()
              ? 1
              : b.name.toLowerCase() > a.name.toLowerCase()
              ? -1
              : 0
          )
          .map((pp) => (
            <div className={`col-sm-3`}>
              <div className="d-inline-flex align-items-center">
                <input
                  className="form-check-input custom-checkbox-new"
                  type="checkbox"
                  id={`${props.module}-${pp.name}`}
                  value="1"
                  onChange={(e) => {
                    props.handleCheckboxChange(
                      e,
                      pp.id,
                      props.module,
                      props.parentModule ? props.parentModule : null
                    );
                  }}
                  checked={pp.is_allowed === 1}
                  disabled={pp.is_allowed === 1 && pp.level === "role"}
                />
                <label
                  className="form-check-label"
                  htmlFor={`${props.module}-${pp.name}`}
                >
                  {pp.name}
                </label>
              </div>
            </div>
          ))}
      </div>
    )
  );
};

export default UserPermissions;
