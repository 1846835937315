import React, { Component } from "react";
import EcosystemTransactionDetailsCategories from "../../Containers/EcosystemTransactionDetailsCategories";
import EcosystemTransactionDetailsContent from "../../Containers/EcosystemTransactionDetailsContent";
import { EcosystemServices } from "../../Services/Ecosystem";
import { connect } from "react-redux";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import {
  ecosystemCategories,
  ecosystemDetails,
  ecosystemContent,
  ecosystemTemplates,
  ecosystemTransactionDetails,
} from "../../Store/Actions/EcosystemDetails";
import constants from "../../Utils/constants";
import { formatRoute } from "react-router-named-routes";
import { last, isEqual } from "lodash";
import DailogNew from "../../Components/DailogNew";
import { Prompt } from "react-router-dom";
import permissionCheck from "../../lib/permissionCheck";
import { activeMenuTab } from "../../Store/Actions/User";
import decodeJWT from "../../lib/decodeJWT";
import Toast from "../../Utils/Toast";
import CustomCloneDeep from "../../Utils/CustomCloneDeep";
import DocumentTitle from "../../Utils/DocumentTitle";
import { QuoteImageServices } from "../../Services/QuoteImage";
import QuoteImageDialog from "../../Components/QuoteImageDialog";

class EcosystemTransactionDetails extends Component {
  state = {
    masterContent: {},
    masterCategories: {},
    dailogModal: false,
    dailogModalContent: "",
    dailogModalHeader: "",
    dailogModalConfig: {},
    dailogModalStyleType: "",
    changesMade: false,
    transactionId: null,
    apiError: null,
    publishedContent: {},
    innerSidebarOpen: true,
    notification: false,
    selectedSlide: "",
    quoteImageDailogModal: false,
    mapping_id: "",
    mapping_type: null,
    quoteId: null,
    quoteContent: null,
    quoteImageUrl: null,
    quoteImageName: null,
    image_delete: false,
    base64_data: null,
    currentState: null,
    labelquoteContent: null,
  };

  toggleInnerSidebar = () => {
    this.setState({ innerSidebarOpen: !this.state.innerSidebarOpen });
  };

  turnOffNotification = () => {
    this.setState({
      notification: !this.state.notification,
    });
  };

  detectChanges = (ecosystemContent, ecosystemCategories) => {
    let c = 0,
      e = 0,
      d = 0; //cntents
    let cC = 0,
      eC = 0,
      dC = 0; //categories
    let cSC = 0,
      eSC = 0,
      dSC = 0; //sub categories
    ecosystemContent.forEach((ea) => {
      if (ea.id && ea.id !== null) {
        if (ea.is_deleted === true) d = d + 1;
        else {
          if (this.findUpdates(ea.uid)) e = e + 1;
        }
      } else if (ea.is_deleted !== true) c = c + 1;
    });
    ecosystemCategories.forEach((pc) => {
      if (pc.id && pc.id !== null) {
        if (this.findUpdatesCategory(pc.id)) {
          eC = eC + 1;
        }
      } else {
        if (pc.name !== null && pc.name !== "") cC = cC + 1;
      }
      pc.subcategories.forEach((psc) => {
        if (psc.id && psc.id !== null) {
          if (this.findUpdatesSubCategory(pc.id, psc.id)) {
            eSC = eSC + 1;
          }
        } else {
          if (psc.name !== null && psc.name !== "") cSC = cSC + 1;
        }
      });
    });
    window.removeEventListener("beforeunload", this.onUnload);
    if (
      (c > 0 ||
        d > 0 ||
        e > 0 ||
        cC > 0 ||
        dC > 0 ||
        eC > 0 ||
        cSC > 0 ||
        dSC > 0 ||
        eSC > 0) &&
      ecosystemContent.length
    )
      window.addEventListener("beforeunload", this.onUnload);
    this.setState({
      changesMade:
        (c > 0 ||
          d > 0 ||
          e > 0 ||
          cC > 0 ||
          dC > 0 ||
          eC > 0 ||
          cSC > 0 ||
          dSC > 0 ||
          eSC > 0) &&
        ecosystemContent.length > 0
          ? true
          : false,
    });
  };

  findUpdatesCategory = (categoryId) => {
    let newCategory = this.props.ecosystem_details.ecosystemCategories.filter(
      (comp) => comp.id === categoryId
    )[0];
    let oldCategory = this.state.masterCategories.filter(
      (comp) => comp.id === categoryId
    )[0];
    if (isEqual(newCategory.name, oldCategory.name) === true) return false;
    return true;
  };

  findUpdatesSubCategory = (categoryId, subcategoryId) => {
    let newCategory = this.props.ecosystem_details.ecosystemCategories.filter(
      (comp) => comp.id === categoryId
    )[0];
    let oldCategory = this.state.masterCategories.filter(
      (comp) => comp.id === categoryId
    )[0];
    let newSubCategory = newCategory.subcategories.filter(
      (comp) => comp.id === subcategoryId
    )[0];
    let oldSubCategory = oldCategory.subcategories.filter(
      (comp) => comp.id === subcategoryId
    )[0];
    if (isEqual(newSubCategory.name, oldSubCategory.name) === true)
      return false;
    return true;
  };

  onUnload = (ev) => {
    ev.preventDefault();
    ev.returnValue = "";
  };

  componentDidMount() {
    let ecosystemId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    EcosystemServices.getEcosystemTemplates()
      .then((res) => {
        let response = res && res.data;
        this.props.ecosystemTemplates(response);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
    this.initContent(ecosystemId);
  }

  //API For getting Image and Quote
  getImageAndQuote = (mapping_id, transaction_id) => {
    QuoteImageServices.showImageandQuote(mapping_id, transaction_id)
      .then((res) => {
        const { content, image, id } = res.data?.data ?? "";
        let lastPart = "";
        if (image) {
          const urlParts = image.split("/");
          lastPart = last(urlParts);
        }
        this.setState({
          quoteId: id,
          quoteContent: content ?? "",
          labelquoteContent: content ?? "",
          quoteImageUrl: image ?? null,
          quoteImageName: lastPart,
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  initContent = (ecosystemTransactionId) => {
    let contents = [];
    EcosystemServices.getManageContentTransactionContent(ecosystemTransactionId)
      .then((res) => {
        let totalResponse = res && res.data && res.data.data && res.data.data;
        totalResponse = { ...totalResponse };
        let content_role_state_histories =
          res && res.data && res.data.data && res.data.data.role_state_histories
            ? res.data.data.role_state_histories
            : [];
        let transaction_comments = [];
        content_role_state_histories.forEach((res) => {
          transaction_comments.push(...res.comments);
        });
        let transactionDetails = {
          comment: null,
          openCommentsFlag: false,
          id: totalResponse.id,
          comments: transaction_comments,
          next_states: totalResponse.next_states,
          role_state: totalResponse.role_state ? totalResponse.role_state : {},
          eco_system: totalResponse.eco_system ? totalResponse.eco_system : {},
        };
        this.props.ecosystemDetails(totalResponse.eco_system);
        this.getEcosystemPublishedContent(totalResponse.eco_system.id);

        const currentState = totalResponse.role_state?.state ?? null;
        if (currentState && !currentState?.short_name?.includes("draft")) {
          this.getImageAndQuote(
            totalResponse.mapping_id ?? null,
            totalResponse.id ?? null
          );
        }

        let responseMeta = JSON.parse(totalResponse.meta);
        let responseCategories = totalResponse.categories
          .map((res) => {
            let category = null;
            if (responseMeta) {
              category = responseMeta.find((k) => k.categoryId === res.id);
            }

            res.is_selected = true;
            res.is_deleted = false;
            res.uid = Math.random();
            if (res.subcategories) {
              res.subcategories = res.subcategories
                .map((res_sub) => {
                  let subCategoryData = null;
                  if (category) {
                    subCategoryData = category.subCategories.find(
                      (k) => k.subCategoryId === res_sub.id
                    );
                  }

                  res_sub.uid = Math.random();
                  res_sub.is_selected = true;
                  res_sub.is_deleted = false;
                  res_sub.contents.forEach((cn) => {
                    cn.uid = Math.random();
                    cn.subcategory_id = res_sub.uid;
                    cn.is_deleted = cn.is_deleted ? cn.is_deleted === 1 : false;
                    cn.comment = null;
                    cn.openCommentsFlag = false;
                  });
                  if (res_sub.contents) {
                    contents.push(...res_sub.contents);
                  }
                  if (category && subCategoryData) {
                    return { ...res_sub, ...subCategoryData };
                  } else {
                    return { ...res_sub };
                  }
                })
                .sort((a, b) => a.subCategoryIndex - b.subCategoryIndex);
            }
            if (category) {
              delete category.subCategories;
              return { ...res, ...category };
            } else {
              return { ...res };
            }
          })
          .sort((a, b) => a.categoryIndex - b.categoryIndex);

        this.unCheckOnNoContent(this.props.ecosystem_details.ecosystemContent);
        this.setState(
          {
            masterCategories: JSON.parse(JSON.stringify(responseCategories)),
            masterContent: JSON.parse(JSON.stringify(contents)),
            changesMade: false,
            transactionId: totalResponse.id ? totalResponse.id : null,
            apiError: null,
            transaction_comment: null,
            mapping_id: totalResponse.mapping_id
              ? totalResponse.mapping_id
              : null,
            mapping_type: totalResponse.type,
            currentState: totalResponse.role_state?.state ?? null,
          },
          () => {
            window.removeEventListener("beforeunload", this.onUnload);
            this.props.ecosystemCategories(responseCategories);
            this.props.ecosystemContent(contents);
            this.props.ecosystemTransactionDetails(transactionDetails);
            this.props.activeMenuTab(
              transactionDetails.next_states.includes(
                constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_DRAFT.SLUG
              )
                ? constants.APPLICATION_ROUTE.MANAGE_CONTENT_ECOSYSTEM_MY_DRAFTS
                    .ROUTE
                : constants.APPLICATION_ROUTE
                    .MANAGE_CONTENT_ECOSYSTEM_TRACK_SUBMISSIONS.ROUTE
            );
          }
        );
      })
      .catch((error) => {
        this.props.ecosystemCategories([]);
        this.props.ecosystemContent([]);
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  getEcosystemPublishedContent = (ecosystemId) => {
    EcosystemServices.getEcosystemContent(ecosystemId)
      .then((res) => {
        let response = res && res.data && res.data.data;
        this.setState({
          publishedContent: CustomCloneDeep(response),
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  getEcosystemContent = (ecosystemId, subcategories) => {
    EcosystemServices.getEcosystemContent(ecosystemId)
      .then((res) => {
        let response = res && res.data && res.data.data;
        response.forEach((res) => {
          res.uid = Math.random();
          res.is_deleted = false;
          if (
            subcategories &&
            subcategories.find((s) => s.id === res.subcategory_id)
          ) {
            res.subcategory_id = subcategories.find(
              (s) => s.id === res.subcategory_id
            ).uid;
          }
        });
        this.props.ecosystemContent(response);
        this.unCheckOnNoContent(this.props.ecosystem_details.ecosystemContent);
        this.setState(
          {
            masterContent: JSON.parse(JSON.stringify(response)),
            changesMade: false,
          },
          () => {
            window.removeEventListener("beforeunload", this.onUnload);
          }
        );
      })
      .catch((error) => {
        this.props.ecosystemContent([]);
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
    this.props.ecosystemContent([]);
  };

  unCheckOnNoContent = (content) => {
    let original_categories = [
      ...this.props.ecosystem_details.ecosystemCategories,
    ];
    original_categories.forEach((res) => {
      if (res.subcategories) {
        res.subcategories.forEach((res_sub) => {
          let content_found = content.filter(
            (row) =>
              row.subcategory_id === res_sub.uid && row.is_deleted === false
          ).length;
          if (content_found > 0) {
            res_sub.is_selected = true;
          } else {
            res_sub.is_selected = false;
          }
        });
        let total = res.subcategories.length;
        let total_selected = res.subcategories.filter(
          (row) => row.is_selected === true
        ).length;
        if (total_selected === total && total > 0) {
          res.is_selected = true;
        } else {
          res.is_selected = false;
        }
      }
    });
    this.props.ecosystemCategories(original_categories);
  };

  toggleCheckboxParent = (e, type, category_id, sub_category_id) => {
    let checkbox_selection = e.target ? e.target.checked : e;
    if (checkbox_selection === true)
      this.toggleCheckbox(
        checkbox_selection,
        type,
        category_id,
        sub_category_id
      );
    else {
      this.confirmDailog({
        checkbox_selection,
        type,
        category_id,
        sub_category_id,
      });
    }
  };

  toggleCheckbox = (
    checkbox_selection,
    type,
    category_id,
    sub_category_id,
    is_edit
  ) => {
    let original_categories = [
      ...this.props.ecosystem_details.ecosystemCategories,
    ];
    if (type === "category") {
      original_categories = original_categories.map((res) => {
        if (res.uid === category_id) {
          res.is_selected = checkbox_selection;
          res.subcategories.forEach((sub) => {
            sub.is_selected = checkbox_selection;
          });
          this.handleAddRemoveTemplateCategory(category_id, checkbox_selection);
        }
        return res;
      });
    } else if (type === "sub_category") {
      let selected_states = [];
      let subid = 0;
      for (let i = 0; i < original_categories.length; i++) {
        selected_states.push(original_categories[i].is_selected);
      }
      original_categories = original_categories.map((res, index) => {
        if (res.uid === category_id) {
          if (res.subcategories) {
            res.subcategories.map((res_sub) => {
              if (res_sub.uid === sub_category_id) {
                res_sub.is_selected = checkbox_selection;
                subid = res_sub.uid;
              }

              return res_sub;
            });
            this.handleAddRemoveTemplateSubCategory(subid, checkbox_selection);
            let total_selected = res.subcategories.filter(
              (row) => row.is_selected === true
            ).length;
            if (total_selected === 0) {
              res.is_selected = false;
            }
            if (total_selected > 0 && res.is_selected === false) {
              res.is_selected = true;
            }
          }
          selected_states[index] = res.is_selected;
        }
        return res;
      });
      original_categories = original_categories.map((res, index) => {
        res.is_selected = selected_states[index];
        return res;
      });
    }
    this.props.ecosystemCategories(original_categories);
  };

  getEmptyContent = (sub_category_id) => {
    let emptyContent = {};
    emptyContent.id = null;
    emptyContent.uid = Math.random();
    emptyContent.data = "{}";
    emptyContent.subcategory_title = "";
    emptyContent.published_date = new Date();
    emptyContent.subcategory_id = sub_category_id;
    emptyContent.is_deleted = false;
    emptyContent.parent_content_id = null;
    emptyContent.comments = "";
    return emptyContent;
  };

  onTemplateChangeHandler = (content_id, template_id) => {
    let template_found = this.props.ecosystem_details.ecosystemTemplates.filter(
      (row) => row.id === template_id
    );
    if (template_found && template_found.length > 0) {
      let original_content = [...this.props.ecosystem_details.ecosystemContent];
      let index = original_content.findIndex((row) => row.uid === content_id);
      original_content[index].template_id = template_found[0].id;
      original_content[index].template = template_found[0];
      let data = {};
      let extractParams =
        original_content[index].template.content.match(/[^{}]+(?=})/g);
      if (extractParams && extractParams.length > 0) {
        extractParams.forEach((param) => {
          data[param] = { type: "empty", value: "" };
        });
      }
      original_content[index].data = JSON.stringify(data);
      this.props.ecosystemContent(original_content);
    }
  };

  addEmptyContent = (sub_category_id) => {
    let original_content = [...this.props.ecosystem_details.ecosystemContent];
    let original_categories = [
      ...this.props.ecosystem_details.ecosystemCategories,
    ];
    let getEmptyContent = this.getEmptyContent(sub_category_id);
    original_content.push(getEmptyContent);
    this.props.ecosystemContent(original_content);
    this.unCheckOnNoContent(original_content);
    this.detectChanges(original_content, original_categories);
  };

  handleAddRemoveTemplateCategory = (category_id, is_selected) => {
    let original_categories = [
      ...this.props.ecosystem_details.ecosystemCategories,
    ];
    let checkbox_selection = is_selected;
    let original_content = [...this.props.ecosystem_details.ecosystemContent];
    original_categories.forEach((res) => {
      if (res.uid === category_id) {
        res.is_selected = checkbox_selection;
        if (res.subcategories) {
          res.subcategories.forEach((res_sub) => {
            if (checkbox_selection === true) {
              let content_found =
                this.props.ecosystem_details.ecosystemContent.filter(
                  (row) =>
                    row.subcategory_id === res_sub.uid &&
                    row.is_deleted === false
                ).length;
              if (content_found > 0) {
              } else {
                original_content.push(this.getEmptyContent(res_sub.uid));
              }
            } else {
              original_content.forEach((item, index) => {
                if (item.subcategory_id === res_sub.uid) item.is_deleted = true;
                if (
                  item.is_deleted &&
                  item.id === null &&
                  item.subcategory_id === res_sub.uid
                )
                  delete original_content[index];
              });
            }
          });
        }
      }
    });
    let remainingContents = original_content.filter(
      (row) => row.id !== undefined
    );
    this.props.ecosystemContent(remainingContents);
    this.detectChanges(remainingContents, original_categories);
  };

  handleAddRemoveTemplateSubCategory = (sub_category_id, is_selected) => {
    let content_found = this.props.ecosystem_details.ecosystemContent.filter(
      (row) =>
        row.subcategory_id === sub_category_id && row.is_deleted === false
    ).length;
    if (is_selected === true) {
      if (content_found > 0) {
      } else {
        this.addEmptyContent(sub_category_id);
      }
    } else {
      this.removeSubCategoryContent(sub_category_id);
    }
  };

  removeSubCategoryContent = (sub_category_id) => {
    let original_content = [...this.props.ecosystem_details.ecosystemContent];
    let original_categories = [
      ...this.props.ecosystem_details.ecosystemCategories,
    ];
    original_content.forEach((item, index) => {
      if (item.subcategory_id === sub_category_id) item.is_deleted = true;
      if (item.is_deleted && item.id === null) delete original_content[index];
    });
    let remainingContents = original_content.filter(
      (row) => row.id !== undefined
    );
    this.props.ecosystemContent(remainingContents);
    this.unCheckOnNoContent(remainingContents);
    this.detectChanges(remainingContents, original_categories);
  };

  removeContent = (uid) => {
    let original_content = [...this.props.ecosystem_details.ecosystemContent];
    let original_categories = [
      ...this.props.ecosystem_details.ecosystemCategories,
    ];
    original_content.forEach((item, index) => {
      if (item.uid === uid) item.is_deleted = true;
      if (item.is_deleted && item.id === null && item.uid === uid)
        delete original_content[index];
    });
    let remainingContents = original_content.filter(
      (row) => row.id !== undefined
    );
    this.props.ecosystemContent(remainingContents);
    this.unCheckOnNoContent(remainingContents);
    this.detectChanges(remainingContents, original_categories);
  };

  undoRemoveContent = (uid) => {
    let original_content = [...this.props.ecosystem_details.ecosystemContent];
    let original_categories = [
      ...this.props.ecosystem_details.ecosystemCategories,
    ];
    original_content.forEach((item) => {
      if (item.uid === uid) item.is_deleted = false;
    });
    this.props.ecosystemContent(original_content);
    this.unCheckOnNoContent(original_content);
    this.detectChanges(original_content, original_categories);
  };

  dateOnChangeHandler = (key, val) => {
    let original_content = [...this.props.ecosystem_details.ecosystemContent];
    let original_categories = [
      ...this.props.ecosystem_details.ecosystemCategories,
    ];
    let index = original_content.findIndex((row) => row.uid === key);
    original_content[index].published_date = val;
    this.props.ecosystemContent(original_content);
    this.detectChanges(original_content, original_categories);
  };

  onContentChangeHandler = (uid, placeholder, value, valueType) => {
    let original_content = [...this.props.ecosystem_details.ecosystemContent];
    let original_categories = [
      ...this.props.ecosystem_details.ecosystemCategories,
    ];
    let index = original_content.findIndex((row) => row.uid === uid);
    let jsonData = JSON.parse(original_content[index].data);
    jsonData[placeholder].type = valueType;
    if (valueType === "image" && value.startsWith("data:")) {
      let splitValue = value.split("|");
      jsonData[placeholder].value = splitValue[0] ? splitValue[0] : "";
      jsonData[placeholder].imageName = splitValue[1] ? splitValue[1] : "";
    } else {
      jsonData[placeholder].value = value;
    }
    let stringData = JSON.stringify(jsonData);
    original_content[index].data = stringData;
    this.props.ecosystemContent(original_content);
    this.detectChanges(original_content, original_categories);
  };

  onSectionTitleChangeHandler = (uid, value) => {
    let original_content = [...this.props.ecosystem_details.ecosystemContent];
    let original_categories = [
      ...this.props.ecosystem_details.ecosystemCategories,
    ];
    let index = original_content.findIndex((row) => row.uid === uid);
    original_content[index].subcategory_title = value;
    this.props.ecosystemContent(original_content);
    this.detectChanges(original_content, original_categories);
  };

  saveContent = (type) => {
    let finalData = [];
    let original_categories = [
      ...this.props.ecosystem_details.ecosystemCategories,
    ];
    let original_content = [...this.props.ecosystem_details.ecosystemContent];
    let isDuplicatCategory = [];
    let isDuplicatSubCategory = [];
    let emptyCategoryName = false;
    let emptySubCategoryName = false;
    let isMissingTemplate = false;
    original_categories.forEach((cat) => {
      let indData = {};
      indData.id = cat.id ? cat.id : null;
      indData.name = cat.name ? cat.name : null;
      indData.rank = cat.rank ? cat.rank : null;
      let category_contents_count = 0;
      if (
        original_categories.filter(
          (c) => c.name.toLowerCase() === cat.name.toLowerCase()
        ).length > 1
      ) {
        isDuplicatCategory.push(cat.name);
      }
      let subcats = [];
      cat.subcategories.forEach((subcat) => {
        let indSubcat = {};
        indSubcat.id = subcat.id ? subcat.id : null;
        indSubcat.name = subcat.name ? subcat.name : null;
        indSubcat.rank = subcat.rank ? subcat.rank : null;
        let sub_category_contents_count = 0;
        if (
          cat.subcategories.filter(
            (cs) => cs.name.toLowerCase() === subcat.name.toLowerCase()
          ).length > 1
        ) {
          isDuplicatSubCategory.push(subcat.name);
        }
        let contents = [];
        original_content
          .filter((content) => content.subcategory_id === subcat.uid)
          .forEach((con) => {
            if (con.template_id) {
              category_contents_count = category_contents_count + 1;
              sub_category_contents_count = sub_category_contents_count + 1;
              contents.push({
                template_id: con.template_id,
                content_id: con.id ? con.id : null,
                parent_content_id: con.id ? con.id : null,
                subcategory_title: con.subcategory_title,
                published_date: con.published_date,
                content: JSON.parse(con.data),
                is_deleted: con.is_deleted ? 1 : 0,
                deleted: con.is_deleted ? 1 : 0,
              });
            } else isMissingTemplate = true;
          });
        indSubcat.data = contents;
        subcats.push(indSubcat);
        if (
          sub_category_contents_count > 0 &&
          (subcat.name === null || subcat.name === "")
        )
          emptySubCategoryName = true;
      });
      indData.subcategories = subcats;
      finalData.push(indData);
      if (category_contents_count > 0 && (cat.name === null || cat.name === ""))
        emptyCategoryName = true;
    });
    if (emptyCategoryName) {
      this.setState({
        apiError: "Category name cannot be blank.",
      });
      return false;
    }
    if (emptySubCategoryName) {
      this.setState({
        apiError: "Sub-category name cannot be blank.",
      });
      return false;
    }
    if (isDuplicatCategory.length > 0) {
      this.setState({
        apiError: "The value you entered in a category is already in the list.",
      });
      return false;
    }
    if (isDuplicatSubCategory.length > 0) {
      this.setState({
        apiError:
          "The value you entered in a sub-category is already in the list",
      });
      return false;
    }
    if (isMissingTemplate) {
      this.setState({
        apiError:
          "Template is missing in the content section, Please choose the template",
      });
      return false;
    }
    if (
      isDuplicatCategory.length === 0 &&
      isDuplicatSubCategory.length === 0 &&
      !emptyCategoryName &&
      !emptySubCategoryName &&
      !isMissingTemplate
    ) {
      let dailogModalHeader = (
        <>
          <h3 className="text-darkblue2 font-weight-bold">
            {type === constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_DRAFT.SLUG
              ? constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_DRAFT
                  .BUTTON_TITLE
              : constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_SUBMIT
                  .BUTTON_TITLE}
          </h3>
        </>
      );
      let dailogModalContent;
      let dailogModalStyleType;
      switch (type) {
        case constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_DRAFT.SLUG:
          dailogModalHeader = (
            <>
              <h3 className="text-darkblue2 font-weight-bold">
                {
                  constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_DRAFT
                    .BUTTON_TITLE
                }
              </h3>
            </>
          );
          dailogModalContent = (
            <>
              <div className="activate-body">
                <h2 className="font-weight-semibold mb-0">
                  {
                    constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_DRAFT
                      .CONFIRM_MESSAGE
                  }
                </h2>
              </div>
            </>
          );
          dailogModalStyleType = "success-modal";
          break;
        case constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_SUBMIT.SLUG:
          this.setState({
            quoteImageDailogModal: true,
            dailogModalConfig: {
              type: type,
              id: null,
              transaction_id: this.state.transactionId,
              mapping_id: this.state.mapping_id,
              mapping_type: this.state.mapping_type,
            },
            apiError: null,
          });
          break;
        case constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_RE_SUBMIT.SLUG:
          dailogModalHeader = (
            <>
              <h3 className="text-darkblue2 font-weight-bold">
                {
                  constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_RE_SUBMIT
                    .BUTTON_TITLE
                }
              </h3>
            </>
          );
          dailogModalContent = (
            <>
              <div className="activate-body">
                <h2 className="font-weight-semibold mb-0">
                  {
                    constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_RE_SUBMIT
                      .CONFIRM_MESSAGE
                  }
                </h2>
              </div>
            </>
          );
          dailogModalStyleType = "success-modal";
          break;
        case constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_REJECT.SLUG:
          dailogModalHeader = (
            <>
              <h3 className="text-darkblue2 font-weight-bold">
                {
                  constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_REJECT
                    .BUTTON_TITLE
                }
              </h3>
            </>
          );
          dailogModalContent = (
            <>
              <div className="remove-body">
                <h2 className="font-weight-semibold mb-0">
                  {
                    constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_REJECT
                      .CONFIRM_MESSAGE
                  }
                </h2>
              </div>
            </>
          );
          dailogModalStyleType = "danger-modal";
          break;
        case constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_APPROVE.SLUG:
          dailogModalHeader = (
            <>
              <h3 className="text-darkblue2 font-weight-bold">
                {
                  constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_APPROVE
                    .BUTTON_TITLE
                }
              </h3>
            </>
          );
          dailogModalContent = (
            <>
              <div className="activate-body">
                <h2 className="font-weight-semibold mb-0">
                  {
                    constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_APPROVE
                      .CONFIRM_MESSAGE
                  }
                </h2>
              </div>
            </>
          );
          dailogModalStyleType = "success-modal";
          break;
        case constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_PUBLISH.SLUG:
          dailogModalHeader = (
            <>
              <h3 className="text-darkblue2 font-weight-bold">
                {
                  constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_PUBLISH
                    .BUTTON_TITLE
                }
              </h3>
            </>
          );
          dailogModalContent = (
            <>
              <div className="activate-body">
                <h2 className="font-weight-semibold mb-0">
                  {
                    constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_PUBLISH
                      .CONFIRM_MESSAGE
                  }
                </h2>
              </div>
              <div className="model-notification">
                <input
                  type="checkbox"
                  onClick={this.turnOffNotification}
                  name="select"
                />
                <h3>Don't send notifications</h3>
              </div>
            </>
          );
          dailogModalStyleType = "success-modal";
          break;
        default:
          break;
      }
      if (type !== constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_SUBMIT.SLUG) {
        this.setState({
          dailogModal: true,
          dailogModalHeader: dailogModalHeader,
          dailogModalContent: dailogModalContent,
          dailogModalConfig: { type: type, id: null },
          dailogModalStyleType: dailogModalStyleType,
          apiError: null,
        });
      }
    }
  };

  resetContent = () => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Warning</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="inactive-body">
          <h2 className="font-weight-semibold mb-0">
            {
              constants.MANAGE_CONTENT_TRANSACTION_DETAILS
                .RESET_CHANGES_CONFIRM_MESSAGE
            }
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "reset_content", id: null },
      dailogModalStyleType: "inactive-modal",
    });
  };

  saveContentSuccess = (action) => {
    let message = "";
    switch (action) {
      case constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_DRAFT.SLUG:
        message =
          constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_DRAFT.SUCCESS_MESSAGE;
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_SUBMIT.SLUG:
        message =
          constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_SUBMIT.SUCCESS_MESSAGE;
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_RE_SUBMIT.SLUG:
        message =
          constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_RE_SUBMIT
            .SUCCESS_MESSAGE;
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_APPROVE.SLUG:
        message =
          constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_APPROVE
            .SUCCESS_MESSAGE;
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_PUBLISH.SLUG:
        message =
          constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_PUBLISH
            .SUCCESS_MESSAGE;
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_REJECT.SLUG:
        message =
          constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_REJECT.SUCCESS_MESSAGE;
        break;
      default:
        break;
    }
    Toast(message, "success");
    this.setState(
      {
        changesMade: false,
        dailogModal: false,
        dailogModalHeader: "",
        dailogModalContent: "",
        dailogModalConfig: {},
        dailogModalStyleType: "",
      },
      () => {
        window.removeEventListener("beforeunload", this.onUnload);
        if (
          action === constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_SUBMIT.SLUG
        )
          this.props.history.push({
            pathname: formatRoute(
              constants.APPLICATION_ROUTE
                .MANAGE_CONTENT_ECOSYSTEM_TRACK_SUBMISSIONS.ROUTE
            ),
          });
        else this.props.history.goBack();
      }
    );
  };

  saveContent2 = async (org_action, toastType, isPublished) => {
    let action = org_action.replace("ecosystem_", "");
    let ecosystemTransactionId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    let finalData = [];
    let original_categories = [
      ...this.props.ecosystem_details.ecosystemCategories,
    ];
    let original_content = [...this.props.ecosystem_details.ecosystemContent];
    let isDuplicatCategory = [];
    let isDuplicatSubCategory = [];
    original_categories.forEach((cat) => {
      let indData = {};
      indData.id = cat.id ? cat.id : null;
      indData.name = cat.name ? cat.name : null;
      indData.rank = cat.rank ? cat.rank : null;
      if (
        original_categories.filter(
          (c) => c.name.toLowerCase() === cat.name.toLowerCase()
        ).length > 1
      ) {
        isDuplicatCategory.push(cat.name);
      }
      let subcats = [];
      cat.subcategories.forEach((subcat) => {
        let indSubcat = {};
        indSubcat.id = subcat.id ? subcat.id : null;
        indSubcat.name = subcat.name ? subcat.name : null;
        indSubcat.rank = subcat.rank ? subcat.rank : null;
        if (
          cat.subcategories.filter(
            (cs) => cs.name.toLowerCase() === subcat.name.toLowerCase()
          ).length > 1
        ) {
          isDuplicatSubCategory.push(subcat.name);
        }
        let contents = [];
        original_content
          .filter((content) => content.subcategory_id === subcat.uid)
          .forEach((con) => {
            if (con.template_id) {
              contents.push({
                template_id: con.template_id,
                content_id: con.id ? con.id : null,
                parent_content_id: con.parent_content_id
                  ? con.parent_content_id
                  : null,
                subcategory_title: con.subcategory_title,
                published_date: con.published_date,
                content: JSON.parse(con.data),
                is_deleted: con.is_deleted
                  ? con.parent_content_id !== null
                    ? 1
                    : 0
                  : 0,
                deleted: con.is_deleted
                  ? con.parent_content_id === null
                    ? 1
                    : 0
                  : 0,
                comment: con.comment ? con.comment : null,
              });
            }
          });
        indSubcat.data = contents;
        subcats.push(indSubcat);
      });
      indData.subcategories = subcats;
      finalData.push(indData);
    });
    await EcosystemServices.saveEcosystemTransactionContent(
      ecosystemTransactionId,
      {
        categories: finalData,
        transaction_id: ecosystemTransactionId,
        comment:
          this.props.ecosystem_details.ecosystemTransactionDetails &&
          this.props.ecosystem_details.ecosystemTransactionDetails.comment
            ? this.props.ecosystem_details.ecosystemTransactionDetails.comment
            : null,
        is_for_publish: isPublished,
        silent_notification: this.state.notification,
      },
      action
    )
      .then(async (res) => {
        if (
          (action ===
            constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_SUBMIT.SLUG &&
            this.state.quoteContent) ||
          (this.state.base64_data && this.state.quoteImageName)
        ) {
          const body = {
            mapping_id: this.state.mapping_id ?? "",
            transaction_id: res.data.transaction_id ?? "",
            mapping_type: this.state.mapping_type ?? "",
            content: this.state.quoteContent ?? "",
            base64_data: this.state.base64_data ?? "",
            imageName: this.state.quoteImageName ?? "",
          };
          await QuoteImageServices.addImageandQuote(body)
            .then((res) => {
              this.setState({
                base64_data: null,
              });
            })
            .catch((error) => {
              Toast(
                error && error.data && error.data.error
                  ? error.data.error
                  : constants.ERROR.SOMETHING_WENT_WRONG,
                "error"
              );
            });
        }
        window.removeEventListener("beforeunload", this.onUnload);
        this.setState(
          {
            dailogModal: false,
            dailogModalHeader: "",
            dailogModalContent: "",
            dailogModalConfig: {},
            dailogModalStyleType: "",
            notification: false,
            quoteImageDailogModal: false,
            config: {},
          },
          () => {
            this.saveContentSuccess(toastType);
          }
        );
      })
      .catch((error) => {
        this.setState({
          notification: false,
          apiError:
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
        });
      });
  };

  resetContent2 = () => {
    let ecosystemId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    this.initContent(ecosystemId);
  };

  findUpdates = (uid) => {
    let newContent = this.props.ecosystem_details.ecosystemContent.filter(
      (comp) => comp.uid === uid
    )[0];
    let oldContent = this.state.masterContent.filter(
      (comp) => comp.uid === uid
    )[0];
    if (
      isEqual(newContent.subcategory_title, oldContent.subcategory_title) ===
        true &&
      isEqual(newContent.data, oldContent.data) === true &&
      isEqual(newContent.published_date, oldContent.published_date) === true
    )
      return false;
    return true;
  };

  confirmDailog = (config) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Delete</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {config.type === "category"
              ? constants.MANAGE_CONTENT_TRANSACTION_DETAILS
                  .DELETE_CATEGORY_CONFIRM_MESSAGE
              : constants.MANAGE_CONTENT_TRANSACTION_DETAILS
                  .DELETE_SUB_CATEGORY_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: config,
      dailogModalStyleType: "danger-modal",
    });
  };

  dailogModalAccept = (config) => {
    this.setState(
      {
        dailogModal: false,
        dailogModalHeader: "",
        dailogModalContent: "",
        dailogModalConfig: {},
        dailogModalStyleType: "",
      },
      () => {
        this.matchEvent(config);
      }
    );
  };

  matchEvent = (config) => {
    switch (config.type) {
      case "category":
        this.toggleCheckbox(
          config.checkbox_selection,
          config.type,
          config.category_id,
          config.sub_category_id
        );
        break;
      case "sub_category":
        this.toggleCheckbox(
          config.checkbox_selection,
          config.type,
          config.category_id,
          config.sub_category_id
        );
        break;
      case "switch_segment":
        this.setState({ changesMade: false }, () => {
          this.switchSegment2(config.id);
        });
        break;
      case "switch_company":
        this.setState({ changesMade: false }, () => {
          this.handleCompanyChange2(config.id);
        });
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_DRAFT.SLUG:
        this.saveContent2(
          constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_DRAFT.SLUG,
          config.type
        );
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_SUBMIT.SLUG:
        this.saveContent2(
          constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_SUBMIT.SLUG,
          config.type
        );
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_RE_SUBMIT.SLUG:
        this.saveContent2(
          constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_RE_SUBMIT.SLUG,
          config.type
        );
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_APPROVE.SLUG:
        this.saveContent2(
          constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_APPROVE.SLUG,
          config.type
        );
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_REJECT.SLUG:
        this.saveContent2(
          constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_REJECT.SLUG,
          config.type
        );
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_PUBLISH.SLUG:
        this.saveContent2(
          constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_APPROVE.SLUG,
          config.type,
          "is_for_publish"
        );
        break;
      case "reset_content":
        this.resetContent2();
        break;
      default:
        break;
    }
  };

  quoteImageDailogModalStoreAccept = (config) => {
    const body = {
      mapping_id: this.state.mapping_id ?? "",
      transaction_id: config.transaction_id ?? "",
      mapping_type: this.state.mapping_type ?? "",
      content: this.state.quoteContent ?? "",
      base64_data: this.state.base64_data ?? "",
      imageName: this.state.quoteImageName ?? "",
    };
    QuoteImageServices.addImageandQuote(body)
      .then((res) => {
        const { content, image } = res.data.data;
        this.setState({
          base64_data: null,
          labelquoteContent: content ?? "",
          quoteImageUrl: image ?? null,
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
    this.setState(
      {
        quoteImageDailogModal: false,
      },
      () => {
        this.matchEvent(config);
      }
    );
  };

  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
    });
  };

  quoteImageDailogModalAccept = (config) => {
    let transaction_id = this.state.transactionId ?? "";
    if (this.state.quoteImageUrl) {
      this.setState({
        image_delete: false,
      });
    }
    const body = {
      content: this.state.quoteContent ?? "",
      base64_data: this.state.base64_data ?? "",
      imageName: this.state.quoteImageName ?? "",
      image_delete: !this.state.base64_data ? this.state.image_delete : false,
    };
    // API For Updating Image and Quote
    QuoteImageServices.updateImageandQuote(
      this.state.quoteId,
      transaction_id,
      body
    )
      .then((res) => {
        const { content, image, id } = res.data.data;
        let lastPart = "";
        if (image) {
          const urlParts = image.split("/");
          lastPart = last(urlParts);
        }
        this.setState({
          quoteId: id,
          labelquoteContent: content ?? "",
          quoteImageUrl: image ?? null,
          quoteImageName: lastPart,
          base64_data: null,
          image_delete: false,
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
    this.setState(
      {
        quoteImageDailogModal: false,
      },
      () => {
        this.matchEvent(config);
      }
    );
  };
  quoteImageDailogModalDecline = () => {
    this.setState({
      quoteImageDailogModal: false,
      config: {},
    });
    setTimeout(() => {
      this.setState({
        quoteContent: this.state.labelquoteContent,
      });
    }, 500);
  };
  onEditClicked = () => {
    this.setState({
      quoteImageDailogModal: true,
      dailogModalConfig: {
        type: null,
        id: null,
        transaction_id: this.state.transactionId,
      },
      apiError: null,
    });
  };
  handleQuoteContent = (e) => {
    this.setState({
      quoteContent: e.target.value,
    });
  };
  handleQuoteImage = (base64Url, imageName) => {
    let filteredBase64Url = base64Url.split(";base64,")[1];
    this.setState({
      base64_data: filteredBase64Url,
      quoteImageName: imageName,
    });
  };

  componentWillUnmount = () => {
    this.props.ecosystemDetails({});
    this.props.ecosystemContent({});
    this.props.ecosystemCategories([]);
    this.props.ecosystemTemplates([]);
  };

  onNameChangeHandler = (type, category_id, sub_category_id, value) => {
    let original_content = [...this.props.ecosystem_details.ecosystemContent];
    let original_categories = [
      ...this.props.ecosystem_details.ecosystemCategories,
    ];
    if (type === "category") {
      original_categories.forEach((res) => {
        if (res.uid === category_id) {
          res.name = value;
        }
      });
    } else if (type === "sub_category") {
      original_categories.forEach((res) => {
        if (res.uid === category_id) {
          if (res.subcategories) {
            res.subcategories.forEach((res_sub) => {
              if (res_sub.uid === sub_category_id) {
                res_sub.name = value;
              }
            });
          }
        }
      });
    }
    this.props.ecosystemCategories(original_categories);
    this.detectChanges(original_content, original_categories);
  };

  addCategory = (data, edit, category_name) => {
    if (!edit) {
      let original_content = [...this.props.ecosystem_details.ecosystemContent];
      let original_categories = [
        ...this.props.ecosystem_details.ecosystemCategories,
      ];
      let published_categories =
        this.state.publishedContent && this.state.publishedContent.categories
          ? this.state.publishedContent.categories
          : [];
      let rank1 = 0;
      let rank2 = 0;
      if (published_categories.length > 0) {
        rank1 = Math.max(...published_categories.map((o) => o.rank));
      }
      if (original_categories.length > 0) {
        rank2 = Math.max(...original_categories.map((o) => o.rank));
      }
      let rank = Math.max(rank1, rank2);
      let category_name = data[0];
      data.shift();
      let sub_categories = [];
      let newCategory = {
        id: null,
        uid: Math.random(),
        name: category_name,
        is_selected: false,
        subcategories: [],
        rank: rank + 1,
      };
      if (
        !original_categories.filter((s) => s.name === category_name).length > 0
      ) {
        let subcat = [];
        for (let i = 0; i < data.length; i++) {
          subcat.push({
            id: null,
            uid: Math.random(),
            name: data[i],
            is_selected: false,
            rank: i + 1,
          });
        }
        newCategory.subcategories = subcat;
        original_categories.push(newCategory);
        this.props.ecosystemCategories(original_categories);
        this.detectChanges(original_content, original_categories);
        return true;
      } else {
        Toast("Category Name Already Exists", "error");
        this.setState({
          adddataSuccess: false,
        });
        return false;
      }
    } else {
      let original_content = [...this.props.ecosystem_details.ecosystemContent];
      let original_categories = [
        ...this.props.ecosystem_details.ecosystemCategories,
      ];
      let category_selection = false;
      let category_id = 0;
      let category_temp = false;
      let add_categories = 0;
      let category_status = [];
      original_categories = original_categories.map((s) => {
        if (s.name === category_name) {
          s.name = data[0];
          category_id = s.uid;
          category_selection = s.is_selected;
          data.shift();
          for (let i = 0; i < s.subcategories.length; i++) {
            s.subcategories[i].name = data[i];
          }
          category_temp = data.length > s.subcategories.length;
          if (data.length > s.subcategories.length) {
            for (let i = s.subcategories.length; i < data.length; i++) {
              this.addSubCategory(category_name, data[i]);
            }
          }
          s.is_selected = category_selection;
        }
        category_status.push(s.is_selected);
        return s;
      });
      original_categories = original_categories.map((s, index) => {
        s.is_selected = category_status[index];
        return s;
      });
      this.props.ecosystemCategories(original_categories);
      this.detectChanges(original_content, original_categories);
      return true;
    }
  };

  addSubCategory = (cat_name, sub_cat) => {
    let original_content = [...this.props.ecosystem_details.ecosystemContent];
    let original_categories = [
      ...this.props.ecosystem_details.ecosystemCategories,
    ];
    let index = original_categories.findIndex((i) => i.name === cat_name);
    let published_categories =
      this.state.publishedContent && this.state.publishedContent.categories
        ? this.state.publishedContent.categories
        : [];
    let rank1 = 0;
    let rank2 = 0;
    if (
      original_categories.length > 0 &&
      original_categories[index] &&
      original_categories[index].id
    ) {
      let ind = published_categories.findIndex(
        (i) => i.id === original_categories[index].id
      );
      if (ind >= 0) {
        if (
          published_categories[ind] &&
          published_categories[ind].subcategories
        )
          rank1 = Math.max(
            ...published_categories[ind].subcategories.map((o) => o.rank)
          );
      }
    }
    if (
      original_categories.length > 0 &&
      original_categories[index] &&
      original_categories[index].subcategories &&
      original_categories[index].subcategories.length > 0
    ) {
      rank2 = Math.max(
        ...original_categories[index].subcategories.map((o) => o.rank)
      );
    }
    let rank = Math.max(rank1, rank2);
    let newSubCategory = {
      id: null,
      uid: Math.random(),
      name: sub_cat,
      is_selected: false,
      rank: rank + 1,
    };
    original_categories[index].subcategories.push(newSubCategory);
    this.props.ecosystemCategories(original_categories);
    this.unCheckOnNoContent(original_content);
    this.detectChanges(original_content, original_categories);
  };

  onCommentChangeHandler = (uid, comment) => {
    let original_content = [...this.props.ecosystem_details.ecosystemContent];
    let original_categories = [
      ...this.props.ecosystem_details.ecosystemCategories,
    ];
    let index = original_content.findIndex((row) => row.uid === uid);
    original_content[index].comment = comment;
    this.props.ecosystemContent(original_content);
    this.detectChanges(original_content, original_categories);
  };

  onTransactionCommentChangeHandler = (uid, comment) => {
    let original_content = {
      ...this.props.ecosystem_details.ecosystemTransactionDetails,
    };
    original_content.comment = comment;
    this.props.ecosystemTransactionDetails(original_content);
  };

  toggleCommentsHandler = (uid) => {
    let original_content = [...this.props.ecosystem_details.ecosystemContent];
    let index = original_content.findIndex((row) => row.uid === uid);
    original_content[index].openCommentsFlag =
      !original_content[index].openCommentsFlag;
    this.props.ecosystemContent(original_content);
  };

  toggleCommentsHandlerTransaction = (uid) => {
    let original_content = JSON.parse(
      JSON.stringify(this.props.ecosystem_details.ecosystemTransactionDetails)
    );
    original_content.openCommentsFlag = !original_content.openCommentsFlag;
    this.props.ecosystemTransactionDetails(original_content);
  };

  breadcrumbs = () => {
    let nextStates =
      this.props.ecosystem_details &&
      this.props.ecosystem_details.ecosystemTransactionDetails &&
      this.props.ecosystem_details.ecosystemTransactionDetails.next_states
        ? this.props.ecosystem_details.ecosystemTransactionDetails.next_states
        : [];
    let ecosystemDetails =
      this.props.ecosystem_details &&
      this.props.ecosystem_details.ecosystemTransactionDetails
        ? this.props.ecosystem_details.ecosystemTransactionDetails
        : {};
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.ECOSYSTEM_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.ECOSYSTEM.LIST.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.ECOSYSTEM.LIST.ROUTE, {}),
        is_active: false,
      },
      {
        title: nextStates.includes(
          constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_DRAFT.SLUG
        )
          ? constants.APPLICATION_ROUTE.MANAGE_CONTENT_ECOSYSTEM_MY_DRAFTS.NAME
          : constants.APPLICATION_ROUTE
              .MANAGE_CONTENT_ECOSYSTEM_TRACK_SUBMISSIONS.NAME,
        link: formatRoute(
          nextStates.includes(
            constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_DRAFT.SLUG
          )
            ? constants.APPLICATION_ROUTE.MANAGE_CONTENT_ECOSYSTEM_MY_DRAFTS
                .ROUTE
            : constants.APPLICATION_ROUTE
                .MANAGE_CONTENT_ECOSYSTEM_TRACK_SUBMISSIONS.ROUTE,
          {}
        ),
        is_active: false,
      },
      {
        title: `${
          ecosystemDetails &&
          ecosystemDetails.eco_system &&
          ecosystemDetails.eco_system.id
            ? ecosystemDetails.eco_system.name
            : ""
        }`,
        link: null,
        is_active: true,
      },
    ];
    DocumentTitle(
      `${
        ecosystemDetails &&
        ecosystemDetails.eco_system &&
        ecosystemDetails.eco_system.id
          ? ecosystemDetails.eco_system.name
          : ""
      }`
    );
    return breadcrumbs;
  };

  // on mouse scroll the left sidebar element will change color
  mouseScroll = (id) => {
    this.setState({ selectedSlide: id });
  };
  // method to scroll the element
  handleClickScroll = (name, id) => {
    let elementID = `${name}-${id}`;
    let element = document.getElementById(elementID);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    this.setState({ selectedSlide: id });
  };

  render() {
    let tokenDetails = decodeJWT(localStorage.getItem("token"));
    let is_others_content_edit_allowed =
      tokenDetails &&
      tokenDetails.role &&
      tokenDetails.role.is_others_content_edit_allowed;
    let nextStates =
      this.props.ecosystem_details &&
      this.props.ecosystem_details.ecosystemTransactionDetails &&
      this.props.ecosystem_details.ecosystemTransactionDetails.next_states
        ? this.props.ecosystem_details.ecosystemTransactionDetails.next_states
        : [];
    const isEditEnabled =
      ((nextStates.includes(
        constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_DRAFT.SLUG
      ) &&
        permissionCheck(
          constants.MANAGE_CONTENT_PERMISSIONS.KEY,
          constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
          constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_DRAFT.SLUG
        )) ||
        (nextStates.includes(
          constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_SUBMIT.SLUG
        ) &&
          permissionCheck(
            constants.MANAGE_CONTENT_PERMISSIONS.KEY,
            constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
            constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_SUBMIT.SLUG
          )) ||
        (nextStates.includes(
          constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_RE_SUBMIT.SLUG
        ) &&
          permissionCheck(
            constants.MANAGE_CONTENT_PERMISSIONS.KEY,
            constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
            constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_RE_SUBMIT.SLUG
          )) ||
        is_others_content_edit_allowed === 1) &&
      nextStates.length > 0;
    let showComments =
      (nextStates.includes(
        constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_RE_SUBMIT.SLUG
      ) &&
        permissionCheck(
          constants.MANAGE_CONTENT_PERMISSIONS.KEY,
          constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
          constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_RE_SUBMIT.SLUG
        )) ||
      (nextStates.includes(
        constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_REJECT.SLUG
      ) &&
        permissionCheck(
          constants.MANAGE_CONTENT_PERMISSIONS.KEY,
          constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
          constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_REJECT.SLUG
        )) ||
      (nextStates.includes(
        constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_APPROVE.SLUG
      ) &&
        permissionCheck(
          constants.MANAGE_CONTENT_PERMISSIONS.KEY,
          constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
          constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_APPROVE.SLUG
        )) ||
      (nextStates.includes(
        constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_PUBLISH.SLUG
      ) &&
        permissionCheck(
          constants.MANAGE_CONTENT_PERMISSIONS.KEY,
          constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
          constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_PUBLISH.SLUG
        ));
    return (
      <div
        className={`${
          this.state.innerSidebarOpen
            ? "opened-innersidebar"
            : "closed-innersidebar"
        } main_content fixed-title-main-content`}
      >
        <div className="m-3 mb-0 bg-white px-3 br_8 main_content_height">
          <div className="d-flex align-items-center project-transaction-details-page-title-sec fixed-title-section">
            <div className="breadcrumb-mb-0">
              <CustomBreadcrumb breadcrumbs={this.breadcrumbs()} />
            </div>
          </div>
          <div className="company_details project_details transaction_details">
            <Prompt
              when={this.state.changesMade}
              message={(location) => `Changes you made may not be saved.`}
            />
            <EcosystemTransactionDetailsCategories
              addEmptyContent={this.addEmptyContent}
              handleAddRemoveTemplateSubCategory={
                this.handleAddRemoveTemplateSubCategory
              }
              handleAddRemoveTemplateCategory={
                this.handleAddRemoveTemplateCategory
              }
              toggleCheckbox={this.toggleCheckboxParent}
              isEditable={isEditEnabled}
              onNameChangeHandler={this.onNameChangeHandler}
              addCategory={this.addCategory}
              addSubCategory={this.addSubCategory}
              toggleInnerSidebar={this.toggleInnerSidebar}
              handleClickScroll={this.handleClickScroll}
              selectedSlide={this.state.selectedSlide}
              {...this.props}
            />
            <EcosystemTransactionDetailsContent
              switchSegmentHandler={this.switchSegment}
              addEmptyContent={this.addEmptyContent}
              removeContent={this.removeContent}
              dateOnChangeHandler={this.dateOnChangeHandler}
              onContentChangeHandler={this.onContentChangeHandler}
              onSectionTitleChangeHandler={this.onSectionTitleChangeHandler}
              saveContent={this.saveContent}
              changesMade={this.state.changesMade}
              undoRemoveContent={this.undoRemoveContent}
              resetContent={this.resetContent}
              isEditable={isEditEnabled}
              onTemplateChangeHandler={this.onTemplateChangeHandler}
              apiError={this.state.apiError}
              showComments={showComments}
              onCommentChangeHandler={this.onCommentChangeHandler}
              toggleCommentsHandlerTransaction={
                this.toggleCommentsHandlerTransaction
              }
              onTransactionCommentChangeHandler={
                this.onTransactionCommentChangeHandler
              }
              toggleCommentsHandler={this.toggleCommentsHandler}
              writeComments={showComments}
              selectedSlide={this.state.selectedSlide}
              mouseScroll={this.mouseScroll}
              setImage={this.state.quoteImageUrl}
              setQuote={this.state.labelquoteContent}
              setQuoteImageName={this.state.quoteImageName}
              quoteId={this.state.quoteId}
              onEditClicked={this.onEditClicked}
              currentState={this.state.currentState}
              {...this.props}
            />
            <QuoteImageDialog
              isOpen={this.state.quoteImageDailogModal}
              header={
                this.state.quoteId
                  ? constants.QUOTE_IMAGE.MODAL_HEADER.EDITING_QUOTE
                  : constants.QUOTE_IMAGE.MODAL_HEADER.ADDING_QUOTE
              }
              config={this.state.dailogModalConfig}
              accept={
                this.state.quoteId
                  ? this.quoteImageDailogModalAccept
                  : this.quoteImageDailogModalStoreAccept
              }
              decline={this.quoteImageDailogModalDecline}
              state={this.state}
              handleTitleChange={this.handleQuoteContent}
              onChangeHandlerImage={this.handleQuoteImage}
              saveButtonName={constants.QUOTE_IMAGE.UPLOAD_BUTTON.UPDATE}
              handleQuoteImageRemove={(e) => {
                this.setState({
                  image_delete: e,
                });
              }}
            />
            <DailogNew
              isOpen={this.state.dailogModal}
              accept={this.dailogModalAccept}
              decline={this.dailogModalDecline}
              header={this.state.dailogModalHeader}
              content={this.state.dailogModalContent}
              config={this.state.dailogModalConfig}
              modalStyleType={this.state.dailogModalStyleType}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ecosystem_details: state.ecosystem_details,
    home: state.home,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ecosystemCategories: (payload) => {
      dispatch(ecosystemCategories(payload));
    },
    ecosystemDetails: (payload) => {
      dispatch(ecosystemDetails(payload));
    },
    ecosystemContent: (payload) => {
      dispatch(ecosystemContent(payload));
    },
    ecosystemTemplates: (payload) => {
      dispatch(ecosystemTemplates(payload));
    },
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
    ecosystemTransactionDetails: (payload) => {
      dispatch(ecosystemTransactionDetails(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EcosystemTransactionDetails);
