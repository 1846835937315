import { project_details as DETAILS } from "../Reducers/actionConstants";

const projectCategories = (payload) => {
  return {
    type: DETAILS.PROJECT_CATEGORIES,
    payload,
  };
};

const projectSegments = (payload) => {
  return {
    type: DETAILS.PROJECT_SEGMENTS,
    payload,
  };
};

const projectDetails = (payload) => {
  return {
    type: DETAILS.PROJECT_DETAILS,
    payload,
  };
};

const categories = (payload) => {
  return {
    type: DETAILS.CATEGORIES,
    payload,
  };
};

const projectContent = (payload) => {
  return {
    type: DETAILS.PROJECT_CONTENT,
    payload,
  };
};

const projectTemplates = (payload) => {
  return {
    type: DETAILS.PROJECT_TEMPLATES,
    payload,
  };
};

const projectTransactionDetails = (payload) => {
  return {
    type: DETAILS.PROJECT_TRANSACTION_DETAILS,
    payload,
  };
};

export {
  projectCategories,
  projectSegments,
  projectDetails,
  categories,
  projectContent,
  projectTemplates,
  projectTransactionDetails,
};
