import { market_landscape_details as DETAILS } from "../Reducers/actionConstants";

const marketLandscapeCategories = (payload) => {
  return {
    type: DETAILS.MARKET_LANDSCAPE_CATEGORIES,
    payload,
  };
};

const marketLandscapeSegments = (payload) => {
  return {
    type: DETAILS.MARKET_LANDSCAPE_SEGMENTS,
    payload,
  };
};

const marketLandscapeDetails = (payload) => {
  return {
    type: DETAILS.MARKET_LANDSCAPE_DETAILS,
    payload,
  };
};

const categories = (payload) => {
  return {
    type: DETAILS.CATEGORIES,
    payload,
  };
};

const marketLandscapeContent = (payload) => {
  return {
    type: DETAILS.MARKET_LANDSCAPE_CONTENT,
    payload,
  };
};

const marketLandscapeTemplates = (payload) => {
  return {
    type: DETAILS.MARKET_LANDSCAPE_TEMPLATES,
    payload,
  };
};

const marketLandscapeTransactionDetails = (payload) => {
  return {
    type: DETAILS.MARKET_LANDSCAPE_TRANSACTION_DETAILS,
    payload,
  };
};

export {
  marketLandscapeCategories,
  marketLandscapeSegments,
  marketLandscapeDetails,
  categories,
  marketLandscapeContent,
  marketLandscapeTemplates,
  marketLandscapeTransactionDetails,
};
