import { company_details as DETAILS } from "../Reducers/actionConstants";

const companyCategories = (payload) => {
  return {
    type: DETAILS.COMPANY_CATEGORIES,
    payload,
  };
};

const companySegments = (payload) => {
  return {
    type: DETAILS.COMPANY_SEGMENTS,
    payload,
  };
};

const companyDetails = (payload) => {
  return {
    type: DETAILS.COMPANY_DETAILS,
    payload,
  };
};

const categories = (payload) => {
  return {
    type: DETAILS.CATEGORIES,
    payload,
  };
};

const companyContent = (payload) => {
  return {
    type: DETAILS.COMPANY_CONTENT,
    payload,
  };
};

const companies = (payload) => {
  return {
    type: DETAILS.COMPANIES,
    payload,
  };
};

const templates = (payload) => {
  return {
    type: DETAILS.TEMPLATES,
    payload,
  };
};

const transactionDetails = (payload) => {
  return {
    type: DETAILS.TRANSACTION_DETAILS,
    payload,
  };
};

export {
  companyCategories,
  companySegments,
  companyDetails,
  categories,
  companyContent,
  companies,
  templates,
  transactionDetails,
};
