import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "./StatusModal.css";

function ActivationModel({
  activeModal,
  setActiveModal,
  handleCategoryStatus,
  activateConfirmMessage,
}) {
  const toggle = () => setActiveModal(false);
  return (
    <Modal
      isOpen={activeModal}
      toggle={toggle}
      className={
        "modal-sm modal-w-header custom-modal modal-without-header primary-modal"
      }
    >
      <ModalHeader toggle={toggle}>
        <div className="modal-header">
          <h3 className="text-darkblue2 font-weight-bold">Activation</h3>
        </div>
      </ModalHeader>
      <ModalBody style={{ paddingTop: "20px !important" }}>
        <div className="activate-body">
          <h2 className="font-weight-semibold mb-0">
            {activateConfirmMessage}
          </h2>
        </div>
      </ModalBody>
      <ModalFooter
        className="status-model-footer"
        style={{ justifyContent: "center !important" }}
      >
        <Button className={"modal-right-btn modal-btn"} onClick={toggle}>
          Cancel
        </Button>{" "}
        <Button
          className={"modal-btn btn-success"}
          type="submit"
          onClick={handleCategoryStatus}
        >
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ActivationModel;
