import React, { Component } from "react";
import permissionCheck from "../../lib/permissionCheck";
import constants from "../../Utils/constants";
class AclAction extends Component {
  restrict = (children) => {
    return React.Children.map(children, (child) =>
      this.filterByType(children.props.tbrentitytype, child)
    );
  };
  filterByType = (componentName, component) => {
    switch (componentName) {
      case constants.ACL.ENTITY_TYPE.NAVIGATION:
      case constants.ACL.ENTITY_TYPE.DIV:
      case constants.ACL.ENTITY_TYPE.BUTTON: {
        let access = this.checkAccess();
        if (!access[this.props.action]) {
          return <React.Fragment />;
        }
        return component;
      }
      default: {
        return component;
      }
    }
  };
  getMergedAccess = () => {
    let access = {
      [this.props.action]: false,
    };
    if (!this.props.entity || !this.props.action) {
      return access;
    }
    access[this.props.action] = permissionCheck(
      this.props.type,
      this.props.entity,
      this.props.action
    );
    return access;
  };
  checkAccess = () => {
    let access = this.getMergedAccess();
    return access;
  };
  render() {
    const { children } = this.props;
    const propped_children = this.restrict(children);
    return <React.Fragment>{propped_children}</React.Fragment>;
  }
}
export default AclAction;
