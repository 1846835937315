import { Axios } from "../lib/axios";

export const PublishedCalendarServices = {
  getCalendarEvent,
  createCalendarEvent,
  updateCalendarEvent,
  deleteCalendarEvent,
  uploadBulkEventFile,
};

function getCalendarEvent() {
  return Axios.get("/calenderEvents");
}

function createCalendarEvent(body) {
  return Axios.post("/addCalenderEvent", body);
}

function updateCalendarEvent(id, body) {
  return Axios.put(`/calenderEvent/${id}`, body);
}
function deleteCalendarEvent(id) {
  return Axios.delete(`/calenderEvent/${id}`);
}

function uploadBulkEventFile(body) {
  return Axios.post("addBulkCalendarEvent", body);
}
