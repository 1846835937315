import { Axios } from "../lib/axios";
export const RuleServices = {
  addRule,
  getRules,
  removeRule,
  getRuleById,
  updateRule,
};
function addRule(body) {
  return Axios.post(`metricrule`, body);
}
function getRules(url) {
  return Axios.get(url);
}
function getRuleById(id) {
  return Axios.get(`metricrule/${id}`);
}
function updateRule(id, body) {
  return Axios.patch(`metricrule/${id}`, body);
}
function removeRule(id) {
  return Axios.delete(`metricrule/${id}`);
}
