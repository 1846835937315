import React, { useState, useRef } from "react";

const CustomImageUploader = ({
  value,
  onChangeHandlerImage,
  quoteImageName,
  handleQuoteImageRemove,
}) => {
  const [image, setImage] = useState(value ?? "");
  const [imageName, setImageName] = useState(quoteImageName ?? "");
  const [uploadFlag, setUploadFlag] = useState(value && value.length > 0);

  const upload = useRef(null);

  const uploadImage = (image) => {
    try {
      let reader = new FileReader();

      reader.onloadend = () => {
        if (reader.readyState === FileReader.DONE) {
          const base64Url = reader.result;
          const imageName = image[0].name;
          setImage(base64Url);
          setImageName(imageName);
          setUploadFlag(true);
          onChangeHandlerImage(base64Url, imageName);
        }
      };

      reader.readAsDataURL(image[0]);
    } catch (e) {
      console.error("Error reading file:", e);
    }
  };

  const removeImage = () => {
    handleQuoteImageRemove(true);
    setImage("");
    setImageName("");
    setUploadFlag(false);
    onChangeHandlerImage("", "");
  };

  return (
    <>
      <div className="row">
        {image && (
          <div className="col-12 col-md-9">
            <div className="d-flex align-items-center">
              <img
                src={image}
                alt="Image"
                style={{ maxWidth: "100%", width: "80px", height: "70px" }}
              />
              <span
                style={{
                  display: "inline-block",
                  maxHeight: "50px",
                  overflow: "auto",
                  wordBreak: "break-word",
                }}
                className="pr-2 ml-2"
              >
                {imageName}
              </span>
              <span
                className="material-icons-outlined text-grey icon-fs-16 cursor-pointer ml-2"
                onClick={removeImage}
              >
                delete
              </span>
            </div>
          </div>
        )}
        <div className={image ? "col-12 col-md-3" : "col-md-12"}>
          <div className="d-flex justify-content-center align-items-center flex-wrap">
            <div className="w-100">
              <input
                type="file"
                accept="image/jpeg, image/png"
                ref={upload}
                style={{ display: "none" }}
                onChange={() => uploadImage(upload.current.files)}
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
              <div
                className={
                  image
                    ? "upload-buttons d-flex justify-content-end align-items-center mt-3"
                    : "upload-buttons"
                }
              >
                <button
                  className="btn btn-primary d-inline-flex align-items-center icon-text-primary-btn"
                  type="button"
                  disabled={uploadFlag}
                  onClick={() => upload.current.click()}
                >
                  <span className="material-icons-round icon-fs-16 mr-1">
                    search
                  </span>
                  {"Browse"}
                </button>
              </div>
            </div>
            {!image && (
              <div className="w-100 text-center" style={{ marginTop: "5px" }}>
                <label
                  htmlFor="note"
                  style={{
                    fontWeight: 400,
                    fontStyle: "italic",
                    fontSize: "10px",
                    lineHeight: "14px",
                    color: "#93999F",
                  }}
                >
                  Note: Only PNG, JPG files are allowed
                </label>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default CustomImageUploader;
