import React, { useState } from "react";
import { UserServices } from "../Services/User";
import constants from "../Utils/constants";
import Toast from "../Utils/Toast";
import { msalInstance } from "../Auth/MSAL-Instance";
import DailogNew from "../Components/DailogNew";
import { ButtonToggle, FormGroup } from "reactstrap";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { loginUserSuccess } from "../Store/Actions/User";
import { formatRoute } from "react-router-named-routes";

export default (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const defaultDailogModalState = {
    isOpen: false,
    dailogModalStyleType: "",
    dailogModalContent: "",
    dailogHeader: "",
  };

  const [dailogModalState, setDailogModalState] = useState(
    defaultDailogModalState
  );

  const handleLogin = () => {
    localStorage.setItem("SSO", true);
    UserServices.getUserLogin()
      .then((data) => {
        localStorage.setItem("token", data.data.data.token.access_token);
        localStorage.setItem(
          "refresh_token",
          data.data.data.token.refresh_token
        );
        dispatch(loginUserSuccess(data.data.data));
        history.push(
          formatRoute(constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE, {})
        );
      })
      .catch((error) => {
        localStorage.removeItem("SSO");
        Toast(
          error && error.message
            ? error.message
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  const registerSSO = async () => {
    if (!props.is_sso_registered) {
      msalInstance
        .handleLogin()
        .then((res) => {
          if (props.is_from_password_creation_page) {
            handleLogin();
          } else {
            UserServices.registerSSO({ idToken: res.idToken })
              .then((res) => {
                let message =
                  res.data && res.data && res.data.message
                    ? res.data.message
                    : "SSO successfully registered";
                setDailogModalState({
                  isOpen: true,
                  dailogModalStyleType: "success-modal",
                  dailogModalContent: (
                    <div className="activate-body">
                      <h2 className="font-weight-semibold mb-0">{message}</h2>
                    </div>
                  ),
                  dailogHeader: (
                    <h3 className="text-darkblue2 font-weight-bold">Message</h3>
                  ),
                });
              })
              .catch((error) => {
                setDailogModalState({
                  isOpen: true,
                  dailogModalStyleType: "danger-modal",
                  dailogModalContent: (
                    <div className="remove-body">
                      <h2 className="font-weight-semibold mb-0">
                        {error && error.data && error.data.error
                          ? error.data.error
                          : constants.ERROR.SOMETHING_WENT_WRONG}
                      </h2>
                    </div>
                  ),
                  dailogHeader: (
                    <h3 className="text-darkblue2 font-weight-bold">Message</h3>
                  ),
                });
              });
          }
        })
        .catch((e) => {
          setDailogModalState({
            isOpen: true,
            dailogModalStyleType: "danger-modal",
            dailogModalContent:
              e && e.message ? e.message : constants.ERROR.SOMETHING_WENT_WRONG,
          });
        });
    }
  };

  const closeDailogModal = () => {
    setDailogModalState(defaultDailogModalState);
  };

  return (
    <>
      {props.entity === constants.HTML.TAGS.BUTTON ? (
        <FormGroup className="m-0">
          <ButtonToggle color="primary" onClick={registerSSO} className="mb-3">
            Continue with SSO
          </ButtonToggle>
        </FormGroup>
      ) : (
        <li className={`${props.is_sso_registered ? "disable" : ""}`}>
          <span
            onClick={registerSSO}
            className={`d-inline-flex align-items-center ${
              props.is_sso_registered ? "disable" : ""
            }`}
          >
            <i className="material-icons-outlined rotate-45 icon-fs-17">
              vpn_key
            </i>
            {props.is_sso_registered ? "SSO Registered" : "Register SSO"}
          </span>
        </li>
      )}
      <DailogNew
        isOpen={dailogModalState.isOpen}
        decline={closeDailogModal}
        content={dailogModalState.dailogModalContent}
        modalStyleType={dailogModalState.dailogModalStyleType}
        header={dailogModalState.dailogHeader}
      />
    </>
  );
};
