import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import AM_Charts from "./AM_Charts";

export default class ColumnChart extends AM_Charts {
  constructor(props) {
    super(props);
    this.series = {};
    this.legend = null;
  }

  createSeries = (seriesObj) => {
    const { name, isStacked, field_name, isFill } = seriesObj;

    const root = this.root;
    const chart = this.chart;
    const chartData = this.data;
    const xAxis = this.xAxis;
    const yAxis = this.yAxis;

    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        stacked: isStacked,
        valueYField: field_name,
        valueXField: "date",
      })
    );

    series.columns.template.setAll({
      tooltipText: "{name}, {valueX.formatDate()}:{valueY}",
      width: am5.percent(90),
      tooltipY: 0,
    });

    if (isFill) {
      series.fills.template.setAll({
        fillOpacity: 0.5,
        visible: true,
      });
    }

    if (chartData.length == 1) {
      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 5,
            fill: series.get("fill"),
          }),
        });
      });
    }

    series.data.setAll(chartData);
    series.appear();
    // this.series = series;
    if (this.legend) {
      this.legend.data.push(series);
    }
  };

  // addLegend = (legendDiv) => {
  //     const root = this.root;
  //     const chart = this.chart;
  //     let legendRoot = am5.Root.new(legendDiv);
  //     legendRoot.setThemes([
  //         am5themes_Animated.new(root)
  //     ]);
  //     let legend = chart.children.push(
  //         am5.Legend.new(root, {
  //             centerX: am5.percent(50),
  //             x: am5.percent(50),
  // layout: am5.GridLayout.new(root, {
  //     maxColumns: 3,
  //     fixedWidthGrid: true
  // })
  //         })
  //     );
  //     this.legend = legend;
  // }
}
