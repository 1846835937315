import { combineReducers } from "redux";
import * as DETAILS_REF from "./reducerReference/clientReference";

const clients = DETAILS_REF.clients;
const clientRoles = DETAILS_REF.clientRoles;
const users = DETAILS_REF.users;

const clientReducer = combineReducers({
  clients,
  clientRoles,
  users,
});

export default clientReducer;
