import { ecosystem_details as DETAILS } from "../Reducers/actionConstants";

const ecosystemCategories = (payload) => {
  return {
    type: DETAILS.ECOSYSTEM_CATEGORIES,
    payload,
  };
};

const ecosystemSegments = (payload) => {
  return {
    type: DETAILS.ECOSYSTEM_SEGMENTS,
    payload,
  };
};

const ecosystemDetails = (payload) => {
  return {
    type: DETAILS.ECOSYSTEM_DETAILS,
    payload,
  };
};

const categories = (payload) => {
  return {
    type: DETAILS.CATEGORIES,
    payload,
  };
};

const ecosystemContent = (payload) => {
  return {
    type: DETAILS.ECOSYSTEM_CONTENT,
    payload,
  };
};

const ecosystemTemplates = (payload) => {
  return {
    type: DETAILS.ECOSYSTEM_TEMPLATES,
    payload,
  };
};

const ecosystemTransactionDetails = (payload) => {
  return {
    type: DETAILS.ECOSYSTEM_TRANSACTION_DETAILS,
    payload,
  };
};

export {
  ecosystemCategories,
  ecosystemSegments,
  ecosystemDetails,
  categories,
  ecosystemContent,
  ecosystemTemplates,
  ecosystemTransactionDetails,
};
