import React from "react";
import "react-collapser/dist/default-styles.css";

function HelpCategories(props) {
  return (
    <aside className="inner-sidebar">
      <div className="sidebar_nav2 d-flex flex-column h-100">
        <div className="d-flex expand-collapse-icon-wrapper">
          <span
            className="material-icons-outlined expand-collapse-icon"
            onClick={props.toggleInnerSidebar}
          >
            keyboard_double_arrow_left
          </span>
        </div>
        <div className="opened-innersidebar-content">
          <div className="">
            <div className="help-categories-list">
              {props.helpCategory.length > 0 &&
                props.helpCategory.map((entry, index) => (
                  <div
                    key={index}
                    className={`d-flex align-items-start help-item ${
                      entry.item.includes(props.selectedCategory.item)
                        ? "active"
                        : ""
                    }`}
                  >
                    <span
                      className={`flex-grow-1 ellipsis d-flex mr-1 help-item-text`}
                      onClick={(e) => props.categoryClicked(e, entry.item)}
                    >
                      {entry.item}
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="closed-innersidebar-content d-flex flex-grow-1">
          <div className="content text-center font-weight-semibold">
            HELP TOPICS
          </div>
        </div>
      </div>
    </aside>
  );
}
export default HelpCategories;
