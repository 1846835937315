import React, { Component } from "react";
import { SpecialReportServices } from "../Services/SpecialReport";
import CustomBreadcrumb from "../Components/CustomBreadcrumb";
import { UserServices } from "../Services/User";
import { connect } from "react-redux";
import {
  specialReportCategories,
  specialReportDetails,
  specialReportContent,
  specialReportTemplates,
} from "../Store/Actions/SpecialReportDetails";
import constants from "../Utils/constants";
import { formatRoute } from "react-router-named-routes";
import _ from "lodash";
import DailogNew from "../Components/DailogNew";
import { Prompt } from "react-router-dom";
import permissionCheck from "../lib/permissionCheck";
import { activeMenuTab } from "../Store/Actions/User";
import { getTbriUsers } from "../Store/Actions/User";
import Toast from "../Utils/Toast";
import CustomCloneDeep from "../Utils/CustomCloneDeep";
import DocumentTitle from "../Utils/DocumentTitle";
import Authors from "../Components/Authors";
import DetailsContent from "../Containers/DetailsContent";
import DetailsCategories from "../Containers/DetailsCategories";
import Copy from "../Utils/Copy";
import { QuoteImageServices } from "../Services/QuoteImage";
import QuoteImageDialog from "../Components/QuoteImageDialog";

class SpecialReportDetail extends Component {
  state = {
    masterContent: {},
    masterCategories: {},
    dailogModal: false,
    dailogModalContent: "",
    dailogModalHeader: "",
    dailogModalConfig: {},
    dailogModalStyleType: "",
    changesMade: false,
    transactionId: null,
    apiError: null,
    innerSidebarOpen: true,
    quoteImageDailogModal: false,
    mapping_id: null,
    mapping_type: null,
    quoteContent: null,
    quoteImageUrl: null,
    quoteImageName: null,
  };

  toggleInnerSidebar = () => {
    this.setState({ innerSidebarOpen: !this.state.innerSidebarOpen });
  };

  detectChanges = (specialReportContent, specialReportCategories) => {
    let c = 0,
      e = 0,
      d = 0; //cntents
    let cC = 0,
      eC = 0,
      dC = 0; //categories
    let cSC = 0,
      eSC = 0,
      dSC = 0; //sub categories
    specialReportContent.forEach((ea) => {
      if (ea.id && ea.id !== null) {
        if (ea.is_deleted === true) d = d + 1;
        else {
          if (this.findUpdates(ea.uid)) e = e + 1;
        }
      } else if (ea.is_deleted !== true) c = c + 1;
    });
    specialReportCategories.forEach((pc) => {
      if (pc.id && pc.id !== null) {
        if (this.findUpdatesCategory(pc.id)) {
          eC = eC + 1;
        }
      } else {
        if (pc.name !== null && pc.name !== "") cC = cC + 1;
      }
      pc.subcategories.forEach((psc) => {
        if (psc.id && psc.id !== null) {
          if (this.findUpdatesSubCategory(pc.id, psc.id)) {
            eSC = eSC + 1;
          }
        } else {
          if (psc.name !== null && psc.name !== "") cSC = cSC + 1;
        }
      });
    });
    window.removeEventListener("beforeunload", this.onUnload);
    if (
      (c > 0 ||
        d > 0 ||
        e > 0 ||
        cC > 0 ||
        dC > 0 ||
        eC > 0 ||
        cSC > 0 ||
        dSC > 0 ||
        eSC > 0) &&
      specialReportContent.length > 0
    )
      window.addEventListener("beforeunload", this.onUnload);
    this.setState({
      changesMade:
        (c > 0 ||
          d > 0 ||
          e > 0 ||
          cC > 0 ||
          dC > 0 ||
          eC > 0 ||
          cSC > 0 ||
          dSC > 0 ||
          eSC > 0) &&
        specialReportContent.length > 0
          ? true
          : false,
    });
  };

  findUpdatesCategory = (categoryId) => {
    let newCategory =
      this.props.special_report_details.specialReportCategories.filter(
        (comp) => comp.id === categoryId
      )[0];
    let oldCategory = this.state.masterCategories.filter(
      (comp) => comp.id === categoryId
    )[0];
    if (
      _.isEqual(newCategory.name, oldCategory.name) === true &&
      _.isEqual(newCategory.rank, oldCategory.rank) === true
    )
      return false;
    return true;
  };

  findUpdatesSubCategory = (categoryId, subcategoryId) => {
    let newCategory =
      this.props.special_report_details.specialReportCategories.filter(
        (comp) => comp.id === categoryId
      )[0];
    let oldCategory = this.state.masterCategories.filter(
      (comp) => comp.id === categoryId
    )[0];
    let newSubCategory = newCategory.subcategories.filter(
      (comp) => comp.id === subcategoryId
    )[0];
    let oldSubCategory = oldCategory.subcategories.filter(
      (comp) => comp.id === subcategoryId
    )[0];
    if (
      _.isEqual(newSubCategory.name, oldSubCategory.name) === true &&
      _.isEqual(newSubCategory.rank, oldSubCategory.rank) === true
    )
      return false;
    return true;
  };

  onUnload = (ev) => {
    ev.preventDefault();
    ev.returnValue = "";
  };

  componentDidMount() {
    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        this.setState({ dailogModal: true });
      }
    });
    let specialReportId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    SpecialReportServices.getSpecialReportTemplates()
      .then((res) => {
        let response = res && res.data;
        this.props.specialReportTemplates(response);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
    this.props.activeMenuTab(
      constants.APPLICATION_ROUTE.SPECIAL_REPORT.LIST.ROUTE
    );
    this.initContent(specialReportId);
    this.getspecialReportDetails(specialReportId);
    this.getTbriUsers();
  }

  initContent = (specialReportId) => {
    let contents = [];
    SpecialReportServices.getSpecialReportContent(specialReportId)
      .then((res) => {
        let response = res && res.data && res.data.data;
        if (Object.keys(response).length > 0) {
          let responseMeta = response.meta ? JSON.parse(response.meta) : null;
          let responseCategories = response.categories
            .map((res) => {
              let category = null;
              if (responseMeta) {
                category = responseMeta.find((k) => k.categoryId === res.id);
              }
              res.is_selected = true;
              res.is_deleted = false;
              res.uid = Math.random();
              if (res.subcategories) {
                res.subcategories = res.subcategories
                  .map((res_sub) => {
                    let subCategoryData = null;
                    if (category) {
                      subCategoryData = category.subCategories.find(
                        (k) => k.subCategoryId === res_sub.id
                      );
                    }
                    res_sub.uid = Math.random();
                    res_sub.is_selected = true;
                    res_sub.is_deleted = false;
                    res_sub.contents = res_sub.contents.map((cn) => {
                      cn.uid = Math.random();
                      cn.subcategory_id = res_sub.uid;
                      cn.is_deleted = cn.is_deleted
                        ? cn.is_deleted === 1
                        : false;
                      cn.comment = null;
                      cn.openCommentsFlag = false;
                      return cn;
                    });
                    if (res_sub.contents) {
                      contents.push(...res_sub.contents);
                    }
                    if (category && subCategoryData) {
                      return { ...res_sub, ...subCategoryData };
                    } else {
                      return { ...res_sub };
                    }
                  })
                  .sort((a, b) => a.subCategoryIndex - b.subCategoryIndex);
              }
              if (category) {
                delete category.subCategories;
                return { ...res, ...category };
              } else {
                return { ...res };
              }
            })
            .sort((a, b) => a.categoryIndex - b.categoryIndex);
          this.unCheckOnNoContent(
            this.props.special_report_details.specialReportContent
          );
          this.setState(
            {
              masterCategories: JSON.parse(JSON.stringify(responseCategories)),
              masterContent: JSON.parse(JSON.stringify(contents)),
              changesMade: false,
              transactionId: response.id ? response.id : null,
              apiError: null,
              mapping_id: response.mapping_id,
              mapping_type: response.type,
            },
            () => {
              window.removeEventListener("beforeunload", this.onUnload);
              this.props.specialReportCategories(responseCategories);
              this.props.specialReportContent(contents);
            }
          );
        } else {
          this.unCheckOnNoContent(
            this.props.special_report_details.specialReportContent
          );
          this.setState(
            {
              masterCategories: JSON.parse(JSON.stringify([])),
              masterContent: JSON.parse(JSON.stringify([])),
              changesMade: false,
              transactionId: response.id ? response.id : null,
              apiError: null,
              mapping_id: response.mapping_id,
              mapping_type: response.type,
            },
            () => {
              window.removeEventListener("beforeunload", this.onUnload);
              this.props.specialReportCategories([]);
              this.props.specialReportContent([]);
            }
          );
        }
      })

      .catch((error) => {
        this.props.specialReportCategories([]);
        this.props.specialReportContent([]);
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  onDragEnd = (result) => {
    if (!result.destination) {
      Toast("Not allowed", "error");
      return;
    }
    let original_categories = CustomCloneDeep(
      this.props.special_report_details.specialReportCategories
    );
    if (result.destination.droppableId === "drop-category") {
      let sourceIdx = parseInt(result.source.index);
      let destIdx = parseInt(result.destination.index);
      let draggedLink = original_categories[sourceIdx];
      let newList = original_categories.slice();
      newList.splice(sourceIdx, 1);
      newList.splice(destIdx, 0, draggedLink);
      newList = newList.map((r, i) => {
        return { ...r, rank: i + 1 };
      });
      original_categories = newList;
    } else {
      original_categories.forEach((res) => {
        if (`drop-${res.uid}` === result.destination.droppableId) {
          if (res.subcategories) {
            let sourceIdx = parseInt(result.source.index);
            let destIdx = parseInt(result.destination.index);
            let draggedLink = res.subcategories[sourceIdx];
            let newList = res.subcategories.slice();
            newList.splice(sourceIdx, 1);
            newList.splice(destIdx, 0, draggedLink);
            newList = newList.map((r, i) => {
              return { ...r, rank: i + 1 };
            });
            res.subcategories = newList;
          }
        }
      });
    }
    this.props.specialReportCategories(original_categories);
    let original_content = [
      ...this.props.special_report_details.specialReportContent,
    ];
    setTimeout(() => {
      this.detectChanges(original_content, original_categories);
    }, 0);
  };

  getSpecialReportContent = (specialReportId, subcategories) => {
    SpecialReportServices.getSpecialReportContent(specialReportId)
      .then((res) => {
        let response = res && res.data && res.data.data;
        response.forEach((res) => {
          res.uid = Math.random();
          res.is_deleted = false;
          if (
            subcategories &&
            subcategories.find((s) => s.id === res.subcategory_id)
          ) {
            res.subcategory_id = subcategories.find(
              (s) => s.id === res.subcategory_id
            ).uid;
          }
        });
        this.props.specialReportContent(response);
        this.unCheckOnNoContent(
          this.props.special_report_details.specialReportContent
        );
        this.setState(
          {
            masterContent: JSON.parse(JSON.stringify(response)),
            changesMade: false,
          },
          () => {
            window.removeEventListener("beforeunload", this.onUnload);
          }
        );
      })
      .catch((error) => {
        this.props.specialReportContent([]);
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
    this.props.specialReportContent([]);
  };

  getspecialReportDetails = (specialReportId) => {
    SpecialReportServices.getSpecialReportDetails(specialReportId)
      .then((res) => {
        let response = res && res.data;
        this.props.specialReportDetails(response);
      })
      .catch((error) => {
        this.props.specialReportDetails({});
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  unCheckOnNoContent = (content) => {
    let original_categories = [
      ...this.props.special_report_details.specialReportCategories,
    ];
    original_categories.forEach((res) => {
      if (res.subcategories) {
        res.subcategories.forEach((res_sub) => {
          let content_found = content.filter(
            (row) =>
              row.subcategory_id === res_sub.uid && row.is_deleted === false
          ).length;
          if (content_found > 0) {
            res_sub.is_selected = true;
          } else {
            res_sub.is_selected = false;
          }
        });
        let total = res.subcategories.length;
        let total_selected = res.subcategories.filter(
          (row) => row.is_selected === true
        ).length;
        if (total_selected === total && total > 0) {
          res.is_selected = true;
        } else {
          res.is_selected = false;
        }
      }
    });
    this.props.specialReportCategories(original_categories);
  };

  toggleCheckboxParent = (e, type, category_id, sub_category_id) => {
    let checkbox_selection = e.target ? e.target.checked : e;
    if (checkbox_selection === true)
      this.toggleCheckbox(
        checkbox_selection,
        type,
        category_id,
        sub_category_id
      );
    else {
      this.confirmDailog({
        checkbox_selection,
        type,
        category_id,
        sub_category_id,
      });
    }
  };

  toggleCheckbox = (checkbox_selection, type, category_id, sub_category_id) => {
    let original_categories = [
      ...this.props.special_report_details.specialReportCategories,
    ];
    if (type === "category") {
      original_categories = original_categories.map((res) => {
        if (res.uid === category_id) {
          res.is_selected = checkbox_selection;
          res.subcategories.forEach((sub) => {
            sub.is_selected = checkbox_selection;
          });
          this.handleAddRemoveTemplateCategory(category_id, checkbox_selection);
        }
        return res;
      });
    } else if (type === "sub_category") {
      let selected_states = [];
      let subid = 0;
      for (let i = 0; i < original_categories.length; i++) {
        selected_states.push(original_categories[i].is_selected);
      }
      original_categories = original_categories.map((res, index) => {
        if (res.uid === category_id) {
          if (res.subcategories) {
            res.subcategories.map((res_sub) => {
              if (res_sub.uid === sub_category_id) {
                res_sub.is_selected = checkbox_selection;
                subid = res_sub.uid;
              }

              return res_sub;
            });
            this.handleAddRemoveTemplateSubCategory(subid, checkbox_selection);
            let total_selected = res.subcategories.filter(
              (row) => row.is_selected === true
            ).length;
            if (total_selected === 0) {
              res.is_selected = false;
            }
            if (total_selected > 0 && res.is_selected === false) {
              res.is_selected = true;
            }
          }
          selected_states[index] = res.is_selected;
        }
        return res;
      });
      original_categories = original_categories.map((res, index) => {
        res.is_selected = selected_states[index];
        return res;
      });
    }
    this.props.specialReportCategories(original_categories);
  };

  getEmptyContent = (sub_category_id) => {
    let emptyContent = {};
    emptyContent.id = null;
    emptyContent.uid = Math.random();
    emptyContent.data = "{}";
    emptyContent.subcategory_title = "";
    emptyContent.published_date = new Date();
    emptyContent.subcategory_id = sub_category_id;
    emptyContent.is_deleted = false;
    return emptyContent;
  };

  onTemplateChangeHandler = (content_id, template_id) => {
    let template_found =
      this.props.special_report_details.specialReportTemplates.filter(
        (row) => row.id === template_id
      );
    if (template_found && template_found.length > 0) {
      let original_content = [
        ...this.props.special_report_details.specialReportContent,
      ];
      let index = original_content.findIndex((row) => row.uid === content_id);
      original_content[index].template_id = template_found[0].id;
      original_content[index].template = template_found[0];
      let data = {};
      let extractParams =
        original_content[index].template.content.match(/[^{}]+(?=})/g);
      if (extractParams && extractParams.length > 0) {
        extractParams.forEach((param) => {
          data[param] = { type: "empty", value: "" };
        });
      }
      original_content[index].data = JSON.stringify(data);
      this.props.specialReportContent(original_content);
    }
  };

  addEmptyContent = (sub_category_id) => {
    let original_content = [
      ...this.props.special_report_details.specialReportContent,
    ];
    let original_categories = [
      ...this.props.special_report_details.specialReportCategories,
    ];
    let getEmptyContent = this.getEmptyContent(sub_category_id);
    original_content.push(getEmptyContent);
    this.props.specialReportContent(original_content);
    this.unCheckOnNoContent(original_content);
    this.detectChanges(original_content, original_categories);
  };

  handleAddRemoveTemplateCategory = (category_id, is_selected) => {
    let original_categories = [
      ...this.props.special_report_details.specialReportCategories,
    ];
    let checkbox_selection = is_selected;
    let original_content = [
      ...this.props.special_report_details.specialReportContent,
    ];
    original_categories.forEach((res) => {
      if (res.uid === category_id) {
        res.is_selected = checkbox_selection;
        if (res.subcategories) {
          res.subcategories.forEach((res_sub) => {
            if (checkbox_selection === true) {
              let content_found =
                this.props.special_report_details.specialReportContent.filter(
                  (row) =>
                    row.subcategory_id === res_sub.uid &&
                    row.is_deleted === false
                ).length;
              if (content_found > 0) {
              } else {
                original_content.push(this.getEmptyContent(res_sub.uid));
              }
            } else {
              original_content.forEach((item, index) => {
                if (item.subcategory_id === res_sub.uid) item.is_deleted = true;
                if (
                  item.is_deleted &&
                  item.id === null &&
                  item.subcategory_id === res_sub.uid
                )
                  delete original_content[index];
              });
            }
          });
        }
      }
    });
    let remainingContents = original_content.filter(
      (row) => row.id !== undefined
    );
    this.props.specialReportContent(remainingContents);
    this.detectChanges(remainingContents, original_categories);
  };

  handleAddRemoveTemplateSubCategory = (sub_category_id, is_selected) => {
    let content_found =
      this.props.special_report_details.specialReportContent.filter(
        (row) =>
          row.subcategory_id === sub_category_id && row.is_deleted === false
      ).length;
    if (is_selected === true) {
      if (content_found > 0) {
      } else {
        this.addEmptyContent(sub_category_id);
      }
    } else {
      this.removeSubCategoryContent(sub_category_id);
    }
  };

  removeSubCategoryContent = (sub_category_id) => {
    let original_content = [
      ...this.props.special_report_details.specialReportContent,
    ];
    let original_categories = [
      ...this.props.special_report_details.specialReportCategories,
    ];
    original_content.forEach((item, index) => {
      if (item.subcategory_id === sub_category_id) item.is_deleted = true;
      if (item.is_deleted && item.id === null) delete original_content[index];
    });
    let remainingContents = original_content.filter(
      (row) => row.id !== undefined
    );
    this.props.specialReportContent(remainingContents);
    this.unCheckOnNoContent(remainingContents);
    this.detectChanges(remainingContents, original_categories);
  };

  removeContent = (uid) => {
    let original_content = [
      ...this.props.special_report_details.specialReportContent,
    ];
    let original_categories = [
      ...this.props.special_report_details.specialReportCategories,
    ];
    original_content.forEach((item, index) => {
      if (item.uid === uid) item.is_deleted = true;
      if (item.is_deleted && item.id === null && item.uid === uid)
        delete original_content[index];
    });
    let remainingContents = original_content.filter(
      (row) => row.id !== undefined
    );
    this.props.specialReportContent(remainingContents);
    this.unCheckOnNoContent(remainingContents);
    this.detectChanges(remainingContents, original_categories);
  };

  undoRemoveContent = (uid) => {
    let original_content = [
      ...this.props.special_report_details.specialReportContent,
    ];
    let original_categories = [
      ...this.props.special_report_details.specialReportCategories,
    ];
    original_content.forEach((item) => {
      if (item.uid === uid) item.is_deleted = false;
    });
    this.props.specialReportContent(original_content);
    this.unCheckOnNoContent(original_content);
    this.detectChanges(original_content, original_categories);
  };

  dateOnChangeHandler = (key, val) => {
    let original_content = [
      ...this.props.special_report_details.specialReportContent,
    ];
    let original_categories = [
      ...this.props.special_report_details.specialReportCategories,
    ];
    let index = original_content.findIndex((row) => row.uid === key);
    original_content[index].published_date = val;
    this.props.specialReportContent(original_content);
    this.detectChanges(original_content, original_categories);
  };

  onContentChangeHandler = (uid, placeholder, value, valueType) => {
    let original_content = [
      ...this.props.special_report_details.specialReportContent,
    ];
    let original_categories = [
      ...this.props.special_report_details.specialReportCategories,
    ];
    let index = original_content.findIndex((row) => row.uid === uid);
    let jsonData = JSON.parse(original_content[index].data);
    jsonData[placeholder].type = valueType;
    if (valueType === "image" && value.startsWith("data:")) {
      let splitValue = value.split("|");
      jsonData[placeholder].value = splitValue[0] ? splitValue[0] : "";
      jsonData[placeholder].imageName = splitValue[1] ? splitValue[1] : "";
    } else {
      jsonData[placeholder].value = value;
    }
    let stringData = JSON.stringify(jsonData);
    original_content[index].data = stringData;
    this.props.specialReportContent(original_content);
    this.detectChanges(original_content, original_categories);
  };

  onSectionTitleChangeHandler = (uid, value) => {
    let original_content = [
      ...this.props.special_report_details.specialReportContent,
    ];
    let original_categories = [
      ...this.props.special_report_details.specialReportCategories,
    ];
    let index = original_content.findIndex((row) => row.uid === uid);
    original_content[index].subcategory_title = value;
    this.props.specialReportContent(original_content);
    this.detectChanges(original_content, original_categories);
  };

  saveContent = (type) => {
    let finalData = [];
    let original_categories = [
      ...this.props.special_report_details.specialReportCategories,
    ];
    let original_content = [
      ...this.props.special_report_details.specialReportContent,
    ];
    let isDuplicatCategory = [];
    let isDuplicatSubCategory = [];
    let emptyCategoryName = false;
    let emptySubCategoryName = false;
    let isMissingTemplate = false;
    original_categories.forEach((cat) => {
      let indData = {};
      indData.id = cat.id ? cat.id : null;
      indData.name = cat.name ? cat.name : null;
      indData.rank = cat.rank ? cat.rank : null;
      let category_contents_count = 0;
      if (
        original_categories.filter(
          (c) => c.name.toLowerCase() === cat.name.toLowerCase()
        ).length > 1
      ) {
        isDuplicatCategory.push(cat.name);
      }
      let subcats = [];
      cat.subcategories.forEach((subcat) => {
        let indSubcat = {};
        indSubcat.id = subcat.id ? subcat.id : null;
        indSubcat.name = subcat.name ? subcat.name : null;
        indSubcat.rank = subcat.rank ? subcat.rank : null;
        let sub_category_contents_count = 0;
        if (
          cat.subcategories.filter(
            (cs) => cs.name.toLowerCase() === subcat.name.toLowerCase()
          ).length > 1
        ) {
          isDuplicatSubCategory.push(subcat.name);
        }
        let contents = [];
        original_content
          .filter((content) => content.subcategory_id === subcat.uid)
          .forEach((con) => {
            if (con.template_id) {
              category_contents_count = category_contents_count + 1;
              sub_category_contents_count = sub_category_contents_count + 1;
              contents.push({
                template_id: con.template_id,
                content_id: con.id ? con.id : null,
                parent_content_id: con.id ? con.id : null,
                subcategory_title: con.subcategory_title,
                published_date: con.published_date,
                content: JSON.parse(con.data),
                is_deleted: con.is_deleted ? 1 : 0,
                deleted: con.is_deleted ? 1 : 0,
              });
            } else isMissingTemplate = true;
          });
        indSubcat.data = contents;
        subcats.push(indSubcat);
        if (
          sub_category_contents_count > 0 &&
          (subcat.name === null || subcat.name === "")
        )
          emptySubCategoryName = true;
      });
      indData.subcategories = subcats;
      finalData.push(indData);
      if (category_contents_count > 0 && (cat.name === null || cat.name === ""))
        emptyCategoryName = true;
    });
    if (emptyCategoryName) {
      this.setState({
        apiError: "Category name cannot be blank.",
      });
      return false;
    }
    if (emptySubCategoryName) {
      this.setState({
        apiError: "Sub-category name cannot be blank.",
      });
      return false;
    }
    if (isDuplicatCategory.length > 0) {
      this.setState({
        apiError: "The value you entered in a category is already in the list.",
      });
      return false;
    }
    if (isDuplicatSubCategory.length > 0) {
      this.setState({
        apiError:
          "The value you entered in a sub-category is already in the list",
      });
      return false;
    }
    if (isMissingTemplate) {
      this.setState({
        apiError:
          "Template is missing in the content section, Please choose the template",
      });
      return false;
    }
    if (
      isDuplicatCategory.length === 0 &&
      isDuplicatSubCategory.length === 0 &&
      !emptyCategoryName &&
      !emptySubCategoryName &&
      !isMissingTemplate
    ) {
      let dailogModalHeader = (
        <>
          <h3 className="text-darkblue2 font-weight-bold">
            {type ===
            constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_DRAFT.SLUG
              ? constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_DRAFT
                  .BUTTON_TITLE
              : constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_SUBMIT
                  .BUTTON_TITLE}
          </h3>
        </>
      );
      let dailogModalContent;
      let dailogModalStyleType;
      switch (type) {
        case constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_DRAFT.SLUG:
          dailogModalHeader = (
            <>
              <h3 className="text-darkblue2 font-weight-bold">
                {
                  constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_DRAFT
                    .BUTTON_TITLE
                }
              </h3>
            </>
          );
          dailogModalContent = (
            <>
              <div className="activate-body">
                <h2 className="font-weight-semibold mb-0">
                  {
                    constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_DRAFT
                      .CONFIRM_MESSAGE
                  }
                </h2>
              </div>
            </>
          );
          dailogModalStyleType = "success-modal";
          break;
        case constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_SUBMIT.SLUG:
          this.setState({
            quoteImageDailogModal: true,
            dailogModalConfig: {
              type: type,
              id: null,
            },
            apiError: null,
          });
          break;
        case constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_RE_SUBMIT.SLUG:
          dailogModalHeader = (
            <>
              <h3 className="text-darkblue2 font-weight-bold">
                {
                  constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_RE_SUBMIT
                    .BUTTON_TITLE
                }
              </h3>
            </>
          );
          dailogModalContent = (
            <>
              <div className="activate-body">
                <h2 className="font-weight-semibold mb-0">
                  {
                    constants.MANAGE_CONTENT_PERMISSIONS
                      .SPECIAL_REPORT_RE_SUBMIT.CONFIRM_MESSAGE
                  }
                </h2>
              </div>
            </>
          );
          dailogModalStyleType = "success-modal";
          break;
        case constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_REJECT.SLUG:
          dailogModalHeader = (
            <>
              <h3 className="text-darkblue2 font-weight-bold">
                {
                  constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_REJECT
                    .BUTTON_TITLE
                }
              </h3>
            </>
          );
          dailogModalContent = (
            <>
              <div className="remove-body">
                <h2 className="font-weight-semibold mb-0">
                  {
                    constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_REJECT
                      .CONFIRM_MESSAGE
                  }
                </h2>
              </div>
            </>
          );
          dailogModalStyleType = "danger-modal";
          break;
        case constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_APPROVE.SLUG:
          dailogModalHeader = (
            <>
              <h3 className="text-darkblue2 font-weight-bold">
                {
                  constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_APPROVE
                    .BUTTON_TITLE
                }
              </h3>
            </>
          );
          dailogModalContent = (
            <>
              <div className="activate-body">
                <h2 className="font-weight-semibold mb-0">
                  {
                    constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_APPROVE
                      .CONFIRM_MESSAGE
                  }
                </h2>
              </div>
            </>
          );
          dailogModalStyleType = "success-modal";
          break;
        default:
          break;
      }
      if (
        type !== constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_SUBMIT.SLUG
      ) {
        this.setState({
          dailogModal: true,
          dailogModalHeader: dailogModalHeader,
          dailogModalContent: dailogModalContent,
          dailogModalConfig: { type: type, id: null },
          dailogModalStyleType: dailogModalStyleType,
          apiError: null,
        });
      }
    }
  };

  resetContent = () => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Warning</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="inactive-body">
          <h2 className="font-weight-semibold mb-0">
            {
              constants.MANAGE_CONTENT_TRANSACTION_DETAILS
                .RESET_CHANGES_CONFIRM_MESSAGE
            }
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "reset_content", id: null },
      dailogModalStyleType: "inactive-modal",
    });
  };

  saveContentSuccess = (action) => {
    let message = "";
    switch (action) {
      case constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_DRAFT.SLUG:
        message =
          constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_DRAFT
            .SUCCESS_MESSAGE;
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_SUBMIT.SLUG:
        message =
          constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_SUBMIT
            .SUCCESS_MESSAGE;
        break;
      default:
        break;
    }
    Toast(message, "success");
    this.setState(
      {
        changesMade: false,
        dailogModal: false,
        dailogModalHeader: "",
        dailogModalContent: "",
        dailogModalConfig: {},
        dailogModalStyleType: "",
      },
      () => {
        window.removeEventListener("beforeunload", this.onUnload);
        let redirectionTo;
        if (
          action ===
          constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_DRAFT.SLUG
        )
          redirectionTo =
            constants.APPLICATION_ROUTE.MANAGE_CONTENT_SPECIAL_REPORT_MY_DRAFTS
              .ROUTE;
        else
          redirectionTo = formatRoute(
            constants.APPLICATION_ROUTE
              .MANAGE_CONTENT_SPECIAL_REPORT_TRACK_SUBMISSIONS.ROUTE,
            {}
          );
        this.props.history.push({
          pathname: redirectionTo,
        });
      }
    );
  };

  saveContent2 = (org_action) => {
    let action = org_action.replace("special_report_", "");
    let specialReportId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    let finalData = [];
    let original_categories = [
      ...this.props.special_report_details.specialReportCategories,
    ];
    let original_content = [
      ...this.props.special_report_details.specialReportContent,
    ];
    let isDuplicatCategory = [];
    let isDuplicatSubCategory = [];
    let emptyCategoryName = false;
    let emptySubCategoryName = false;
    let isMissingTemplate = false;
    original_categories.forEach((cat) => {
      let indData = {};
      let isCategoryNameChanged = cat.id
        ? this.findUpdatesCategory(cat.id)
        : true;
      indData.id = cat.id ? (isCategoryNameChanged ? null : cat.id) : null;
      indData.parent_category_id = cat.id ? cat.id : null;
      indData.name = cat.name ? cat.name : null;
      indData.rank = cat.rank ? cat.rank : null;
      if (cat.name === null || cat.name === "") emptyCategoryName = true;
      if (
        original_categories.filter(
          (c) => c.name.toLowerCase() === cat.name.toLowerCase()
        ).length > 1
      ) {
        isDuplicatCategory.push(cat.name);
      }
      let subcats = [];
      cat.subcategories.forEach((subcat) => {
        let indSubcat = {};
        let isSubCategoryNameChanged =
          cat.id && subcat.id
            ? this.findUpdatesSubCategory(cat.id, subcat.id)
            : true;
        indSubcat.id = subcat.id
          ? isCategoryNameChanged || isSubCategoryNameChanged
            ? null
            : subcat.id
          : null;
        indSubcat.parent_sub_category_id = subcat.id ? subcat.id : null;
        indSubcat.name = subcat.name ? subcat.name : null;
        indSubcat.rank = subcat.rank ? subcat.rank : null;
        if (subcat.name === null || subcat.name === "")
          emptySubCategoryName = true;
        if (
          cat.subcategories.filter(
            (cs) => cs.name.toLowerCase() === subcat.name.toLowerCase()
          ).length > 1
        ) {
          isDuplicatSubCategory.push(subcat.name);
        }
        let contents = [];
        original_content
          .filter((content) => content.subcategory_id === subcat.uid)
          .forEach((con) => {
            if (con.template_id) {
              let isContentChanged =
                (con.id ? this.findUpdates(con.uid) : true) || con.is_deleted;
              if (
                isCategoryNameChanged ||
                isSubCategoryNameChanged ||
                isContentChanged
              ) {
                contents.push({
                  template_id: con.template_id,
                  content_id: con.id
                    ? isCategoryNameChanged ||
                      isSubCategoryNameChanged ||
                      isContentChanged
                      ? null
                      : con.id
                    : null,
                  parent_content_id: con.id ? con.id : null,
                  subcategory_title: con.subcategory_title,
                  published_date: con.published_date,
                  content: JSON.parse(con.data),
                  is_deleted: con.is_deleted ? 1 : 0,
                  deleted: 0,
                });
              }
            } else isMissingTemplate = true;
          });
        indSubcat.data = contents;
        if (contents.length > 0) subcats.push(indSubcat);
      });
      indData.subcategories = subcats;
      if (subcats.length > 0) finalData.push(indData);
    });
    SpecialReportServices.saveSpecialReportContent(
      specialReportId,
      { categories: finalData, transaction_id: null },
      action
    )
      .then(async (res) => {
        if (
          this.state.quoteContent ||
          (this.state.quoteImageUrl && this.state.quoteImageName)
        ) {
          const body = {
            mapping_id: this.state.mapping_id
              ? this.state.mapping_id
              : specialReportId,
            transaction_id: res.data.transaction_id ?? "",
            mapping_type: this.state.mapping_type ?? "special_report",
            content: this.state.quoteContent ?? "",
            base64_data: this.state.quoteImageUrl ?? "",
            imageName: this.state.quoteImageName ?? "",
          };
          // API Call For adding Image and Quote and Save it to database
          await QuoteImageServices.addImageandQuote(body)
            .then((res) => {
              this.setState({
                base64_data: null,
              });
            })
            .catch((error) => {
              Toast(
                error && error.data && error.data.error
                  ? error.data.error
                  : constants.ERROR.SOMETHING_WENT_WRONG,
                "error"
              );
            });
        }
        this.setState(
          {
            dailogModal: false,
            dailogModalHeader: "",
            dailogModalContent: "",
            dailogModalConfig: {},
            dailogModalStyleType: "",
            quoteImageDailogModal: false,
          },
          () => {
            this.saveContentSuccess(org_action);
          }
        );
      })
      .catch((error) => {
        this.setState({
          apiError:
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
        });
      });
  };

  resetContent2 = () => {
    let specialReportId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    this.initContent(specialReportId);
  };

  findUpdates = (uid) => {
    let newContent =
      this.props.special_report_details.specialReportContent.filter(
        (comp) => comp.uid === uid
      )[0];
    let oldContent = this.state.masterContent.filter(
      (comp) => comp.uid === uid
    )[0];
    if (
      _.isEqual(newContent.subcategory_title, oldContent.subcategory_title) ===
        true &&
      _.isEqual(newContent.data, oldContent.data) === true &&
      _.isEqual(newContent.published_date, oldContent.published_date) === true
    )
      return false;
    return true;
  };

  confirmDailog = (config) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Delete</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {config.type === "category"
              ? constants.MANAGE_CONTENT_TRANSACTION_DETAILS
                  .DELETE_CATEGORY_CONFIRM_MESSAGE
              : constants.MANAGE_CONTENT_TRANSACTION_DETAILS
                  .DELETE_SUB_CATEGORY_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: config,
      dailogModalStyleType: "danger-modal",
    });
  };

  dailogModalAccept = (config) => {
    this.setState(
      {
        dailogModal: false,
        dailogModalHeader: "",
        dailogModalContent: "",
        dailogModalConfig: {},
      },
      () => {
        this.matchEvent(config);
      }
    );
  };

  quoteImageDailogModalAccept = (config) => {
    this.setState(
      {
        quoteImageDailogModal: false,
      },
      () => {
        this.matchEvent(config);
      }
    );
  };
  matchEvent = (config) => {
    switch (config.type) {
      case "category":
        this.toggleCheckbox(
          config.checkbox_selection,
          config.type,
          config.category_id,
          config.sub_category_id
        );
        break;
      case "sub_category":
        this.toggleCheckbox(
          config.checkbox_selection,
          config.type,
          config.category_id,
          config.sub_category_id
        );
        break;
      case "switch_segment":
        this.setState({ changesMade: false }, () => {
          this.switchSegment2(config.id);
        });
        break;
      case "switch_company":
        this.setState({ changesMade: false }, () => {
          this.handleCompanyChange2(config.id);
        });
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_DRAFT.SLUG:
        this.saveContent2(
          constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_DRAFT.SLUG
        );
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_SUBMIT.SLUG:
        this.saveContent2(
          constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_SUBMIT.SLUG
        );
        break;
      case "reset_content":
        this.resetContent2();
        break;
      default:
        break;
    }
  };

  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };

  quoteImageDailogModalDecline = () => {
    this.setState({
      quoteContent: null,
      quoteImageUrl: null,
      quoteImageName: null,
      base64_data: null,
      quoteImageDailogModal: false,
      config: {},
    });
  };

  componentWillUnmount = () => {
    this.props.specialReportCategories({});
    this.props.specialReportContent({});
    this.props.specialReportCategories([]);
    this.props.specialReportTemplates([]);
  };

  onNameChangeHandler = (type, category_id, sub_category_id, value) => {
    let original_content = [
      ...this.props.special_report_details.specialReportContent,
    ];
    let original_categories = [
      ...this.props.special_report_details.specialReportCategories,
    ];
    if (type === "category") {
      original_categories.forEach((res) => {
        if (res.uid === category_id) {
          res.name = value;
        }
      });
    } else if (type === "sub_category") {
      original_categories.forEach((res) => {
        if (res.uid === category_id) {
          if (res.subcategories) {
            res.subcategories.forEach((res_sub) => {
              if (res_sub.uid === sub_category_id) {
                res_sub.name = value;
              }
            });
          }
        }
      });
    }
    this.props.specialReportCategories(original_categories);
    this.detectChanges(original_content, original_categories);
  };

  addCategory = (data, edit, category_name) => {
    if (!edit) {
      let original_content = [
        ...this.props.special_report_details.specialReportContent,
      ];
      let original_categories = [
        ...this.props.special_report_details.specialReportCategories,
      ];
      let rank =
        original_categories.length > 0
          ? Math.max(...original_categories.map((o) => o.rank))
          : 0;
      let category_name = data[0];
      data.shift();
      let newCategory = {
        id: null,
        uid: Math.random(),
        name: category_name,
        is_selected: false,
        subcategories: [],
        rank: rank + 1,
      };
      if (
        !original_categories.filter((s) => s.name === category_name).length > 0
      ) {
        let subcat = [];
        for (let i = 0; i < data.length; i++) {
          subcat.push({
            id: null,
            uid: Math.random(),
            name: data[i],
            is_selected: false,
            rank: i + 1,
          });
        }
        newCategory.subcategories = subcat;
        original_categories.push(newCategory);
        this.props.specialReportCategories(original_categories);
        this.detectChanges(original_content, original_categories);
        return true;
      } else {
        Toast("Category Name Already Exists", "error");
        this.setState({
          adddataSuccess: false,
        });
        return false;
      }
    } else {
      let original_content = [
        ...this.props.special_report_details.specialReportContent,
      ];
      let original_categories = [
        ...this.props.special_report_details.specialReportCategories,
      ];
      let rank =
        original_categories.length > 0
          ? Math.max(...original_categories.map((o) => o.rank))
          : 0;
      let category_selection = false;
      let category_id = 0;
      let category_temp = false;
      let add_categories = 0;
      let category_status = [];
      original_categories = original_categories.map((s) => {
        if (s.name === category_name) {
          s.name = data[0];
          category_id = s.uid;
          category_selection = s.is_selected;
          data.shift();
          for (let i = 0; i < s.subcategories.length; i++) {
            s.subcategories[i].name = data[i];
          }
          category_temp = data.length > s.subcategories.length;
          if (data.length > s.subcategories.length) {
            for (let i = s.subcategories.length; i < data.length; i++) {
              this.addSubCategory(category_name, data[i]);
            }
          }
          s.is_selected = category_selection;
        }
        category_status.push(s.is_selected);
        return s;
      });
      original_categories = original_categories.map((s, index) => {
        s.is_selected = category_status[index];
        return s;
      });
      this.props.specialReportCategories(original_categories);
      this.detectChanges(original_content, original_categories);
      return true;
    }
  };

  addSubCategory = (cat_name, sub_cat) => {
    let original_content = [
      ...this.props.special_report_details.specialReportContent,
    ];
    let original_categories = [
      ...this.props.special_report_details.specialReportCategories,
    ];
    let index = original_categories.findIndex((i) => i.name === cat_name);
    let rank =
      typeof original_categories[index].subcategories != "undefined"
        ? original_categories[index].subcategories.length > 0
          ? Math.max(
              ...original_categories[index].subcategories.map((o) => o.rank)
            )
          : 0
        : 0;
    let newSubCategory = {
      id: null,
      uid: Math.random(),
      name: sub_cat,
      is_selected: false,
      rank: rank + 1,
    };
    original_categories[index].subcategories.push(newSubCategory);
    this.props.special_report_details.specialReportContent.push(newSubCategory);
    this.props.specialReportCategories(original_categories);
    this.unCheckOnNoContent(original_content);
    this.detectChanges(original_content, original_categories);
  };

  getTbriUsers = () => {
    UserServices.getTbriUsers()
      .then((res) => {
        let response = res && res.data && res.data.data;
        response = response.sort((a, b) =>
          a.first_name.toLowerCase() > b.first_name.toLowerCase()
            ? 1
            : b.first_name.toLowerCase() > a.first_name.toLowerCase()
            ? -1
            : 0
        );
        response.forEach((r) => {
          r.is_selected = false;
          r.name = `${r.first_name} ${r.last_name}`;
        });
        this.props.setTbriUsers(response);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  handleAuthorChange = (e) => {
    let getTbriUsers = [...this.props.home.getTbriUsers];
    getTbriUsers.forEach((c) => {
      c.is_selected = c.id === e.id ? true : false;
    });
    this.props.setTbriUsers(getTbriUsers);
  };

  addAuthor = () => {
    let getTbriUsers = [...this.props.home.getTbriUsers];
    let user = {
      id: getTbriUsers.find((u) => u.is_selected === true)
        ? getTbriUsers.find((u) => u.is_selected === true).id
        : null,
    };
    let specialReportId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    let body = {
      type: "special_report",
      mappingId: specialReportId,
    };
    SpecialReportServices.addAuthor(user.id, body)
      .then((response) => {
        this.getspecialReportDetails(specialReportId);
        this.getTbriUsers();
        Toast(
          constants.MANAGE_CONTENT_TRANSACTION_DETAILS
            .ADD_AUTHOR_SUCCESS_MESSAGE,
          "success"
        );
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  removeAuthor = (id) => {
    let specialReportId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    SpecialReportServices.removeAuthor(specialReportId, id)
      .then((response) => {
        this.getspecialReportDetails(specialReportId);
        this.getTbriUsers();
        Toast(
          constants.MANAGE_CONTENT_TRANSACTION_DETAILS
            .DELETE_AUTHOR_SUCCESS_MESSAGE,
          "success"
        );
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  handleQuoteContent = (e) => {
    this.setState({
      quoteContent: e.target.value,
    });
  };
  handleQuoteImage = (base64Url, imageName) => {
    let filteredBase64Url = base64Url.split(";base64,")[1];
    this.setState({
      quoteImageUrl: filteredBase64Url,
      quoteImageName: imageName,
    });
  };
  breadcrumbs = () => {
    let specialReportDetails =
      this.props.special_report_details &&
      this.props.special_report_details.specialReportDetails
        ? this.props.special_report_details.specialReportDetails
        : {};
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.SPECIAL_REPORT_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.SPECIAL_REPORT.LIST.NAME,
        link: formatRoute(
          constants.APPLICATION_ROUTE.SPECIAL_REPORT.LIST.ROUTE,
          {}
        ),
        is_active: false,
      },
      {
        title: `${
          specialReportDetails.special_report != undefined &&
          specialReportDetails.special_report.name != undefined
            ? specialReportDetails.special_report.name
            : ""
        }`,
        link: null,
        is_active: true,
      },
    ];
    DocumentTitle(
      `${
        specialReportDetails.special_report != undefined &&
        specialReportDetails.special_report.name != undefined
          ? specialReportDetails.special_report.name
          : ""
      }`
    );
    return breadcrumbs;
  };

  copyHandler = (author) => {
    let specialReportDetails =
      this.props.special_report_details &&
      this.props.special_report_details.specialReportDetails
        ? JSON.parse(
            JSON.stringify(
              this.props.special_report_details.specialReportDetails
            )
          )
        : {};
    let authors = specialReportDetails.special_report.authors;
    let authorIndex = authors.findIndex((a) => a.id === author.id);
    specialReportDetails.special_report.authors[authorIndex].copied = true;
    this.props.specialReportDetails(specialReportDetails);
    Copy(author.email);
    setTimeout(() => {
      let cd = {
        special_report: JSON.parse(
          JSON.stringify(specialReportDetails.special_report)
        ),
      };
      cd.special_report.authors[authorIndex].copied = false;
      this.props.specialReportDetails(cd);
    }, 2000);
  };

  render() {
    let specialReportDetails =
      this.props.special_report_details &&
      this.props.special_report_details.specialReportDetails
        ? this.props.special_report_details.specialReportDetails.special_report
        : {};
    const isEditEnabled =
      permissionCheck(
        constants.MANAGE_CONTENT_PERMISSIONS.KEY,
        constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
        constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_DRAFT.SLUG
      ) ||
      permissionCheck(
        constants.MANAGE_CONTENT_PERMISSIONS.KEY,
        constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
        constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_SUBMIT.SLUG
      );
    return (
      <div
        className={`${
          this.state.innerSidebarOpen
            ? "opened-innersidebar"
            : "closed-innersidebar"
        } main_content fixed-title-main-content`}
      >
        <div className="m-3 mb-0 bg-white px-3 pb-3 br_8 main_content_height">
          <div className="d-flex align-items-center benchmark-details-page-title-sec fixed-title-section">
            <div className="breadcrumb-mb-0">
              <CustomBreadcrumb breadcrumbs={this.breadcrumbs()} />
            </div>
            <Authors
              entity={constants.ACL.SPECIAL_REPORT_AUTHOR_RESOURCE.TITLE}
              create_action={
                constants.ACL.SPECIAL_REPORT_AUTHOR_RESOURCE.ACTIONS.CREATE
              }
              delete_action={
                constants.ACL.SPECIAL_REPORT_AUTHOR_RESOURCE.ACTIONS.DELETE
              }
              authors_type={constants.AUTHORS_TYPES.SPECIAL_REPORT_DETAILS}
              addAuthor={this.addAuthor}
              removeAuthor={this.removeAuthor}
              handleAuthorChange={this.handleAuthorChange}
              authors={
                this.props.special_report_details?.specialReportDetails
                  ?.special_report?.authors
              }
              copyhandler={this.copyHandler}
            />
          </div>
          <div className="company_details project_details">
            <Prompt
              when={this.state.changesMade}
              message={(location) => `Changes you made may not be saved.`}
            />
            <DetailsCategories
              addEmptyContent={this.addEmptyContent}
              handleAddRemoveTemplateSubCategory={
                this.handleAddRemoveTemplateSubCategory
              }
              handleAddRemoveTemplateCategory={
                this.handleAddRemoveTemplateCategory
              }
              toggleCheckbox={this.toggleCheckboxParent}
              isEditable={isEditEnabled}
              onNameChangeHandler={this.onNameChangeHandler}
              addCategory={this.addCategory}
              addSubCategory={this.addSubCategory}
              onDragEnd={this.onDragEnd}
              toggleInnerSidebar={this.toggleInnerSidebar}
              componentName={constants.MODULE_NAMES.SPECIAL_REPORT}
              {...this.props}
            />
            <DetailsContent
              switchSegmentHandler={this.switchSegment}
              addEmptyContent={this.addEmptyContent}
              removeContent={this.removeContent}
              dateOnChangeHandler={this.dateOnChangeHandler}
              onContentChangeHandler={this.onContentChangeHandler}
              onSectionTitleChangeHandler={this.onSectionTitleChangeHandler}
              saveContent={this.saveContent}
              changesMade={this.state.changesMade}
              undoRemoveContent={this.undoRemoveContent}
              resetContent={this.resetContent}
              isEditable={isEditEnabled}
              onTemplateChangeHandler={this.onTemplateChangeHandler}
              handleAuthorChange={this.handleAuthorChange}
              addAuthor={this.addAuthor}
              removeAuthor={this.removeAuthor}
              apiError={this.state.apiError}
              componentName={constants.MODULE_NAMES.SPECIAL_REPORT}
              {...this.props}
            />
            <QuoteImageDialog
              isOpen={this.state.quoteImageDailogModal}
              header={constants.QUOTE_IMAGE.MODAL_HEADER.ADDING_QUOTE}
              config={this.state.dailogModalConfig}
              accept={this.quoteImageDailogModalAccept}
              decline={this.quoteImageDailogModalDecline}
              state={this.state}
              handleTitleChange={this.handleQuoteContent}
              onChangeHandlerImage={this.handleQuoteImage}
              saveButtonName={constants.QUOTE_IMAGE.UPLOAD_BUTTON.CREATE}
              handleQuoteImageRemove={(e) => {
                this.setState({
                  image_delete: e,
                });
              }}
            />
            <DailogNew
              isOpen={this.state.dailogModal}
              accept={this.dailogModalAccept}
              decline={this.dailogModalDecline}
              header={this.state.dailogModalHeader}
              content={this.state.dailogModalContent}
              config={this.state.dailogModalConfig}
              modalStyleType={this.state.dailogModalStyleType}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    special_report_details: state.special_report_details,
    home: state.home,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    specialReportCategories: (payload) => {
      dispatch(specialReportCategories(payload));
    },
    specialReportDetails: (payload) => {
      dispatch(specialReportDetails(payload));
    },
    specialReportContent: (payload) => {
      dispatch(specialReportContent(payload));
    },
    specialReportTemplates: (payload) => {
      dispatch(specialReportTemplates(payload));
    },
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
    setTbriUsers: (tbriUsers) => {
      dispatch(getTbriUsers(tbriUsers));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpecialReportDetail);
