import { Axios } from "../lib/axios";
export const UserAnalyticLogServices = {
  getClientList,
  getDayClientList,
  getClientListReport,
  getPendingReminder,
  fetchclientUser,
  clientLastlogin,
  segmentSpecificdownload,
  topFiveData,
};
function getClientList() {
  return Axios.get("/clientlist");
}
function getDayClientList() {
  return Axios.get("/dayuserlist");
}
function getClientListReport(url) {
  document.body.classList.add("second-loading-indicator");
  return Axios.get(url);
}
function getPendingReminder(startDate, endDate) {
  return Axios.get(`/pendingReminder`);
  //  return Axios.get(`/pendingReminder/${startDate}/start/${endDate}/end`);
}

function fetchclientUser(url) {
  return Axios.get(url);
}
function clientLastlogin(id) {
  return Axios.get(`/dayclientuserlist/${id}`);
}
function segmentSpecificdownload(url) {
  return Axios.get(url);
}

function topFiveData(url) {
  return Axios.get(url);
}
