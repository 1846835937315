import { Axios } from "../lib/axios";
export const UserServices = {
  getUserLogin,
  getUserLogout,
  getTbriUsers,
  getUsers,
  getUserDetails,
  addUser,
  editUser,
  toggleUser,
  removeUser,
  resendEmailVerification,
  getUserRoles,
  getTotalPermissions,
  getTotalRolePermissions,
  getTotalUserPermissions,
  getTotalRoleUserPermissions,
  savePermissions,
  resetPassword,
  verifyEmail,
  sendverificationmailForPasswordSetting,
  changePassword,
  codeVerification,
  registerSSO,
  setPassword,
  getAssignedUsers,
};
function getUserLogin(body) {
  return Axios.post("login", body);
}
function getUserLogout() {
  return Axios.get("logout");
}
function getTbriUsers() {
  return Axios.get("users?role_type=tbr_admin");
}
function getUsers(url) {
  return Axios.get(url);
}
function getUserDetails(userId) {
  return Axios.get(`user/${userId}`);
}
function addUser(body) {
  return Axios.post(`user`, body);
}
function editUser(userId, body) {
  return Axios.patch(`user/${userId}`, body);
}
function toggleUser(status, body) {
  return Axios.patch(`user/status/${status}`, body);
}
function removeUser(userId) {
  return Axios.delete(`user/${userId}`);
}
function resendEmailVerification(body) {
  return Axios.post(`resendverificationmail`, body);
}
function getUserRoles() {
  return Axios.get(`/roles?type=tbr_admin`);
}
function getTotalPermissions() {
  return Axios.get(`/permissions`);
}
function getTotalRolePermissions(roleId) {
  return Axios.get(`/permissions?role_id=${roleId}`);
}
function getTotalUserPermissions(userId) {
  return Axios.get(`/permissions?user_id=${userId}`);
}
function getTotalRoleUserPermissions(roleId, userId) {
  return Axios.get(`/permissions?role_id=${roleId}&user_id=${userId}`);
}
function savePermissions(userId, body) {
  return Axios.post(`user/${userId}/permissions`, body);
}
function resetPassword(code, body) {
  return Axios.post(`setpassword/${code}`, body);
}
function sendverificationmailForPasswordSetting(body) {
  return Axios.post(`sendverificationmailForPasswordSetting`, body);
}

function verifyEmail(code) {
  return Axios.get(`user/${code}/verify`);
}

function changePassword(body) {
  return Axios.post("changePassword", body);
}

function codeVerification(code) {
  return Axios.get(`/verifyCodeExpiry/${code}`);
}
function getAssignedUsers(url) {
  return Axios.get(url);
}
function registerSSO(body) {
  return Axios.post("register/sso", body);
}
function setPassword(body) {
  return Axios.post("app/setPassword", body);
}
