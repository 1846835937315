import React, { useEffect } from "react";
import CustomBreadcrumb from "../Components/CustomBreadcrumb";
import SuppressionList from "./SuppressionList";
import constants from "../Utils/constants";
import { useDispatch } from "react-redux";
import { activeMenuTab } from "../Store/Actions/User";

function EmailSuppressionList(props) {
  const dispatch = useDispatch();

  const breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.CLIENT_GROUP.NAME,
        link: constants.APPLICATION_ROUTE.CLIENT.LIST.ROUTE,
        is_active: false,
      },
      {
        title:
          constants.APPLICATION_ROUTE.CLIENT_SUPPRESSION_EMAIL.LIST
            .HEADER_TITLE,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  useEffect(() => {
    dispatch(
      activeMenuTab(
        constants.APPLICATION_ROUTE.CLIENT_SUPPRESSION_EMAIL.LIST.ROUTE
      )
    );
  }, []);

  return (
    <aside className="main_content">
      <div className="m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height">
        <CustomBreadcrumb breadcrumbs={breadcrumbs()} />
        <div className="comapnay_tbl">
          <div className="">
            <SuppressionList
              {...props}
              pageNo={constants.PAGINATION.DEFAULT_PAGE_NO}
              pageSize={constants.PAGINATION.DEFAULT_PAGE_SIZE}
            />
          </div>
        </div>
      </div>
    </aside>
  );
}

export default EmailSuppressionList;
