import { Axios } from "../lib/axios";
export const SpecialReportServices = {
  getTotalSpecialReports,
  getSpecialReports,
  getSpecialReportDetails,
  getSpecialReportContent,
  saveSpecialReportContent,
  addAuthor,
  removeAuthor,
  addSpecialReport,
  editSpecialReport,
  toggleSpecialReport,
  removeSpecialReport,
  getCategories,
  getSpecialReportTemplates,
  saveSpecialReportTransactionContent,
  getManageContentTransactionContent,
};
function getTotalSpecialReports(url) {
  return Axios.get(url);
}
function getSpecialReports(url) {
  return Axios.get(url);
}
function getSpecialReportDetails(id) {
  return Axios.get(`/specialreport/${id}`);
}
function getSpecialReportContent(id) {
  return Axios.get(`/specialreport/${id}/content`);
}
function saveSpecialReportContent(specialReportId, content, action) {
  return Axios.post(
    `specialreport/${specialReportId}/content/${action}`,
    content
  );
}
function addAuthor(userId, body) {
  return Axios.post(`author/${userId}`, body);
}
function removeAuthor(specialReportId, id) {
  return Axios.delete(`author/${id}`);
}
function addSpecialReport(body) {
  return Axios.post(`specialreport`, body);
}
function editSpecialReport(specialReportId, body) {
  return Axios.patch(`/specialreport/${specialReportId}`, body);
}
function toggleSpecialReport(specialReportId, body) {
  return Axios.patch(
    `specialreport/${specialReportId}/status/${body.is_active}`
  );
}
function removeSpecialReport(specialReportId) {
  return Axios.delete(`/specialreport/${specialReportId}`);
}
function getCategories(benchmarkId) {
  return Axios.get(`categories?type=specialreport`);
}
function getSpecialReportTemplates() {
  return Axios.get("templates");
}
function saveSpecialReportTransactionContent(
  transaction_id,
  content,
  action = ""
) {
  return Axios.post(
    `transaction/${transaction_id}/type/special_report/state/${action}`,
    content
  );
}
function getManageContentTransactionContent(transaction_id) {
  return Axios.get(`transaction/${transaction_id}/type/special_report`);
}
