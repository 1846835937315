import React from "react";
import { DropdownList } from "react-widgets";
import "react-widgets/dist/css/react-widgets.css";

const CustomDropdownList = (props) => {
  return (
    <DropdownList
      filter
      data={props.data}
      valueField="id"
      textField="name"
      onChange={(value) => props.onChangeHandler(props.id, value)}
      defaultValue={
        props.data.find((d) => d.is_selected === true)
          ? props.data.find((d) => d.is_selected === true).id
          : null
      }
      value={
        props.data.find((d) => d.is_selected === true)
          ? props.data.find((d) => d.is_selected === true).id
          : null
      }
      placeholder={props.placeholder ? props.placeholder : ""}
      disabled={props.isDisabled ? props.isDisabled : false}
    />
  );
};

export default CustomDropdownList;
