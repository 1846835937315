import { Axios } from "../lib/axios";
export const MarketLandscapeServices = {
  getTotalMarketLandscapes,
  getMarketLandscapes,
  getMarketLandscapeDetails,
  getMarketLandscapeContent,
  saveMarketLandscapeContent,
  addAuthor,
  removeAuthor,
  addMarketLandscape,
  editMarketLandscape,
  toggleMarketLandscape,
  removeMarketLandscape,
  getCategories,
  getMarketLandscapeTemplates,
  saveMarketLandscapeTransactionContent,
  getManageContentTransactionContent,
};
function getTotalMarketLandscapes(url) {
  return Axios.get(url);
}
function getMarketLandscapes(url) {
  return Axios.get(url);
}
function getMarketLandscapeDetails(id) {
  return Axios.get(`marketlandscape/${id}`);
}
function getMarketLandscapeContent(id) {
  return Axios.get(`marketlandscape/${id}/content`);
}
function saveMarketLandscapeContent(marketLandscapeId, content, action) {
  return Axios.post(
    `marketlandscape/${marketLandscapeId}/content/${action}`,
    content
  );
}
function addAuthor(userId, body) {
  return Axios.post(`author/${userId}`, body);
}
function removeAuthor(marketLandscapeId, id) {
  return Axios.delete(`author/${id}`);
}
function addMarketLandscape(body) {
  return Axios.post(`marketlandscape`, body);
}
function editMarketLandscape(marketLandscapeId, body) {
  return Axios.patch(`marketlandscape/${marketLandscapeId}`, body);
}
function toggleMarketLandscape(marketLandscapeId, body) {
  return Axios.patch(
    `marketlandscape/${marketLandscapeId}/status/${body.is_active}`
  );
}
function removeMarketLandscape(marketLandscapeId) {
  return Axios.delete(`marketlandscape/${marketLandscapeId}`);
}
function getCategories(marketLandscapeId) {
  return Axios.get(`categories?type=market_landscape`);
}
function getMarketLandscapeTemplates() {
  return Axios.get("templates");
}
function saveMarketLandscapeTransactionContent(
  transaction_id,
  content,
  action = ""
) {
  return Axios.post(
    `transaction/${transaction_id}/type/market_landscape/state/${action}`,
    content
  );
}
function getManageContentTransactionContent(transaction_id) {
  return Axios.get(`transaction/${transaction_id}/type/market_landscape`);
}
