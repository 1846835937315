/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import DynamicCategoryComponent from "./DynamicCategoryComponent";
import constants from "../../Utils/constants";

export default function ListLobs(props) {
  return (
    <DynamicCategoryComponent
      title={constants.APPLICATION_ROUTE.MANAGE_VERTICAL_AND_CATEGORIES.NAME}
      typeId={3}
      maxLevel={3}
      successMessage={constants.MASTER_DATA_VERTICAL.CREATE.SUCCESS}
      editMessage={constants.MASTER_DATA_VERTICAL.EDIT.SUCCESS}
      inActiveMessage={constants.MASTER_DATA_VERTICAL.IN_ACTIVATE.SUCCESS}
      activeMessage={constants.MASTER_DATA_VERTICAL.ACTIVATE.SUCCESS}
      inActivateConfirmMessage={
        constants.MASTER_DATA_VERTICAL.INACTIVATE_VERTICAL_CONFIRM_MESSAGE
      }
      activateConfirmMessage={
        constants.MASTER_DATA_VERTICAL.ACTIVATE_VERTICAL_CONFIRM_MESSAGE
      }
      {...props}
    />
  );
}
