import React from "react";

function MetricsRuleView({ data }) {
  return Object.entries(data).map(([key, value]) => {
    return (
      <div className="metric-rule-view">
        <div
          className="metrics-view-header"
          style={{
            borderBottom: "1px solid var(--Secondary-text, #9AA0A9)",
            paddingBottom: "8 8 0 8px !important",
          }}
        >
          <h6 className="text-black2 block-title font-weight-normal mb-0">
            {key}
          </h6>
        </div>
        <div className="metrics-rule-view-div">
          {value.length
            ? value.map((item) => {
                return <span className="metrics-rule-view-span">{item}</span>;
              })
            : "No data"}
        </div>
      </div>
    );
  });
}

export default MetricsRuleView;
