import React from "react";
import { Alert } from "reactstrap";

const Notification = (props) => {
  return (
    <div className={"row"}>
      <Alert
        color={props.color}
        className={`col-sm-12 ${props.className || ""}`.trim()}
      >
        {props.message}
      </Alert>
    </div>
  );
};

export default Notification;
