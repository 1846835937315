import React, { Component } from "react";
import constants from "../Utils/constants";
import { MasterServices } from "../Services/Master";
import { formatRoute } from "react-router-named-routes";
import Notification from "../Components/Notification";
import CustomDropdownList from "../Components/CustomDropdownList";
import { connect } from "react-redux";
import { getCurrencies, segments } from "../Store/Actions/User";
import DailogNew from "../Components/DailogNew";
import Toast from "../Utils/Toast";
import { getTbriUsers } from "../Store/Actions/User";
import DocumentTitle from "../Utils/DocumentTitle";
import CustomCloneDeep from "../Utils/CustomCloneDeep";
import { TemplateServices } from "../Services/Template";
import scrollIntoErrors from "../Components/errorScrolling";

const initialState = {
  lobs: [],
  lob_subcategories: [],
  lob_subcategories_2: [],
  lob_subcategories_3: [],
  lob_subcategories_4: [],
  lob_subcategories_5: [],
  geographies: [],
  geo_subcategories: [],
  geo_countries: [],
  verticals: [],
  vertical_categories: [],
  vertical_subcategories: [],
  metric_types: [],
  metrics: [],
  total_metrics: [],
  formErrors: {},
  dailogModal: false,
  dailogModalContent: "",
  dailogModalHeader: "",
  dailogModalConfig: {},
  dailogModalStyleType: "",
  isFormSubmitted: false,
  apiError: null,
};
class ManageTemplate extends Component {
  state = { ...initialState };
  async componentDidMount() {
    let templateId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    DocumentTitle(
      templateId === null
        ? constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.CREATE.HEADER_TITLE
        : constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.EDIT.HEADER_TITLE
    );
    await this.getMasters();
    if (templateId) this.prepopulateData(templateId);
  }

  sortOptions = (rows) => {
    return rows.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase()
        ? 1
        : b.name.toLowerCase() > a.name.toLowerCase()
        ? -1
        : 0
    );
  };

  getMasters = async () => {
    await MasterServices.getMasters()
      .then((res) => {
        let result = res && res.data;
        this.setState({
          lobs: CustomCloneDeep(
            result.lobs ? this.sortOptions(result.lobs) : []
          ),
          lob_subcategories: CustomCloneDeep(
            result.lobsubcategory ? this.sortOptions(result.lobsubcategory) : []
          ),
          lob_subcategories_2: CustomCloneDeep(
            result.lobsubcategory ? this.sortOptions(result.lobsubcategory) : []
          ),
          lob_subcategories_3: CustomCloneDeep(
            result.lobsubcategory ? this.sortOptions(result.lobsubcategory) : []
          ),
          lob_subcategories_4: CustomCloneDeep(
            result.lobsubcategory ? this.sortOptions(result.lobsubcategory) : []
          ),
          lob_subcategories_5: CustomCloneDeep(
            result.lobsubcategory ? this.sortOptions(result.lobsubcategory) : []
          ),
          lob_subcategories_6: CustomCloneDeep(
            result.lobsubcategory ? this.sortOptions(result.lobsubcategory) : []
          ),
          geographies: CustomCloneDeep(
            result.geography ? this.sortOptions(result.geography) : []
          ),
          geo_subcategories: CustomCloneDeep(
            result.geo_subcategory
              ? this.sortOptions(result.geo_subcategory)
              : []
          ),
          geo_countries: CustomCloneDeep(
            result.country ? this.sortOptions(result.country) : []
          ),
          verticals: CustomCloneDeep(
            result.vertical ? this.sortOptions(result.vertical) : []
          ),
          vertical_categories: CustomCloneDeep(
            result.vertical_category
              ? this.sortOptions(result.vertical_category)
              : []
          ),
          vertical_subcategories: CustomCloneDeep(
            result.vertical_category
              ? this.sortOptions(result.vertical_category)
              : []
          ),
          metric_types: CustomCloneDeep(
            result.metric_type ? this.sortOptions(result.metric_type) : []
          ),
          total_metrics: CustomCloneDeep(
            result.metric ? this.sortOptions(result.metric) : []
          ),
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  prepopulateData = (templateId) => {};

  handleMasterChange = (e, props) => {
    this.setMastersDropdown(e[props.valueField], props);
  };

  setMastersDropdown = (id, props) => {
    let selectors = [...this.state[props.selector]];
    selectors.forEach((c) => {
      c.is_selected = c[props.valueField] === id;
    });
    this.setState(
      {
        ...this.state,
        [props.selector]: selectors,
      },
      () => {
        if (props.selector === "metric_types") {
          this.setMetrics(id);
        } else if (this.state.isFormSubmitted) this.isFormValid();
      }
    );
  };

  setMetrics = (id) => {
    let metrics = CustomCloneDeep(this.state.total_metrics);
    metrics = metrics.filter((m) => m.metrictypeid === id);
    this.setState(
      {
        metrics,
      },
      () => {
        if (this.state.isFormSubmitted) this.isFormValid();
      }
    );
  };
  isFormValid = () => {
    let errorsList = [];
    let formError = false;
    let lobError = false;
    let lobSubCategoryError = false;
    let lobSubCategory2Error = false;
    let lobSubCategory3Error = false;
    let lobSubCategory4Error = false;
    let lobSubCategory5Error = false;
    let geographyError = false;
    let regionError = false;
    let countryError = false;
    let verticalError = false;
    let verticalCategoryError = false;
    let verticalSubCategoryError = false;
    let metricTypeError = false;
    let metricError = false;
    if (!this.state.lobs.find((l) => l.is_selected)) {
      lobError = true;
      formError = true;
      errorsList.push("lineofbusiness");
    }
    if (!this.state.geographies.find((l) => l.is_selected)) {
      geographyError = true;
      formError = true;
      errorsList.push("geography");
    }
    if (!this.state.verticals.find((l) => l.is_selected)) {
      verticalError = true;
      formError = true;
      errorsList.push("vertical");
    }
    if (!this.state.metric_types.find((l) => l.is_selected)) {
      metricTypeError = true;
      formError = true;
      errorsList.push("metrictype");
    }
    if (!this.state.metrics.find((l) => l.is_selected)) {
      metricError = true;
      formError = true;
      errorsList.push("metric");
    }
    scrollIntoErrors(errorsList);
    this.setState({
      ...this.state,
      formErrors: {
        ...this.state.formErrors,
        lobError: lobError,
        lobSubCategoryError: lobSubCategoryError,
        lobSubCategory2Error: lobSubCategory2Error,
        lobSubCategory3Error: lobSubCategory3Error,
        lobSubCategory4Error: lobSubCategory4Error,
        lobSubCategory5Error: lobSubCategory5Error,
        geographyError: geographyError,
        regionError: regionError,
        countryError: countryError,
        verticalError: verticalError,
        verticalCategoryError: verticalCategoryError,
        verticalSubCategoryError: verticalSubCategoryError,
        metricTypeError: metricTypeError,
        metricError: metricError,
      },
      apiError: null,
    });
    return formError;
  };

  handleSubmit = () => {
    this.setState(
      {
        isFormSubmitted: true,
      },
      () => {
        if (!this.isFormValid()) {
          let templateId = this.props.match.params.id
            ? this.props.match.params.id
            : null;
          let prepareObject = {
            lob_id: this.state.lobs.find((l) => l.is_selected)
              ? this.state.lobs.find((l) => l.is_selected).id
              : null,
            lob_subcategory1_id: this.state.lob_subcategories.find(
              (l) => l.is_selected
            )
              ? this.state.lob_subcategories.find((l) => l.is_selected)
                  .lobsubcategoryid
              : null,
            lob_subcategory2_id: this.state.lob_subcategories_2.find(
              (l) => l.is_selected
            )
              ? this.state.lob_subcategories_2.find((l) => l.is_selected)
                  .lobsubcategoryid
              : null,
            lob_subcategory3_id: this.state.lob_subcategories_3.find(
              (l) => l.is_selected
            )
              ? this.state.lob_subcategories_3.find((l) => l.is_selected)
                  .lobsubcategoryid
              : null,
            lob_subcategory4_id: this.state.lob_subcategories_4.find(
              (l) => l.is_selected
            )
              ? this.state.lob_subcategories_4.find((l) => l.is_selected)
                  .lobsubcategoryid
              : null,
            lob_subcategory5_id: this.state.lob_subcategories_5.find(
              (l) => l.is_selected
            )
              ? this.state.lob_subcategories_5.find((l) => l.is_selected)
                  .lobsubcategoryid
              : null,
            geo_id: this.state.geographies.find((l) => l.is_selected)
              ? this.state.geographies.find((l) => l.is_selected).geographyid
              : null,
            geosubcategory_id: this.state.geo_subcategories.find(
              (l) => l.is_selected
            )
              ? this.state.geo_subcategories.find((l) => l.is_selected)
                  .geosubcategoryid
              : null,
            country_id: this.state.geo_countries.find((l) => l.is_selected)
              ? this.state.geo_countries.find((l) => l.is_selected).countryid
              : null,
            vertical_id: this.state.verticals.find((l) => l.is_selected)
              ? this.state.verticals.find((l) => l.is_selected).verticalid
              : null,
            verticalcategory_id: this.state.vertical_categories.find(
              (l) => l.is_selected
            )
              ? this.state.vertical_categories.find((l) => l.is_selected)
                  .verticalcategoryid
              : null,
            verticalsubcategory_id: this.state.vertical_subcategories.find(
              (l) => l.is_selected
            )
              ? this.state.vertical_subcategories.find((l) => l.is_selected)
                  .verticalcategoryid
              : null,
            metrictype_id: this.state.metric_types.find((l) => l.is_selected)
              ? this.state.metric_types.find((l) => l.is_selected).metrictypeid
              : null,
            metric_id: this.state.metrics.find((l) => l.is_selected)
              ? this.state.metrics.find((l) => l.is_selected).metricid
              : null,
          };
          if (templateId === null) {
            //create
            TemplateServices.addTemplate(prepareObject)
              .then((res) => {
                this.saveTemplateSuccess();
              })
              .catch((error) => {
                Toast(
                  error && error.data && error.data.error
                    ? error.data.error
                    : constants.ERROR.SOMETHING_WENT_WRONG,
                  "error"
                );
              });
          } else {
            // TemplateServices.editTemplate(templateId, prepareObject).then((res) => {
            //   this.saveTemplateSuccess()
            // }).catch((error) => {
            //   Toast(error && error.data && error.data.error ? error.data.error : constants.ERROR.SOMETHING_WENT_WRONG, 'error')
            // })
          }
        }
      }
    );
  };

  saveTemplateSuccess = () => {
    let templateId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    Toast(
      templateId === null
        ? constants.TEMPLATE.CREATE.SUCCESS
        : constants.TEMPLATE.EDIT.SUCCESS,
      "success"
    );
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.LIST.ROUTE,
        {}
      ),
    });
  };

  dailogModalAccept = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
    });
  };

  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
    });
  };

  resetForm = () => {
    let templateId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    this.setState({
      ...this.state,
      formErrors: {},
      apiError: null,
    });
    if (templateId !== null) {
      this.prepopulateData(templateId);
    } else {
      this.setState(
        {
          ...initialState,
        },
        () => {
          this.getMasters();
        }
      );
    }
  };

  breadcrumbs = () => {
    let templateId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.DASHBOARD.ROUTE, {}),
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.TEMPLATE_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.LIST.NAME,
        link: formatRoute(
          constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.LIST.ROUTE,
          {}
        ),
        is_active: false,
      },
      {
        title: templateId
          ? constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.EDIT.NAME
          : constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.CREATE.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };
  changeRoute = () => {
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.LIST.ROUTE
      ),
    });
  };
  render() {
    let templateId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    return (
      <aside className="main_content">
        <div className="m-3 mb-0 bg-white px-3 br_8 main_content_height">
          <div className="manage-client">
            <div className="">
              <div className={"add-edit-form-card"}>
                <form className={"form-wrapper"}>
                  {this.state.apiError !== null && (
                    <Notification
                      color={"danger"}
                      message={this.state.apiError}
                    />
                  )}
                  <div className="card">
                    <div className="card-header title-cancel-header d-flex align-items-center">
                      <h3 className="text-darkblue2 mb-0 font-weight-bold">
                        {templateId === null
                          ? constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.CREATE
                              .HEADER_TITLE
                          : constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.EDIT
                              .HEADER_TITLE}
                      </h3>
                      <span
                        className="material-icons-round text-darkblue2 ml-auto cursor-pointer"
                        onClick={this.changeRoute}
                      >
                        close
                      </span>
                    </div>
                    <div className="card-body">
                      <div className="card-body-header">
                        <h5 className="mb-0 font-weight-semibold">LOB</h5>
                      </div>
                      <div className="form-field-wrapper">
                        <div className="row">
                          <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                            <div className={"row"}>
                              <div className="col-lg-6 col-sm-6 form-group position-relative">
                                <label for="status">
                                  Line of Business{" "}
                                  <span className={"mandatory"}>* </span>
                                </label>
                                <div id="lineofbusiness">
                                  <div
                                    className={`${
                                      this.state.formErrors.lobError
                                        ? "is-invalid"
                                        : ""
                                    } custom-combobox-list`}
                                  >
                                    <CustomDropdownList
                                      data={this.state.lobs}
                                      handleChange={this.handleMasterChange}
                                      placeholder={"Select Line of Business"}
                                      valueField={"id"}
                                      textField={"name"}
                                      selector="lobs"
                                    />
                                  </div>
                                  {this.state.formErrors.lobError && (
                                    <div
                                      id="invalid-tooltip"
                                      className="invalid-tooltip d-block"
                                    >
                                      {constants.FORM.MANDATORY_FIELD}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-6 col-sm-6 form-group position-relative">
                                <label for="status">LOB Subcategory</label>
                                <div
                                  className={`${
                                    this.state.formErrors.lobSubCategoryError
                                      ? "is-invalid"
                                      : ""
                                  } custom-combobox-list`}
                                >
                                  <CustomDropdownList
                                    data={this.state.lob_subcategories}
                                    handleChange={this.handleMasterChange}
                                    placeholder={"Select LOB Subcategory"}
                                    valueField={"lobsubcategoryid"}
                                    textField={"name"}
                                    selector="lob_subcategories"
                                  />
                                </div>
                                {this.state.formErrors.lobSubCategoryError && (
                                  <div
                                    id="invalid-tooltip"
                                    className="invalid-tooltip d-block"
                                  >
                                    {constants.FORM.MANDATORY_FIELD}
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-6 col-sm-6 form-group position-relative">
                                <label for="status">LOB Subcategory2</label>
                                <div
                                  className={`${
                                    this.state.formErrors.lobSubCategory2Error
                                      ? "is-invalid"
                                      : ""
                                  } custom-combobox-list`}
                                >
                                  <CustomDropdownList
                                    data={this.state.lob_subcategories_2}
                                    handleChange={this.handleMasterChange}
                                    placeholder={"Select LOB Subcategory2"}
                                    valueField={"lobsubcategoryid"}
                                    textField={"name"}
                                    selector="lob_subcategories_2"
                                  />
                                </div>
                                {this.state.formErrors.lobSubCategory2Error && (
                                  <div className="invalid-tooltip d-block">
                                    {constants.FORM.MANDATORY_FIELD}
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-6 col-sm-6 form-group position-relative">
                                <label for="status">LOB Subcategory3</label>
                                <div
                                  className={`${
                                    this.state.formErrors.lobSubCategory3Error
                                      ? "is-invalid"
                                      : ""
                                  } custom-combobox-list`}
                                >
                                  <CustomDropdownList
                                    data={this.state.lob_subcategories_3}
                                    handleChange={this.handleMasterChange}
                                    placeholder={"Select LOB Subcategory3"}
                                    valueField={"lobsubcategoryid"}
                                    textField={"name"}
                                    selector="lob_subcategories_3"
                                  />
                                </div>
                                {this.state.formErrors.lobSubCategory3Error && (
                                  <div className="invalid-tooltip d-block">
                                    {constants.FORM.MANDATORY_FIELD}
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-6 col-sm-6 form-group position-relative">
                                <label for="status">LOB Subcategory4</label>
                                <div
                                  className={`${
                                    this.state.formErrors.lobSubCategory4Error
                                      ? "is-invalid"
                                      : ""
                                  } custom-combobox-list`}
                                >
                                  <CustomDropdownList
                                    data={this.state.lob_subcategories_4}
                                    handleChange={this.handleMasterChange}
                                    placeholder={"Select LOB Subcategory4"}
                                    valueField={"lobsubcategoryid"}
                                    textField={"name"}
                                    selector="lob_subcategories_4"
                                  />
                                </div>
                                {this.state.formErrors.lobSubCategory4Error && (
                                  <div className="invalid-tooltip d-block">
                                    {constants.FORM.MANDATORY_FIELD}
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-6 col-sm-6 form-group position-relative">
                                <label for="status">LOB Subcategory5</label>
                                <div
                                  className={`${
                                    this.state.formErrors.lobSubCategory5Error
                                      ? "is-invalid"
                                      : ""
                                  } custom-combobox-list`}
                                >
                                  <CustomDropdownList
                                    data={this.state.lob_subcategories_5}
                                    handleChange={this.handleMasterChange}
                                    placeholder={"Select LOB Subcategory5"}
                                    valueField={"lobsubcategoryid"}
                                    textField={"name"}
                                    selector="lob_subcategories_5"
                                  />
                                </div>
                                {this.state.formErrors.lobSubCategory5Error && (
                                  <div className="invalid-tooltip d-block">
                                    {constants.FORM.MANDATORY_FIELD}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body-header">
                        <h5 className="mb-0 font-weight-semibold">Geography</h5>
                      </div>
                      <div className="form-field-wrapper">
                        <div className="row">
                          <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                            <div className={"row"}>
                              <div className="col-lg-6 col-sm-6 form-group position-relative">
                                <label for="status">
                                  Geography{" "}
                                  <span className={"mandatory"}>* </span>
                                </label>
                                <div id="geography">
                                  <div
                                    className={`${
                                      this.state.formErrors.geographyError
                                        ? "is-invalid"
                                        : ""
                                    } custom-combobox-list`}
                                  >
                                    <CustomDropdownList
                                      data={this.state.geographies}
                                      handleChange={this.handleMasterChange}
                                      placeholder={"Select Geography"}
                                      valueField={"geographyid"}
                                      textField={"name"}
                                      selector="geographies"
                                    />
                                  </div>
                                  {this.state.formErrors.geographyError && (
                                    <div className="invalid-tooltip d-block">
                                      {constants.FORM.MANDATORY_FIELD}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-6 col-sm-6 form-group position-relative">
                                <label for="status">Geo Category</label>
                                <div
                                  className={`${
                                    this.state.formErrors.regionError
                                      ? "is-invalid"
                                      : ""
                                  } custom-combobox-list`}
                                >
                                  <CustomDropdownList
                                    data={this.state.geo_subcategories}
                                    handleChange={this.handleMasterChange}
                                    placeholder={"Select Geo Category"}
                                    valueField={"geosubcategoryid"}
                                    textField={"name"}
                                    selector="geo_subcategories"
                                  />
                                </div>
                                {this.state.formErrors.regionError && (
                                  <div className="invalid-tooltip d-block">
                                    {constants.FORM.MANDATORY_FIELD}
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-6 col-sm-6 form-group position-relative">
                                <label for="status">Geo Sub-category</label>
                                <div
                                  className={`${
                                    this.state.formErrors.countryError
                                      ? "is-invalid"
                                      : ""
                                  } custom-combobox-list`}
                                >
                                  <CustomDropdownList
                                    data={this.state.geo_countries}
                                    handleChange={this.handleMasterChange}
                                    placeholder={"Select Sub-category"}
                                    valueField={"countryid"}
                                    textField={"name"}
                                    selector="geo_countries"
                                  />
                                </div>
                                {this.state.formErrors.countryError && (
                                  <div className="invalid-tooltip d-block">
                                    {constants.FORM.MANDATORY_FIELD}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body-header">
                        <h5 className="mb-0 font-weight-semibold">Vertical</h5>
                      </div>
                      <div className="form-field-wrapper">
                        <div className="row">
                          <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                            <div className={"row"}>
                              <div className="col-lg-6 col-sm-6 form-group position-relative">
                                <label for="status">
                                  Vertical{" "}
                                  <span className={"mandatory"}>* </span>
                                </label>
                                <div id="vertical">
                                  <div
                                    className={`${
                                      this.state.formErrors.verticalError
                                        ? "is-invalid"
                                        : ""
                                    } custom-combobox-list`}
                                  >
                                    <CustomDropdownList
                                      data={this.state.verticals}
                                      handleChange={this.handleMasterChange}
                                      placeholder={"Select Vertical"}
                                      valueField={"verticalid"}
                                      textField={"name"}
                                      selector="verticals"
                                    />
                                  </div>
                                  {this.state.formErrors.verticalError && (
                                    <div className="invalid-tooltip d-block">
                                      {constants.FORM.MANDATORY_FIELD}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-6 col-sm-6 form-group position-relative">
                                <label for="status">Vertical Category</label>
                                <div
                                  className={`${
                                    this.state.formErrors.verticalCategoryError
                                      ? "is-invalid"
                                      : ""
                                  } custom-combobox-list`}
                                >
                                  <CustomDropdownList
                                    data={this.state.vertical_categories}
                                    handleChange={this.handleMasterChange}
                                    placeholder={"Select Vertical Category"}
                                    valueField={"verticalcategoryid"}
                                    textField={"name"}
                                    selector="vertical_categories"
                                  />
                                </div>
                                {this.state.formErrors
                                  .verticalCategoryError && (
                                  <div className="invalid-tooltip d-block">
                                    {constants.FORM.MANDATORY_FIELD}
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-6 col-sm-6 form-group position-relative">
                                <label for="status">
                                  Vertical Sub-category
                                </label>
                                <div
                                  className={`${
                                    this.state.formErrors
                                      .verticalSubCategoryError
                                      ? "is-invalid"
                                      : ""
                                  } custom-combobox-list`}
                                >
                                  <CustomDropdownList
                                    data={this.state.vertical_subcategories}
                                    handleChange={this.handleMasterChange}
                                    placeholder={"Select Vertical Sub-category"}
                                    valueField={"verticalcategoryid"}
                                    textField={"name"}
                                    selector="vertical_subcategories"
                                  />
                                </div>
                                {this.state.formErrors
                                  .verticalSubCategoryError && (
                                  <div className="invalid-tooltip d-block">
                                    {constants.FORM.MANDATORY_FIELD}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body-header">
                        <h5 className="mb-0 font-weight-semibold">Metric</h5>
                      </div>
                      <div className="form-field-wrapper">
                        <div className="row">
                          <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                            <div className={"row"}>
                              <div className="col-lg-6 col-sm-6 form-group position-relative">
                                <label for="status">
                                  Metric Type{" "}
                                  <span className={"mandatory"}>* </span>
                                </label>
                                <div id="metrictype">
                                  <div
                                    className={`${
                                      this.state.formErrors.metricTypeError
                                        ? "is-invalid"
                                        : ""
                                    } custom-combobox-list`}
                                  >
                                    <CustomDropdownList
                                      data={this.state.metric_types}
                                      handleChange={this.handleMasterChange}
                                      placeholder={"Select Metric Type"}
                                      valueField={"metrictypeid"}
                                      textField={"name"}
                                      selector="metric_types"
                                    />
                                  </div>
                                  {this.state.formErrors.metricTypeError && (
                                    <div className="invalid-tooltip d-block">
                                      {constants.FORM.MANDATORY_FIELD}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-6 col-sm-6 form-group position-relative">
                                <label for="status">
                                  Metric <span className={"mandatory"}>* </span>
                                </label>
                                <div id="metric">
                                  <div
                                    className={`${
                                      this.state.formErrors.metricError
                                        ? "is-invalid"
                                        : ""
                                    } custom-combobox-list`}
                                  >
                                    <CustomDropdownList
                                      data={this.state.metrics}
                                      handleChange={this.handleMasterChange}
                                      placeholder={"Select Metric"}
                                      valueField={"metricid"}
                                      textField={"name"}
                                      selector="metrics"
                                    />
                                  </div>
                                  {this.state.formErrors.metricError && (
                                    <div className="invalid-tooltip d-block">
                                      {constants.FORM.MANDATORY_FIELD}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-submit-buttons">
                        <div className={"row"}>
                          <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                            <div className="text-right form-buttons">
                              <button
                                type="button"
                                className="btn btn-outline-primary modal-btn"
                                onClick={this.resetForm}
                              >
                                Reset
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary modal-btn"
                                onClick={this.handleSubmit}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <DailogNew
          isOpen={this.state.dailogModal}
          accept={this.dailogModalAccept}
          decline={this.dailogModalDecline}
          header={this.state.dailogModalHeader}
          content={this.state.dailogModalContent}
          config={this.state.dailogModalConfig}
          modalStyleType={this.state.dailogModalStyleType}
        />
      </aside>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setSegments: (segmentsList) => {
      dispatch(segments(segmentsList));
    },
    setCurrencies: (currenciesList) => {
      dispatch(getCurrencies(currenciesList));
    },
    setTbriUsers: (tbriUsers) => {
      dispatch(getTbriUsers(tbriUsers));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageTemplate);
