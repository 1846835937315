const ellipsisName = (displayName) => {
  if (displayName === null) {
    return null;
  }
  let result;
  if (displayName.length < 8) {
    result = displayName;
  } else {
    result = displayName.slice(0, 8) + "...";
  }
  return result;
};

export default ellipsisName;
