import React from "react";
import CommonListMetricRecoredsV2 from "./CommonListMetricRecordsV2";

function ListMetricRecordsV2Details(props) {
  return (
    <CommonListMetricRecoredsV2 {...props} isPreviewer={true} company="" />
  );
}

export default ListMetricRecordsV2Details;
