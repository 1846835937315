import momentWithZone from "moment-timezone";
import moment from "moment";
function ComapnyDetailsDateFormatter(date, showTimeZone = true) {
  let userTimezone = moment.tz.guess();
  let userTimezoneShortCode = moment().tz(userTimezone).zoneAbbr();
  return `${momentWithZone(date).tz(userTimezone).format("MMMM DD, YYYY")} ${
    showTimeZone ? userTimezoneShortCode : ""
  }`;
}

export default ComapnyDetailsDateFormatter;
