import decodeJWT from "./decodeJWT";
const permissionCheck = (type = "permissions", entity, action) => {
  let access = false;
  let decodedResponse = decodeJWT(localStorage.getItem("token"));
  let permission = decodedResponse[type] ? decodedResponse[type] : {};
  for (let key in permission) {
    if (key === entity) {
      if (permission[key].includes(action)) {
        access = true;
      }
    }
  }
  return access;
};

export default permissionCheck;
