import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import MetricCategoryTreeView from "./MetricCategoryTreeView";
import Toast from "../../Utils/Toast";
import "./CreateMetricsRule.css";

function SegmentGroupForm({
  breadcrumbsConfig,
  fetchCategoryData,
  handleSubmit,
  initialData = { groupName: "", lobCheckedIds: [] },
}) {
  const [lobsTree, setLobTree] = useState([]);
  const [lobsCheckedIds, setLobCheckedIds] = useState(
    initialData.lobCheckedIds || []
  );
  const [lobsExpandIds, setLobExpandIds] = useState(
    initialData.lobCheckedIds || []
  );
  const [groupName, setGroupName] = useState(initialData.groupName || "");
  function buildHierarchy(data, parentId = null) {
    const children = data.filter((item) => item.parent_id === parentId);

    if (children.length === 0) {
      return [];
    }

    const result = children.map((child) => {
      const childNode = {
        ...child,
        isExpand: true,
        checked: true,
        label: child.name,
        value: child.id,
        children: buildHierarchy(data, child.id),
      };

      return childNode;
    });
    return result;
  }

  useEffect(() => {
    fetchCategoryData().then((lobTree) => setLobTree(buildHierarchy(lobTree)));
  }, [fetchCategoryData]);

  const breadcrumbs = () => breadcrumbsConfig;

  return (
    <aside className="main_content">
      <div className="m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height">
        <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0">
          <CustomBreadcrumb breadcrumbs={breadcrumbs()} />
        </div>
        <div className="rule-input-div">
          <input
            placeholder="Enter group name"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
        </div>
        <div style={{ display: "flex", gap: "20px", marginTop: "16px" }}>
          <MetricCategoryTreeView
            treeData={lobsTree}
            setTreeData={setLobTree}
            checkedIds={lobsCheckedIds}
            setCheckedIds={setLobCheckedIds}
            expandedIds={lobsExpandIds}
            setExpandIds={setLobExpandIds}
            title="LOB's"
            groups={true}
          />
        </div>
        <div className="create-btn-div">
          <button
            className={
              "btn btn-custom btn-primary icon-text-btn title-btn d-inline-flex align-items-center mb-3"
            }
            type="button"
            onClick={() =>
              handleSubmit({ groupName, lobCheckedIds: lobsCheckedIds })
            }
            disabled={!groupName.trim() || !lobsCheckedIds.length}
          >
            Save Group
          </button>
        </div>
      </div>
    </aside>
  );
}

export default SegmentGroupForm;
