import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import constants from "../../Utils/constants";
import { formatRoute } from "react-router-named-routes";
import { MasterServices } from "../../Services/Master";
import DynamicTableComponentNew from "../../Components/DynamicTableComponentsNew";
import { DropdownList } from "react-widgets";
import AclAction from "../../Components/ACL/AclAction";
import Toast from "../../Utils/Toast";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

function CommonListMetricRecoredsV2(props) {
  const [columns, setColumns] = useState([]);
  let id = props.match.params.id;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [rowData, setRowData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [tableChange, setTableChange] = useState(true);
  const [allCompany, setAllCompany] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(props.company);
  const history = useHistory();
  const userData = useSelector((state) => state.home.loginUserSuccess);
  const adminRoleId =
    (userData.AdminRoles || []).find(
      (role) => role.short_name === "admin" && role.id === userData.role_id
    )?.id || "";
  const [showButton, setShowButton] = useState(false);

  const breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.DOWNLOAD.LIST.NAME,
        link: {
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.DOWNLOAD.LIST.ROUTE,
            {}
          ),
          search: "?selected_tab=metricV2",
        },
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.METRIC_TRANSACTION.LIST.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  const fetchTemplateRecored = async () => {
    try {
      let url = `metric_template/record/${id}?company=${encodeURIComponent(
        selectedCompany
      )}&page=${page}&pageSize=${pageSize}`;
      let recordRes = await MasterServices.getTemplateRecord(url);

      let recordData = recordRes.data.data;
      if (recordData.length > 0) {
        let columnKeys = Object.keys(recordData[0]);
        let columns = columnKeys.map((item) => {
          return {
            id: item,
            title: item,
            sortable: false,
            headerStyle: { textTransform: "capitalize", textAlign: "left" },
            rowStyle: {
              // color: "#99a0aa",
              textTransform: "capitalize",
              // textAlign: "left",
            },
            headerClass: "w80",
            rowClass: "w80",
            visibility: true,
          };
        });
        setColumns(columns);
        setRowData(recordRes.data.data);
        setPage(recordRes.data.page);
        setPageSize(recordRes.data.pageSize);
        setTotalCount(recordRes.data.total);
        setAllCompany(recordRes.data.allCompany);
        setSelectedCompany(recordRes.data.selectedCompany || "All");
        setTableChange(false);
        let companyData = recordRes.data?.transactionData?.find(
          (item) => recordRes.data.selectedCompany === item.company_data.name
        );
        setShowButton(companyData?.state === "to_verify" ? true : false);
      }
    } catch (error) {
      Toast(
        error && error.data && error.data.error
          ? error.data.error
          : constants.ERROR.SOMETHING_WENT_WRONG,
        "error"
      );
    }
  };

  const handlePageSize = (e) => {
    setPageSize(e.target.value);
    setPage(1);
    setTableChange(true);
  };
  const pagePrevious = () => {
    setPage(page - 1);
    setTableChange(true);
  };
  const pageNext = () => {
    setPage(page + 1);
    setTableChange(true);
  };

  const handlePage = (e) => {
    setPage(e);
    setTableChange(true);
  };

  const handleCompany = (e) => {
    setSelectedCompany(e);
    setPage(1);
    setTableChange(true);
  };

  const handleReject = async () => {
    try {
      console.log("reject");
      let input = {
        companyName: selectedCompany,
        id: id,
      };
      await MasterServices.rejectTransactions(input);
      history.push("/file/list?selected_tab=metricV2");
      Toast("Quantdata Rejected Successfully", "success");
    } catch (error) {
      Toast(
        error && error.data && error.data.error
          ? error.data.error
          : constants.ERROR.SOMETHING_WENT_WRONG,
        "error"
      );
    }
  };

  const handleApprove = async () => {
    try {
      let input = {
        companyName: selectedCompany,
        id: id,
      };
      await MasterServices.approveTransactions(input);
      Toast("Quantdata Approved Successfully", "success");
      history.push("/file/list?selected_tab=metricV2");
    } catch (error) {
      Toast(
        error && error.data && error.data.error
          ? error.data.error
          : constants.ERROR.SOMETHING_WENT_WRONG,
        "error"
      );
    }
  };

  useEffect(() => {
    if (tableChange) {
      fetchTemplateRecored();
    }
  }, [tableChange]);

  return (
    <aside className="main_content">
      <div className="m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height">
        <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0">
          <CustomBreadcrumb breadcrumbs={breadcrumbs()} />
        </div>
        <div className="d-flex align-items-center flex-wrap">
          <h3 className="text-black2 font-weight-bold mb-3">
            {constants.APPLICATION_ROUTE.METRIC_RECORD_V2.LIST.HEADER_TITLE}
          </h3>
        </div>
        {props.isPreviewer && adminRoleId && (
          <div className="d-flex" style={{ marginBottom: "10px", gap: "10px" }}>
            <DropdownList
              value={selectedCompany}
              data={allCompany}
              style={{ width: "200px" }}
              onChange={(e) => handleCompany(e)}
              // filter='contains'
            />
            {showButton && (
              <>
                <AclAction
                  type={constants.MANAGE_CONTENT_PERMISSIONS.KEY}
                  entity={constants.MANAGE_CONTENT_PERMISSIONS.ENTITY}
                  action={
                    constants.MANAGE_CONTENT_PERMISSIONS.COMPANY_METRIC.REJECT
                  }
                >
                  <button
                    tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                    className={
                      "btn btn-custom btn-danger icon-text-btn title-btn d-inline-flex align-items-center " +
                      constants.MANAGE_CONTENT_PERMISSIONS.BENCHMARK_REJECT
                        .BUTTON_CLASS
                    }
                    type="button"
                    onClick={handleReject}
                  >
                    {" " +
                      constants.MANAGE_CONTENT_PERMISSIONS.BENCHMARK_REJECT
                        .BUTTON_TITLE}
                    <span className="material-icons-round icon-fs-16 ml-1">
                      clear
                    </span>
                  </button>
                </AclAction>
                <AclAction
                  type={constants.MANAGE_CONTENT_PERMISSIONS.KEY}
                  entity={constants.MANAGE_CONTENT_PERMISSIONS.ENTITY}
                  action={
                    constants.MANAGE_CONTENT_PERMISSIONS.COMPANY_METRIC.APPROVE
                  }
                >
                  <button
                    tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                    className={
                      "btn btn-custom btn-primary icon-text-btn title-btn d-inline-flex align-items-center " +
                      constants.MANAGE_CONTENT_PERMISSIONS.BENCHMARK_APPROVE
                        .BUTTON_CLASS
                    }
                    type="button"
                    onClick={handleApprove}
                  >
                    {" " +
                      constants.MANAGE_CONTENT_PERMISSIONS.BENCHMARK_APPROVE
                        .BUTTON_TITLE}
                    <span className="material-icons-outlined icon-fs-16 ml-1">
                      done_all
                    </span>
                  </button>
                </AclAction>
              </>
            )}
          </div>
        )}

        {/* <div className="d-flex align-items-center mb-16px">
      <div className="min-width-266 border-search search-box-shadow-2">
        <Search
          handleSearch={this.handleSearch}
          handleInput={this.handleInput}
          value={this.state.name}
          placeHolder={"Filter Name"}
        />
      </div>
    </div> */}
        <div className="custom-table-block custom-table-block-2 nowrap-table-block th-nowrap sort-table-block center-th-align">
          <DynamicTableComponentNew
            columnData={columns}
            rowData={rowData}
            pagePrevious={pagePrevious}
            pageNext={pageNext}
            pageSize={pageSize}
            pageNo={page}
            handlePage={handlePage}
            // handleSort={handleSort}
            // sortColumn={state.sortColumn}
            // sortOrder={state.sortOrder}
            count={totalCount}
            // rowAction={rowAction}
            // colSpan={tableHeader.filter((h) => h.visibility).length}
            // showActions={showActions}
            // className={`full-grid`}
            showPagination={true}
            paginationClass={"center-pagination custom-pagination-new"}
            handlePageSize={handlePageSize}
          />
        </div>
        {/* Tooltips for icons in table, don't delete */}
        {/* {this.state.rowData.map((data) => {
      return (
        <>
          <CustomReactTooltip
            id={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.TEMPLATE.LIST
                .REMOVE_TEMPLATE_TOOLTIP
            )}-${data.id}`}
            multiline={true}
          />
        </>
      );
    })}
    <DailogNew
      isOpen={this.state.dailogModal}
      accept={this.dailogModalAccept}
      decline={this.dailogModalDecline}
      header={this.state.dailogModalHeader}
      content={this.state.dailogModalContent}
      config={this.state.dailogModalConfig}
    /> */}
      </div>
    </aside>
  );
}

export default CommonListMetricRecoredsV2;
