import React from "react";
import SegmentGroupForm from "./SegmentGroupForm";
import constants from "../../Utils/constants";
import { MasterServices } from "../../Services/Master";
import { useHistory } from "react-router-dom";
import Toast from "../../Utils/Toast";

function CreateSegmentGroup() {
  const history = useHistory();

  const fetchCategoryData = async () => {
    const res = await MasterServices.getCategories(
      `metric_category?metric_category_type_id=1`
    );
    const lobRes = res.data.data;
    // Build hierarchy logic
    return lobRes; // Replace with the processed lobTree
  };

  const handleAddGroupTemplate = async ({ groupName, lobCheckedIds }) => {
    try {
      await MasterServices.addMetricGroup({
        group_name: groupName,
        lob_ids: JSON.stringify(lobCheckedIds),
      });
      Toast(
        constants.APPLICATION_ROUTE.SEGMENT_GROUP_TEMPLATE.SUCCESS.ADD,
        "success"
      );
      history.push(
        constants.APPLICATION_ROUTE.SEGMENT_GROUP_TEMPLATE.LIST.ROUTE
      );
    } catch (e) {
      Toast(e.data.error, "error");
    }
  };

  const breadcrumbsConfig = [
    {
      title:
        constants.APPLICATION_ROUTE.SEGMENT_GROUP_TEMPLATE.LIST.HEADER_TITLE,
      link: constants.APPLICATION_ROUTE.SEGMENT_GROUP_TEMPLATE.LIST.ROUTE,
      is_active: false,
    },
    {
      title: constants.APPLICATION_ROUTE.SEGMENT_GROUP_TEMPLATE.CREATE.NAME,
      link: null,
      is_active: true,
    },
  ];

  return (
    <SegmentGroupForm
      breadcrumbsConfig={breadcrumbsConfig}
      fetchCategoryData={fetchCategoryData}
      handleSubmit={handleAddGroupTemplate}
    />
  );
}

export default CreateSegmentGroup;
