import React, { Component } from "react";
import { connect } from "react-redux";
import { categories, companyContent } from "../Store/Actions/CompanyDetails";
import Slugify from "../Utils/Slugify";
import Collapsible from "react-collapsible";
class CompanyDetailsCategories extends Component {
  render() {
    let companies =
      this.props.company_details && this.props.company_details.companies
        ? this.props.company_details.companies
        : [];
    let selectedCompany = companies.filter((row) => row.is_selected === true);
    let subCategories_with_templates = this.props.company_details.templates
      .filter((d) => d.is_default == 0)
      .map((template) => template.subcategory_id);
    let categories_with_content = [];
    for (let j = 0; j < this.props.company_details.categories.length; j++) {
      for (
        let i = 0;
        i < this.props.company_details.categories[j].subcategories.length;
        i++
      ) {
        if (
          subCategories_with_templates.includes(
            this.props.company_details.categories[j].subcategories[i].id
          )
        ) {
          categories_with_content.push(
            this.props.company_details.categories[j].id
          );
          break;
        }
      }
    }
    return (
      this.props.company_details &&
      this.props.company_details.categories &&
      this.props.company_details.categories.length > 0 &&
      companies.filter((row) => row.is_selected === true).length > 0 &&
      selectedCompany[0].segments.length > 0 && (
        <aside className="inner-sidebar">
          <div className="sidebar_nav2 d-flex flex-column h-100">
            <div className="d-flex expand-collapse-icon-wrapper">
              <span
                className="material-icons-outlined expand-collapse-icon"
                onClick={this.props.toggleInnerSidebar}
              >
                keyboard_double_arrow_left
              </span>
            </div>
            <div className="opened-innersidebar-content">
              <div className="custom-collapser mt-1 inner-sidebar-collapser">
                {this.props.company_details.categories
                  .filter(
                    (removed_unmatched) => removed_unmatched.is_default === 0
                  )
                  .map((category, index) => (
                    <div className="mb-1" key={category.id}>
                      {companyContent.length > 0 ? (
                        <div className="custom-collapser-item custom-collapser-2-item">
                          <Collapsible
                            open={true}
                            trigger={
                              <>
                                <div className="d-flex align-items-center ml-auto collapse-icon">
                                  <span className="material-icons-outlined material-icon">
                                    chevron_right
                                  </span>
                                </div>
                              </>
                            }
                            triggerSibling={() => (
                              <div className="d-flex align-items-center trigger-block add-remove-item">
                                <input
                                  type="checkbox"
                                  className="custom-checkbox-new"
                                  name={category.name}
                                  id={Slugify(category.name)}
                                  checked={category.is_selected}
                                  onChange={(e) => {
                                    if (this.props.isEditable) {
                                      e.stopPropagation();
                                      this.props.toggleCheckbox(
                                        e,
                                        "category",
                                        category.id
                                      );
                                    }
                                  }}
                                  disabled={
                                    !categories_with_content.includes(
                                      category.id
                                    )
                                  }
                                />
                                <label
                                  className=" mb-0 title"
                                  htmlFor={Slugify(category.name)}
                                >
                                  <span className="ellipsis">
                                    {category.name}
                                  </span>
                                </label>
                                {this.props.isEditable && (
                                  <span className="add-remove-icon"></span>
                                )}
                              </div>
                            )}
                          >
                            {category.subcategories.map(
                              (sub_category, index_sub_cat) => (
                                <div className="collapse-panel">
                                  <div
                                    className="collapse-panel-content"
                                    key={category.id}
                                  >
                                    <div
                                      className="item d-flex align-items-center add-remove-item"
                                      key={sub_category.id}
                                    >
                                      <input
                                        className="custom-checkbox-new"
                                        key={sub_category.id}
                                        type="checkbox"
                                        name={sub_category.name}
                                        id={
                                          Slugify(category.name) +
                                          "_" +
                                          Slugify(sub_category.name)
                                        }
                                        checked={
                                          subCategories_with_templates.includes(
                                            sub_category.id
                                          ) && sub_category.is_selected
                                        }
                                        onChange={(e) => {
                                          if (this.props.isEditable) {
                                            this.props.toggleCheckbox(
                                              e,
                                              "sub_category",
                                              category.id,
                                              sub_category.id
                                            );
                                          }
                                        }}
                                        disabled={
                                          !subCategories_with_templates.includes(
                                            sub_category.id
                                          )
                                        }
                                      />
                                      <label
                                        className={`${
                                          category.is_selected
                                            ? "selected"
                                            : "non-selected"
                                        } sub-category-label mb-0`}
                                        htmlFor={
                                          Slugify(category.name) +
                                          "_" +
                                          Slugify(sub_category.name)
                                        }
                                      >
                                        <span className="ellipsis">
                                          {sub_category.name}
                                        </span>
                                      </label>
                                      {this.props.isEditable && (
                                        <span className="add-remove-icon"></span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </Collapsible>
                        </div>
                      ) : (
                        <div className="content text-center mt-1">
                          No Content
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <div className="closed-innersidebar-content d-flex flex-grow-1">
              <div className="content text-center font-weight-semibold">
                CONTENTS
              </div>
            </div>
          </div>
        </aside>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company_details: state.company_details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    categories: (payload) => {
      dispatch(categories(payload));
    },
    companyContent: (payload) => {
      dispatch(companyContent(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyDetailsCategories);
