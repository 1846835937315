import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import constants from "../../Utils/constants";
import MetricCategoryTreeView from "./MetricCategoryTreeView";
import { MasterServices } from "../../Services/Master";
import "./CreateMetricsRule.css";
import { useHistory } from "react-router-dom";
import Toast from "../../Utils/Toast";
import { formatRoute } from "react-router-named-routes";

function CreateMetricsRules() {
  const history = useHistory();

  const [lobsTree, setLobTree] = useState([]);
  const [geosTree, setGeoTree] = useState([]);
  const [verticalsTree, setVerticalTree] = useState([]);
  const [metricsTree, setMetricTree] = useState([]);
  const [lobsCheckedIds, setLobCheckedIds] = useState([]);
  const [geosCheckedIds, setGeoCheckedIds] = useState([]);
  const [verticalsCheckedIds, setVerticalCheckedIds] = useState([]);
  const [metricsCheckedIds, setMetricCheckedIds] = useState([]);
  const [lobsExpandIds, setLobExpandIds] = useState([]);
  const [geosExpandIds, setGeoExpandIds] = useState([]);
  const [verticalsExpandIds, setVerticalExpandIds] = useState([]);
  const [metricsExpandIds, setMetricExpandIds] = useState([]);
  const [ruleName, setRuleName] = useState("");

  function buildHierarchy(data, parentId = null) {
    const children = data.filter((item) => item.parent_id === parentId);

    if (children.length === 0) {
      return [];
    }

    const result = children.map((child) => {
      const childNode = {
        ...child,
        isExpand: true,
        checked: true,
        label: child.name,
        value: child.id,
        children: buildHierarchy(data, child.id),
      };
      return childNode;
    });

    return result;
  }

  const breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.TEMPLATE_GROUP.NAME,
        link: constants.APPLICATION_ROUTE.TEMPLATE.LIST.ROUTE,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.TEMPLATE.CREATE.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  const fetchCategoryData = () => {
    // Fetch categories using MasterServices
    MasterServices.getCategories(`metric_category`).then((res) => {
      // Process the response data
      let lobRes = res.data.data;

      let childrenTree = buildHierarchy(lobRes);

      let lobTree = childrenTree.filter(
        (item) => item.metric_category_type_id === 1
      );
      let geoTree = childrenTree.filter(
        (item) => item.metric_category_type_id === 2
      );
      let verticalTree = childrenTree.filter(
        (item) => item.metric_category_type_id === 3
      );

      setLobTree(lobTree);
      setGeoTree(geoTree.filter((item) => item.name !== "global"));
      setVerticalTree(
        verticalTree.filter((item) => item.name !== "all industries")
      );
    });
  };

  const fetchMetricsData = () => {
    MasterServices.getMetrics("metrics_v2").then((res) => {
      let lobRes = res.data.data;
      let childrenTree = buildHierarchy(lobRes);
      setMetricTree(childrenTree);
    });
  };
  function getCheckedObjs(data) {
    let checkedObjects = [];

    function traverseAndFilter(arr) {
      for (let item of arr) {
        if (item.checked) {
          let id = item.id;
          let name = item.name;
          checkedObjects.push({ id, name });
        }
        if (item.children && item.children.length > 0) {
          traverseAndFilter(item.children);
        }
      }
    }

    traverseAndFilter(data);

    return checkedObjects;
  }

  const handleCreate = () => {
    let lobs = lobsCheckedIds;
    let geos = geosCheckedIds;
    let verticals = verticalsCheckedIds;
    let metrics = metricsCheckedIds;

    let input = {
      rule_name: ruleName,
      lobs,
      geos,
      verticals,
      metrics,
    };

    MasterServices.addMetricRule(input)
      .then((res) => {
        let resId = res.data.id;
        history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.TEMPLATE_METRICS_VIEW.ROUTE,
            { id: resId }
          ),
        });
        Toast(
          constants.APPLICATION_ROUTE.TEMPLATE_METRICS_VIEW.SUCCESS,
          "success"
        );
        history.push("/template");
      })
      .catch((e) => {
        Toast(e.data.error, "error");
      });
  };

  useEffect(() => {
    fetchCategoryData();
    fetchMetricsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <aside className="main_content">
      <div className="m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height">
        <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0">
          <CustomBreadcrumb breadcrumbs={breadcrumbs()} />
        </div>
        <div className="rule-input-div">
          <input
            placeholder="Enter rule name"
            value={ruleName}
            onChange={(e) => {
              setRuleName(e.target.value);
            }}
          />
        </div>
        <div style={{ display: "flex", gap: "20px", marginTop: "16px" }}>
          <MetricCategoryTreeView
            treeData={lobsTree}
            setTreeData={setLobTree}
            checkedIds={lobsCheckedIds}
            setCheckedIds={setLobCheckedIds}
            expandedIds={lobsExpandIds}
            setExpandIds={setLobExpandIds}
            title="LOB's"
          />
          <MetricCategoryTreeView
            treeData={verticalsTree}
            setTreeData={setVerticalTree}
            checkedIds={verticalsCheckedIds}
            setCheckedIds={setVerticalCheckedIds}
            expandedIds={verticalsExpandIds}
            setExpandIds={setVerticalExpandIds}
            title="Verticals"
          />
          <MetricCategoryTreeView
            treeData={geosTree}
            setTreeData={setGeoTree}
            title="Geography"
            checkedIds={geosCheckedIds}
            setCheckedIds={setGeoCheckedIds}
            expandedIds={geosExpandIds}
            setExpandIds={setGeoExpandIds}
          />
          <MetricCategoryTreeView
            treeData={metricsTree}
            setTreeData={setMetricTree}
            title="Metric and Type"
            checkedIds={metricsCheckedIds}
            setCheckedIds={setMetricCheckedIds}
            expandedIds={metricsExpandIds}
            setExpandIds={setMetricExpandIds}
            isMetrics={true}
          />
        </div>
        <div className="create-btn-div">
          <button
            className={
              "btn btn-custom btn-primary icon-text-btn title-btn d-inline-flex align-items-center mb-3"
            }
            type="button"
            onClick={handleCreate}
            disabled={
              !ruleName.length ||
              !lobsCheckedIds.length ||
              !metricsCheckedIds.length ||
              ruleName.trim() === ""
            }
          >
            Create Rule
          </button>
        </div>
      </div>
    </aside>
  );
}

export default CreateMetricsRules;
