import constants from "../Utils/constants";
import _ from "lodash";

function DocumentTitle(title) {
  if (title != null && title)
    document.title = `${constants.SITE.TITLE} ${
      title ? ` | ${_.capitalize(title)}` : null
    }`;
}

export default DocumentTitle;
