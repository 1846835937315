import { Axios } from "../lib/axios";
export const QuoteImageServices = {
  addImageandQuote,
  updateImageandQuote,
  showImageandQuote,
};
function addImageandQuote(body) {
  return Axios.post("/content-quote", body);
}
function updateImageandQuote(id, transaction_id, body) {
  return Axios.patch(`content-quote/${id}/${transaction_id}`, body);
}
function showImageandQuote(mapping_id, transaction_id) {
  return Axios.get(`content-quote/${mapping_id}/${transaction_id}`);
}
