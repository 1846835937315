import { combineReducers } from "redux";
import * as DETAILS_REF from "./reducerReference/customerStudyReference";

const customerStudyCategories = DETAILS_REF.customerStudyCategories;
const customerStudySegments = DETAILS_REF.customerStudySegments;
const customerStudyDetails = DETAILS_REF.customerStudyDetails;
const categories = DETAILS_REF.categories;
const customerStudyContent = DETAILS_REF.customerStudyContent;
const customerStudyTemplates = DETAILS_REF.customerStudyTemplates;
const customerStudyTransactionDetails =
  DETAILS_REF.customerStudyTransactionDetails;

const customerStudyDetailsReducer = combineReducers({
  customerStudyCategories,
  customerStudySegments,
  customerStudyDetails,
  categories,
  customerStudyContent,
  customerStudyTemplates,
  customerStudyTransactionDetails,
});

export default customerStudyDetailsReducer;
