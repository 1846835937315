import React, { Component } from "react";
import constants from "../Utils/constants";
import XLSX from "xlsx";

class CustomPPTUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doc: this.props.value && this.props.value.name ? this.props.name : "",
      uploadFlag: this.props.value && this.props.value.name ? true : false,
      docError: false,
      preFilledLogo: false,
    };
  }

  uploadDocument = (doc) => {
    let allowedExtensions =
      this.props.type === "ppt"
        ? /(\.ppt|\.pptx)$/i
        : /(\.xls|\.xlsx|\.xlsm|\.ppt|\.pptx|\.pdf|\.doc|\.docx|)$/i;
    try {
      if (doc[0] && doc[0].name && allowedExtensions.exec(doc[0].name)) {
        let reader = new FileReader();
        if (
          this.props.isExistingUsers ||
          doc[0].size < constants.COMPANY_DETAILS.MAX_PPT_SIZE
        ) {
          if (this.props.convertToJson) {
            let excelFile = doc[0];
            reader.readAsBinaryString(excelFile);
            reader.onload = (event) => {
              let data = event.target.result;
              var workbook = XLSX.read(data, {
                type: "binary",
              });
              /* convert from workbook to array of arrays */
              var first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
              var ArrayOfObjects =
                XLSX.utils.sheet_to_row_object_array(first_worksheet);

              this.props.onChangeHandler(doc[0].name, ArrayOfObjects);
            };
            reader.onerror = (event) => {
              this.setState({ docError: true });
            };
          } else {
            reader.readAsDataURL(doc[0]);
            reader.onloadend = (e) => {
              this.setState(
                {
                  image: reader.result,
                  uploadFlag: true,
                  docError: false,
                },
                () => {
                  this.props.onChangeHandler(doc[0].name, reader.result);
                }
              );
            };
          }
        } else {
          this.setState({ docError: true });
        }
      } else {
        this.setState({ docError: true });
      }
    } catch (e) {}
  };

  removeDocument = () => {
    this.setState(
      { doc: constants.COMPANY_DETAILS.NO_IMAGE_FOUND_PATH, uploadFlag: false },
      () => {
        this.props.onChangeHandler("", "");
      }
    );
  };

  render() {
    let temp = this.props.value ? this.props.value : {};
    return (
      <div className={"row"}>
        <div className={"col-sm-12"}>
          {temp.name && (
            <div>
              <span
                className={`document-name text-center d-inline-flex align-items-center`}
              >
                <span>{temp.name ? temp.name : ""}</span>
                <span
                  className="material-icons-outlined text-grey icon-fs-16 ml-2 cursor-pointer"
                  onClick={() => this.removeDocument()}
                >
                  delete
                </span>
              </span>
            </div>
          )}
          <input
            type="file"
            accept={`${
              this.props.type === "ppt"
                ? ".ppt, .pptx"
                : ".xls, .xlsx, .xlsm, .doc, .docx, .pdf, .pptx, .ppt"
            }`}
            ref={(ref) => (this.upload = ref)}
            style={{ display: "none" }}
            onChange={() => this.uploadDocument(this.upload.files)}
            onClick={(event) => {
              event.target.value = null;
            }}
          />
        </div>
        <div className={"col-sm-12 upload-buttons"}>
          <button
            className={
              "btn btn-primary d-inline-flex align-items-center icon-text-primary-btn"
            }
            type="button"
            disabled={this.state.uploadFlag}
            onClick={() => this.upload.click()}
          >
            <span className="material-icons-round icon-fs-16 mr-1">search</span>
            {"Browse"}
          </button>
        </div>
      </div>
    );
  }
}

export default CustomPPTUploader;
