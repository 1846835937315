import React, { useState } from "react";
import CustomDigestButton from "../Components/CustomDigestButton";
import { Button } from "reactstrap";

const PreferenceCenterContent = ({
  notificationItems,
  selectAllCheckBoxes,
  handleCheckbox,
  selectedBox,
  frequencyType,
  setFrequencyType,
  frequency,
  setFrequency,
  saveUserPreferences,
  mouseScroll,
  clearTheNotifications,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const saveModalFrequency = () => {
    setFrequencyType("digest");
    setOpenModal(!openModal);
  };
  const closeModal = () => {
    // setFrequencyType("immediate");
    setOpenModal(!openModal);
  };

  const setTheFrequency = (event) => setFrequency(event);

  const setTheEmailFrequency = (payload) => {
    setFrequencyType(payload);
    if (payload === "digest") {
      setOpenModal(!openModal);
    }
  };

  return (
    <div className="preference-content">
      <div className="pref-content-header">
        <div>
          <h3 className="pref-email-head">Email Notifications</h3>
          <p className="pref-email-para">
            Receive email notification on immediate action.
          </p>
        </div>
        <CustomDigestButton
          frequencyType={frequencyType}
          setTheEmailFrequency={setTheEmailFrequency}
          openModal={openModal}
          closeModal={closeModal}
          frequency={frequency}
          setTheFrequency={setTheFrequency}
          saveModalFrequency={saveModalFrequency}
        />
      </div>
      <div className="pref-header-divider"></div>

      <div className="pref-scroller">
        {notificationItems.map((content) => (
          <div
            onMouseOver={() => mouseScroll(content.slug)}
            id={`${content.name}-${content.id}`}
            key={content.title}
            className={
              selectedBox === content.slug
                ? "pref-content"
                : "pref-content-selected"
            }
          >
            <div className="content-box-header">
              <h3 className="pref-h3">{content.name}</h3>
              <h3
                onClick={() => selectAllCheckBoxes(content.slug)}
                className="pref-select-all"
              >
                {content.is_checked ? " Unselect All" : "Select All"}
              </h3>
            </div>
            {content.checkBoxes.map((checkBox) => (
              <div key={checkBox.name}>
                <div
                  style={{ height: "42px" }}
                  className="d-flex align-items-center"
                >
                  <input
                    type="checkbox"
                    name="checkbox"
                    className="cursor-pointer"
                    checked={checkBox.isSelected}
                    onChange={() => handleCheckbox(content.slug, checkBox.slug)}
                  />
                  <p className="pref-input-text">{checkBox.name}</p>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-end mt-3">
        <Button
          color="primary"
          className="modal-btn btn-outline-primary"
          onClick={clearTheNotifications}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          className="modal-btn"
          type="submit"
          onClick={saveUserPreferences}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default PreferenceCenterContent;
