import { special_report_details as DETAILS } from "../Reducers/actionConstants";

const specialReportCategories = (payload) => {
  return {
    type: DETAILS.SPECIAL_REPORT_CATEGORIES,
    payload,
  };
};

const specialReportSegments = (payload) => {
  return {
    type: DETAILS.SPECIAL_REPORT_SEGMENTS,
    payload,
  };
};

const specialReportDetails = (payload) => {
  return {
    type: DETAILS.SPECIAL_REPORT_DETAILS,
    payload,
  };
};

const categories = (payload) => {
  return {
    type: DETAILS.CATEGORIES,
    payload,
  };
};

const specialReportContent = (payload) => {
  return {
    type: DETAILS.SPECIAL_REPORT_CONTENT,
    payload,
  };
};

const specialReportTemplates = (payload) => {
  return {
    type: DETAILS.SPECIAL_REPORT_TEMPLATES,
    payload,
  };
};

const specialReportTransactionDetails = (payload) => {
  return {
    type: DETAILS.SPECIAL_REPORT_TRANSACTION_DETAILS,
    payload,
  };
};

export {
  specialReportCategories,
  specialReportSegments,
  specialReportDetails,
  categories,
  specialReportContent,
  specialReportTemplates,
  specialReportTransactionDetails,
};
