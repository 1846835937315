import React, { Component } from "react";
import constants from "../Utils/constants";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import queryString from "query-string";
import { connect } from "react-redux";
import { activeMenuTab } from "../Store/Actions/User";
import CustomBreadcrumb from "../Components/CustomBreadcrumb";
import DocumentTitle from "../Utils/DocumentTitle";
import ListGeographies from "./ListGeographies";
import ListRegions from "../Pages/ListRegions";
import ListCountries from "../Pages/ListCountries";

class ListMasterGeography extends Component {
  state = {
    totalTabs: [],
    selectedTab:
      constants.APPLICATION_ROUTE.MANAGE_GEOGRAPHY_REGION_COUNTRY.TABS[0].KEY,
  };

  onContentChangeTypeHandler = (s) => {
    this.setState({
      selectedTab: s,
    });
  };

  breadcrumbs = () => {
    let breadcrumbs = [
      // {
      //   title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,
      //   link: formatRoute(constants.APPLICATION_ROUTE.DASHBOARD.ROUTE, {}),
      //   is_active: false
      // },
      {
        title: constants.APPLICATION_ROUTE.MASTER_DATA_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.MANAGE_GEOGRAPHY_REGION_COUNTRY.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    let tabs = constants.APPLICATION_ROUTE.MANAGE_GEOGRAPHY_REGION_COUNTRY.TABS;
    tabs = tabs.filter((tab) => tab.HIDE_TAB === false);
    this.setState({
      totalTabs: tabs,
      selectedTab: params.selected_tab ? params.selected_tab : tabs[0].KEY,
      modifiedValueType: params.state ? params.state : tabs[0].STATE,
      modifiedValueRole: params.role ? params.role : tabs[0].ROLE,
    });
    this.props.activeMenuTab(
      constants.APPLICATION_ROUTE.MANAGE_GEOGRAPHY_REGION_COUNTRY.ARCHIVE_ROUTE
    );
  }

  render() {
    let selected = this.state.totalTabs.find(
      (f) => f.KEY === this.state.selectedTab
    );
    DocumentTitle(selected && selected.TAB_TITLE ? selected.TAB_TITLE : "");
    return (
      <aside className="main_content">
        <div className="m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height">
          <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0">
            <CustomBreadcrumb breadcrumbs={this.breadcrumbs()} />
          </div>
          <div className="comapnay_tbl">
            <div className="">
              <div className="btn-items-card-wrapper tabs-card-wrapper">
                <div className="btn-items-card card custom-card nav-tabs-card">
                  <div className="card-body">
                    <div className="d-flex">
                      <Nav tabs className="custom-nav-tabs">
                        {this.state.totalTabs.map((tab) => (
                          <NavItem key={tab.KEY}>
                            <NavLink
                              className={classnames({
                                active: this.state.selectedTab === tab.KEY,
                              })}
                              onClick={() => {
                                this.onContentChangeTypeHandler(tab.KEY);
                              }}
                            >
                              {tab.TAB_TITLE}
                            </NavLink>
                          </NavItem>
                        ))}
                      </Nav>
                    </div>
                  </div>
                </div>
              </div>
              <TabContent activeTab={this.state.selectedTab}>
                {this.state.totalTabs.map((tab) => (
                  <TabPane tabId={tab.KEY} key={tab.KEY}>
                    {this.state.selectedTab &&
                      this.state.selectedTab === tab.KEY &&
                      tab.KEY === "geographies" && (
                        <ListGeographies
                          {...this.props}
                          selectedTab={this.state.selectedTab}
                          pageNo={constants.PAGINATION.DEFAULT_PAGE_NO}
                          pageSize={constants.PAGINATION.DEFAULT_PAGE_SIZE}
                        />
                      )}
                    {this.state.selectedTab &&
                      this.state.selectedTab === tab.KEY &&
                      tab.KEY === "geo_sub_categories" && (
                        <ListRegions
                          {...this.props}
                          selectedTab={this.state.selectedTab}
                          pageNo={constants.PAGINATION.DEFAULT_PAGE_NO}
                          pageSize={constants.PAGINATION.DEFAULT_PAGE_SIZE}
                        />
                      )}
                    {this.state.selectedTab &&
                      this.state.selectedTab === tab.KEY &&
                      tab.KEY === "countries" && (
                        <ListCountries
                          {...this.props}
                          selectedTab={this.state.selectedTab}
                          pageNo={constants.PAGINATION.DEFAULT_PAGE_NO}
                          pageSize={constants.PAGINATION.DEFAULT_PAGE_SIZE}
                        />
                      )}
                  </TabPane>
                ))}
              </TabContent>
            </div>
          </div>
        </div>
      </aside>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
  };
}
export default connect(null, mapDispatchToProps)(ListMasterGeography);
