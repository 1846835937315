import { Axios } from "../lib/axios";
export const BenchmarkServices = {
  getTotalBenchmarks,
  getBenchmarks,
  getBenchmarkDetails,
  getBenchmarkContent,
  saveBenchmarkContent,
  addAuthor,
  removeAuthor,
  addBenchmark,
  editBenchmark,
  toggleBenchmark,
  removeBenchmark,
  getCategories,
  getBenchmarkTemplates,
  saveBenchmarkTransactionContent,
  getManageContentTransactionContent,
};
function getTotalBenchmarks(url) {
  return Axios.get(url);
}
function getBenchmarks(url) {
  return Axios.get(url);
}
function getBenchmarkDetails(id) {
  return Axios.get(`benchmark/${id}`);
}
function getBenchmarkContent(id) {
  return Axios.get(`benchmark/${id}/content`);
}
function saveBenchmarkContent(benchmarkId, content, action) {
  return Axios.post(`benchmark/${benchmarkId}/content/${action}`, content);
}
function addAuthor(userId, body) {
  return Axios.post(`author/${userId}`, body);
}
function removeAuthor(benchmarkId, id) {
  return Axios.delete(`author/${id}`);
}
function addBenchmark(body) {
  return Axios.post(`benchmark`, body);
}
function editBenchmark(benchmarkId, body) {
  return Axios.patch(`benchmark/${benchmarkId}`, body);
}
function toggleBenchmark(benchmarkId, body) {
  return Axios.patch(`benchmark/${benchmarkId}/status/${body.is_active}`);
}
function removeBenchmark(benchmarkId) {
  return Axios.delete(`benchmark/${benchmarkId}`);
}
function getCategories(benchmarkId) {
  return Axios.get(`categories?type=benchmark`);
}
function getBenchmarkTemplates() {
  return Axios.get("templates");
}
function saveBenchmarkTransactionContent(transaction_id, content, action = "") {
  return Axios.post(
    `transaction/${transaction_id}/type/benchmark/state/${action}`,
    content
  );
}
function getManageContentTransactionContent(transaction_id) {
  return Axios.get(`transaction/${transaction_id}/type/benchmark`);
}
