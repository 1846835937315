import React, { Component } from "react";
import DynamicTableComponentNew from "../Components/DynamicTableComponentsNew";
import { ReportsServices } from "../Services/Reports";
import { ClientServices } from "../Services/Client";
import { UserServices } from "../Services/User";
import constants from "../Utils/constants";
import Toast from "../Utils/Toast";
import DynamicTableComponentWithScroll from "../Components/DynamicTableComponentWithScroll";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import DocumentTitle from "../Utils/DocumentTitle";
import { formatRoute } from "react-router-named-routes";
import { activeMenuTab } from "../Store/Actions/User";
import { connect } from "react-redux";
import CustomDropdownList from "../Components/CustomDropdownList";
import IsTBRIClient from "../Utils/IsTBRIClient";
import { clients, users } from "../Store/Actions/Client";
import moment from "moment";
import CustomBreadcrumb from "../Components/CustomBreadcrumb";
import DailogNew from "../Components/DailogNew";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import AclAction from "../Components/ACL/AclAction";
import isRoleAccountManager from "../Utils/isRoleAccountManager";
import Sessions_PageViews from "../Components/Charts/Session_Pageviews";
import ModuleWiseDataChart from "../Components/Charts/ModuleWiseChart";
import BoardsChart from "../Components/Charts/BoardsChart";
import VisualizationChart from "../Components/Charts/VisualizationChart";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import CustomDatePicker from "../Components/CustomDatePicker";
import classnames from "classnames";
import ComapnyDetailsDateFormatter from "../Utils/ComapnyDetailsDateFormatter";
import decodeJWT from "../lib/decodeJWT";
const tableHeader = [
  { id: "timeStamp", title: "Time Stamp", sortable: true, visibility: true },
  { id: "page_title", title: "Page Title", sortable: true, visibility: true },
  { id: "Module_name", title: "Module", sortable: true, visibility: true },
  { id: "Time_spent", title: "Time Spent", sortable: true, visibility: true },
];
const downloadsTableHeader = [
  { id: "timeStamp", title: "Time Stamp", sortable: true, visibility: true },
  { id: "fileName", title: "File", sortable: true, visibility: true },
  { id: "moduleName", title: "Module", sortable: true, visibility: true },
  { id: "fileFormat", title: "Doc Type", sortable: true, visibility: true },
];
const loginDetailsTableHeader = [
  { id: "created_at", title: "Date", sortable: false, visibility: true },
  { id: "message", title: "Status", sortable: false, visibility: true },
];
class Reports3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clickedValues: [],
      rowData: [],
      successLoginCount: 0,
      failureLoginCount: 0,
      userData: [],
      columnData: [],
      pageCount: 1,
      loginData: [],
      hasMore: true,
      sortColumn: constants.PAGINATION.DEFAULT_REPORTS4_SORT_COLUMN,
      sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      count: 0,
      timespentCount: 0,
      timeSpentPageno: constants.PAGINATION.DEFAULT_PAGE_NO,
      timeSpentPagesize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      start_date: null,
      end_date: null,
      userId: null,
      clientId: null,
      clientName: null,
      companyName: "",
      page_Id: "",
      dailogModal: false,
      dailogModalContent: "",
      dailogModalHeader: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
      disableStatus: false,
      selectReason: "",
      AssignedClients: [],
      showActions: false,
      isRoleAccountManager: false,
      sessionsLogData: {},
      pageVisitsLogData: {},
      timeSpentLogData: {},
      currentData: [],
      totalTabs: [],
      selectedTab: constants.APPLICATION_ROUTE.REPORTS.LIST3.TABS[0].KEY,
      fromDate: moment().subtract(60, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      presentDate: moment().format("YYYY-MM-DD"),
    };
  }
  componentDidMount() {
    DocumentTitle(constants.APPLICATION_ROUTE.REPORTS.LIST3.HEADER_TITLE);
    this.setState({
      userId:
        this.props.location.state && this.props.location.state.id
          ? this.props.location.state.id
          : null,
      clientId:
        this.props.location.state && this.props.location.state.clientId
          ? this.props.location.state.clientId
          : null,
      start_date:
        this.props.location.state && this.props.location.state.fromDate
          ? this.props.location.state.fromDate
          : null,
      end_date:
        this.props.location.state && this.props.location.state.endDate
          ? this.props.location.state.endDate
          : moment().format("YYYY-MM-DD"),
      totalTabs: constants.APPLICATION_ROUTE.REPORTS.LIST3.TABS,
    });
    this.props.activeMenuTab(constants.APPLICATION_ROUTE.REPORTS.LIST3.ROUTE);
    ClientServices.getAllClients()
      .then((res) => {
        let clients = [];
        let response = res && res.data && res.data.data;
        response = response.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : b.name.toLowerCase() > a.name.toLowerCase()
            ? -1
            : 0
        );
        response = response.filter((f) => !IsTBRIClient(f));
        let totalClients = clients.concat(response);
        totalClients.forEach((r) => {
          r.is_selected = r.id === this.state.clientId ? true : false;
        });
        let clientid = totalClients.find((c) => c.is_selected === true)
          ? totalClients.find((c) => c.is_selected === true)
          : totalClients[0];
        let companyName = clientid.name;
        clientid = clientid["id"];
        if (clientid === totalClients[0]["id"]) {
          totalClients[0].is_selected = true;
        }
        this.setState(
          {
            clientId: clientid,
            companyName,
          },
          () => {
            let tokenDetails = decodeJWT(localStorage.getItem("token"));
            if (
              isRoleAccountManager(this.props.home.loginUserSuccess.role_id)
            ) {
              this.setState({ isRoleAccountManager: true });
              this.getAssignedClients();
            }
            if (
              this.props.home.loginUserSuccess.role_id !== 4 &&
              this.props.home.loginUserSuccess.role_id !== 28
            ) {
              this.setState({
                disableStatus: true,
              });
            } else {
              this.setState({
                showActions: true,
              });
            }
          }
        );
        this.props.setClients(totalClients);
        this.getUsersList();
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  }

  //checks for falsy values in payload like undefined ,null etc
  validatePayload(obj) {
    let isValid = !Object.values(obj).some((value) => !value);
    return isValid;
  }

  getSelectedTabData = () => {
    switch (this.state.selectedTab) {
      case constants.APPLICATION_ROUTE.REPORTS.LIST3.TABS[1].KEY:
        this.pageWiseTimeSpent();
        break;
      case constants.APPLICATION_ROUTE.REPORTS.LIST3.TABS[2].KEY:
        this.getUserDownloadData();
        break;
      default:
        break;
    }
    this.changeRoute();
  };
  getPageVistedLogData = () => {
    const userId = this.state.userId;
    let body = {
      user_id: userId,
      event_name: constants.GA.EVENTS.PAGE_VISIT,
    };
    ReportsServices.getEventSpecificLogData(body)

      .then((res) => {
        this.setState({
          pageVisitsLogData: res.data,
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  getSessionsLogData = () => {
    const userId = this.state.userId;
    let body = {
      user_id: userId,
      event_name: constants.GA.EVENTS.LOGIN,
    };
    ReportsServices.getEventSpecificLogData(body)
      .then((res) => {
        this.setState({
          sessionsLogData: res.data,
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  getTimeSpentLogData = () => {
    const userId = this.state.userId;
    let body = {
      user_id: userId,
      event_name: constants.GA.EVENTS.PAGE_VISIT,
    };
    ReportsServices.getTimeSpentDaysData(body)
      .then((res) => {
        this.setState({
          timeSpentLogData: res.data,
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  getLoginCount() {
    ReportsServices.getLoginCount(
      this.state.userId,
      this.state.start_date,
      this.state.end_date
    )
      .then((res) => {
        this.setState({
          successLoginCount: res.data.login_success_count,
          failureLoginCount: res.data.login_failure_count,
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  }
  getUserDetails() {
    ClientServices.getClientUserDetails(this.state.userId)
      .then((res) => {
        let created_at = res.data.created_at;
        let currentDate = moment();
        let diff = currentDate.diff(moment(created_at), "days");
        this.setState(
          {
            userData: res.data,
            ...(diff < 60
              ? { start_date: moment(created_at).format("YYYY-MM-DD") }
              : {
                  start_date: moment()
                    .subtract(60, "days")
                    .format("YYYY-MM-DD"),
                }),
          },
          () => {
            this.getLoginCount();
            this.getUserLoginDetails();
            this.getSelectedTabData();
          }
        );
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  }
  getAssignedClients() {
    ClientServices.getAssignedClients("/getAssignedClients")
      .then((res) => {
        let clientData = res.data.data;
        clientData = clientData.map((data) => {
          return {
            id: data.id,
            name: data.name,
          };
        });
        let index = clientData.find(
          (element) => element.id === this.state.clientId
        );
        if (index) {
          this.setState({
            showActions: true,
          });
        } else {
          this.setState({
            showActions: false,
          });
        }
        this.setState({ AssignedClients: clientData });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  }
  resendWelcomeEmailConfirmation = () => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          Resend Verification Link
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="activate-body">
          <h2 className="font-weight-semibold mb-0">
            Are you sure want to Resend Verification Link?
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "resend_welcomemail" },
      dailogModalStyleType: "success-modal",
    });
  };
  resendWelcomeEmail = () => {
    let arr = [];
    arr.push({ id: this.state.userData.id });
    ClientServices.resendEmailVerification(arr)
      .then((res) => {
        let dailogModalHeader = (
          <>
            <h3 className="text-darkblue2 font-weight-bold">Success</h3>
          </>
        );
        let dailogModalContent = (
          <>
            <div className="activate-body">
              <h2 className="font-weight-semibold mb-0">
                Verification mail has been sent
              </h2>
            </div>
          </>
        );
        this.setState({
          dailogModal: true,
          dailogModalHeader: dailogModalHeader,
          dailogModalContent: dailogModalContent,
          dailogModalConfig: { type: false, id: null },
          dailogModalStyleType: "success-modal",
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  sendResetPasswordLink() {
    let body = { email: this.state.userData.email, is_client: true };
    UserServices.sendverificationmailForPasswordSetting(body)
      .then((data) => {
        Toast(data.data.message, "success");
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  }
  getUserLoginDetails = () => {
    let count = this.state.pageCount;
    let prevData = this.state.loginData;
    let url = `/userLoginDetails/${this.state.userId}?page=${count}&start_date=${this.state.start_date}&end_date=${this.state.end_date}`;
    ReportsServices.getUserLoginDetails(url)
      .then((res) => {
        let data = res.data.data;
        let totalPageCount = Math.ceil(res.data.meta.total_count / 10);
        data = data.map((data) => {
          return {
            message: data.message === "Failure" ? "Failed" : data.message,
            created_at: ComapnyDetailsDateTimeFormatter(data.created_at, false),
          };
        });
        this.setState({
          loginData: prevData.concat(data),
          pageCount: count + 1,
        });
        if (totalPageCount < this.state.pageCount) {
          this.setState({
            hasMore: false,
          });
        }
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  getUserDownloadData = () => {
    let body = {
      user_id: this.state.userId,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
    };
    if (this.validatePayload(body)) {
      ReportsServices.getUserDownloadData(body)
        .then((res) => {
          let userDownloadData = res.data.data;
          userDownloadData = userDownloadData.map((data) => {
            return {
              timeStamp: ComapnyDetailsDateTimeFormatter(
                moment(data.eventTimestamp / 1000),
                false
              ),
              fileName: data.fileName,
              fileFormat: data.fileFormat,
              moduleName: data.moduleName,
              timestamp_sort: data.eventTimestamp,
            };
          });
          this.setState(
            {
              rowData: userDownloadData,
              timespentCount: userDownloadData.length,
              currentData: userDownloadData,
            },
            () => {
              this.sortTableData();
            }
          );
        })
        .catch((error) => {
          Toast(
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
            "error"
          );
        });
    } else {
      this.setState({
        rowData: [],
        currentData: [],
        timespentCount: 0,
      });
    }
  };
  getDateDifference = (fromDate, endDate) => {
    const date1 = new Date(fromDate);
    const date2 = new Date(endDate);
    const diffTime = date2 - date1;
    if (diffTime < 0) {
      return false;
    }
    return true;
  };

  changeFromDate = (e, value) => {
    if (value !== null) {
      let bool =
        e === "start"
          ? this.getDateDifference(value, this.state.end_date)
          : this.getDateDifference(this.state.start_date, value);
      if (bool && e === "start") {
        this.setState(
          {
            start_date: moment(value).format("YYYY-MM-DD"),
            hasMore: true,
            pageCount: 1,
            loginData: [],
          },
          () => {
            if (this.state.userId != null) {
              this.getLoginCount();
              this.getUserLoginDetails();
              this.getSelectedTabData();
            }
          }
        );
      } else if (bool) {
        this.setState(
          {
            end_date: moment(value).format("YYYY-MM-DD"),
            hasMore: true,
            pageCount: 1,
            loginData: [],
          },
          () => {
            if (this.state.userId != null) {
              this.getLoginCount();
              this.getUserLoginDetails();
              this.getSelectedTabData();
            }
          }
        );
      }
    }
  };

  breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.REPORTS_GROUP.NAME,
        link: constants.APPLICATION_ROUTE.REPORTS.LIST.ROUTE,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.REPORTS.LIST3.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };
  handleClientChange = (e) => {
    let clients = [...this.props.client.clients];
    clients.forEach((c) => {
      c.is_selected = c.id === e.id ? true : false;
    });
    this.props.setClients(clients);
    let clientId = clients.find((c) => c.is_selected === true)
      ? clients.find((c) => c.is_selected === true)
      : "all";
    let companyName = clientId.name;
    clientId = clientId.id;
    if (this.state.isRoleAccountManager) {
      let clientData = this.state.AssignedClients;
      let index = clientData.find((element) => element.id === clientId);
      if (index) {
        this.setState({
          showActions: true,
        });
      } else {
        this.setState({
          showActions: false,
        });
      }
    }

    this.setState(
      {
        clientId: clientId,
        companyName: companyName,
        count: 0,
      },
      () => {
        this.getUsersList();
      }
    );
  };
  handleUserChange = (e) => {
    let users = [...this.props.client.users];
    users.forEach((c) => {
      c.is_selected = c.id === e.id ? true : false;
    });
    this.props.setUsers(users);
    let userId = users.find((c) => c.is_selected === true)
      ? users.find((c) => c.is_selected === true).id
      : "all";
    this.setState(
      {
        userId: userId,
        hasMore: true,
        pageCount: 1,
        loginData: [],
      },
      () => {
        this.getUserDetails();
        this.getPageVistedLogData();
        this.getSessionsLogData();
        this.getTimeSpentLogData();
      }
    );
  };
  getUsersList() {
    let url = this.state.clientId
      ? `/client/${this.state.clientId}/null/users`
      : ""; // 3 is for all actve and inactive users for status as route is modified for users in client module
    ClientServices.getClientUsers(url)
      .then((res) => {
        let response = res && res.data && res.data.data;
        let totalUsers = response;
        if (totalUsers.length != 0) {
          totalUsers = totalUsers.map((r) => {
            return {
              id: r.id,
              name: r.first_name + " " + r.last_name,
              is_selected: r.id === this.state.userId ? true : false,
              email: r.email,
              createdAt: r.created_at,
            };
          });

          totalUsers = totalUsers.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase()
              ? 1
              : b.name.toLowerCase() > a.name.toLowerCase()
              ? -1
              : 0
          );
          let userid = totalUsers.find((c) => c.is_selected === true)
            ? totalUsers.find((c) => c.is_selected === true)
            : totalUsers[0];
          if (userid.id === totalUsers[0]["id"]) {
            totalUsers[0].is_selected = true;
          }
          this.setState(
            {
              userId: userid.id,
              hasMore: true,
              pageCount: 1,
              loginData: [],
            },
            () => {
              this.getUserDetails();
              this.getPageVistedLogData();
              this.getSessionsLogData();
              this.getTimeSpentLogData();
            }
          );
          this.props.setUsers(totalUsers);
        } else {
          totalUsers = [];
          totalUsers.push({
            id: "na",
            name: "No Users Found",
            is_selected: true,
          });
          this.setState({
            userId: null,
            userData: [],
            rowData: [],
            loginData: [],
            successLoginCount: "-",
            failureLoginCount: "-",
            hasMore: false,
            pageVisitsLogData: [],
            sessionsLogData: [],
          });
          this.props.setUsers(totalUsers);
        }
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  }
  dateformatChanger = (input) => {
    let dateobj = new Date(input);
    let momentobj = new moment(dateobj);
    let old_date = momentobj.format("MM-DD-YYYY");
    return old_date;
  };
  handleSort = (clickedColumn, key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => {
        this.sortTableData();
      }
    );
  };

  sortTableData = () => {
    this.changeRoute();
    let sortOrder = this.state.sortOrder;
    let sortColumn = this.state.sortColumn;
    let totalData = this.state.rowData;
    let bool = false;
    sortColumn =
      sortColumn === "Time_spent"
        ? "timespent_sort"
        : sortColumn === "timeStamp"
        ? "timestamp_sort"
        : sortColumn;
    totalData.sort((a, b) => {
      const aLocale = this.convertType(a[sortColumn]);
      const bLocale = this.convertType(b[sortColumn]);
      if (aLocale === bLocale) {
        return 0;
      } else if (
        aLocale === undefined ||
        aLocale == null ||
        aLocale > bLocale
      ) {
        bool = true;
        return 1;
      } else if (
        bLocale === undefined ||
        bLocale == null ||
        aLocale < bLocale
      ) {
        bool = true;
        return -1;
      }
    });
    if (bool) {
      totalData = sortOrder === "desc" ? totalData.reverse() : totalData;
    }
    this.setState({
      rowData: totalData,
      currentData: totalData,
      timeSpentPageno: 1,
    });
  };
  convertType = (data) => {
    switch (this.state.sortColumn) {
      case "Time_spent" || "timeStamp":
        return Number(data);
      default:
        return data;
    }
  };

  emailValidationHandler = () => {
    ClientServices.getSingleUserBounceValidation(
      this.state.userData.email,
      this.state.userData.id
    )
      .then((res) => {
        if (res.data.status.toLowerCase().trim() === "invalid") {
          let dailogModalHeader = (
            <>
              <h3 className="text-darkblue2 font-weight-bold">User Invalid</h3>
            </>
          );
          let dailogModalContent = (
            <>
              <div className="remove-body">
                <h2 className="font-weight-semibold mb-0">
                  {constants.CLIENT_USER.INACTIVATE_USER_CONFIRM_MESSAGE}
                </h2>
              </div>
            </>
          );
          this.setState({
            dailogModal: true,
            dailogModalHeader: dailogModalHeader,
            dailogModalContent: dailogModalContent,
            dailogModalConfig: { type: false, id: null },
            dailogModalStyleType: "danger-modal",
          });
        } else if (
          res.data.status.toLowerCase() === "catch_all" ||
          res.data.status.toLowerCase().trim() === "catchall"
        ) {
          let dailogModalHeader = (
            <>
              <h3 className="text-darkblue2 font-weight-bold">Verify Email</h3>
            </>
          );
          let dailogModalContent = (
            <>
              <div className="inactive-body">
                <h2 className="font-weight-semibold mb-0">
                  Please Verify User Email Manually
                </h2>
              </div>
            </>
          );
          this.setState({
            dailogModal: true,
            dailogModalHeader: dailogModalHeader,
            dailogModalContent: dailogModalContent,
            dailogModalConfig: { type: false, id: null },
            dailogModalStyleType: "inactive-modal",
          });
        } else {
          let dailogModalHeader = (
            <>
              <h3 className="text-darkblue2 font-weight-bold">Success</h3>
            </>
          );
          let dailogModalContent = (
            <>
              <div className="activate-body">
                <h2 className="font-weight-semibold mb-0">
                  User Email is Valid
                </h2>
              </div>
            </>
          );
          this.setState({
            dailogModal: true,
            dailogModalHeader: dailogModalHeader,
            dailogModalContent: dailogModalContent,
            dailogModalConfig: { type: false, id: null },
            dailogModalStyleType: "success-modal",
          });
        }
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  removeUserConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {constants.CLIENT_USER.REMOVE_USER_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_user", id: data },
      dailogModalStyleType: "danger-modal",
    });
  };
  sendResetPasswordLinkConfirmation = () => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Reset Password Link</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="activate-body">
          <h2 className="font-weight-semibold mb-0">
            Are you sure want to send reset password link?
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "reset_password" },
      dailogModalStyleType: "success-modal",
    });
  };
  emailValidationConfirmation = () => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          User Email Validation
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="activate-body">
          <h2 className="font-weight-semibold mb-0">
            Are you sure want to validate user?
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "email_validation" },
      dailogModalStyleType: "success-modal",
    });
  };
  removeClientUser = (clientUserId) => {
    let arr = [];
    arr.push({ id: clientUserId });
    ClientServices.removeClientUser(arr)
      .then((res) => {
        Toast(constants.CLIENT_USER.REMOVE.SUCCESS, "success");
        this.getUsersList();
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };
  dailogModalAccept = (config) => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
    switch (config.type) {
      case "remove_user":
        this.removeClientUser(config.id);
        break;
      case "reset_password":
        this.sendResetPasswordLink();
        break;
      case "email_validation":
        this.emailValidationHandler();
        break;
      case "resend_welcomemail":
        this.resendWelcomeEmail();
        break;
      case "de-activate_user":
        this.toggleClientUser(config.id, 0);
        break;
      case "activate_user":
        this.toggleClientUser(config.id, 1);
        break;
      default:
        break;
    }
  };
  changeRoute = () => {
    let queryParams = "";
    if (this.state.selectedTab) {
      queryParams = "&selected_tab=" + this.state.selectedTab;
    }
    if (this.state.sortOrder && this.state.selectedTab !== "over-view") {
      queryParams +=
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    this.props.history.push({
      pathname: formatRoute(constants.APPLICATION_ROUTE.REPORTS.LIST3.ROUTE, {
        id: this.state.userId,
      }),
      search:
        "?page=" +
        this.state.timeSpentPageno +
        "&page_size=" +
        this.state.timeSpentPagesize +
        queryParams,
    });
  };
  onContentChangeTypeHandler = (s) => {
    this.setState(
      {
        selectedTab: s,
        timespentCount: 0,
        rowData: [],
        currentData: [],
        timeSpentPageno: 1,
        timeSpentPagesize: 10,
        sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
        sortColumn: constants.PAGINATION.DEFAULT_REPORTS4_SORT_COLUMN,
      },
      () => {
        this.getSelectedTabData();
      }
    );
  };

  timeconversionfordashboard(number) {
    let timeSpent = parseInt(number);
    if (!isNaN(timeSpent)) {
      let hours = timeSpent / 3600 > 0 ? Math.floor(timeSpent / 3600) : 0;
      let minutes =
        (timeSpent % 3600) / 60 > 0 ? Math.floor((timeSpent % 3600) / 60) : 0;
      let seconds = (timeSpent % 3600) % 60 > 0 ? (timeSpent % 3600) % 60 : 0;
      let hour_result = hours === 0 ? `` : `${hours}h`;
      let min_result = minutes === 0 ? `` : `${minutes}m`;
      let sec_result = seconds === 0 ? `` : `${seconds}s`;
      return `${hour_result} ${min_result} ${sec_result}`.trim();
    }
    return "-";
  }

  timeconversion(number) {
    if (!isNaN(number)) {
      let hours = number / 3600 > 0 ? Math.floor(number / 3600) : 0;
      let minutes =
        (number % 3600) / 60 > 0 ? Math.floor((number % 3600) / 60) : 0;
      let seconds = (number % 3600) % 60 > 0 ? (number % 3600) % 60 : 0;
      let hour_result =
        hours === 0 ? `` : hours > 1 ? `${hours} Hours` : `${hours} Hour`;
      let min_result =
        minutes === 0
          ? ``
          : minutes > 1
          ? `${minutes} Minutes`
          : `${minutes} Minute`;
      let sec_result =
        seconds === 0
          ? ``
          : seconds > 1
          ? `${seconds} Seconds`
          : `${seconds} Second`;
      return `${hour_result} ${min_result} ${sec_result}`.trim();
    }
    return "-";
  }
  pageWiseTimeSpent() {
    let body = {
      user_id: this.state.userId,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
    };
    if (this.validatePayload(body)) {
      ReportsServices.pageWiseTimeSpent(body)
        .then((res) => {
          let data = res.data.data;
          let count =
            (res.data && res.data.meta && res.data.meta.total_count) || 0;
          let modules = {
            Companies: 1,
            Projects: 1,
            Marketforecasts: 1,
            Marketlandscapes: 1,
          };
          data = data.map((val) => {
            return {
              timeStamp: ComapnyDetailsDateTimeFormatter(
                moment(val.eventTimeStamp / 1000 - val.timeSpent * 1000, false)
              ),
              page_title:
                modules[val.pageTitle.split("| ")[1]] !== undefined
                  ? val.pageTitle.split("| ")[1] + "-List"
                  : val.pageTitle.split("| ")[1],
              Module_name: val.moduleName,
              Time_spent: this.timeconversion(val.timeSpent),
              timespent_sort: val.timeSpent,
              timestamp_sort: val.eventTimeStamp,
            };
          });

          this.setState(
            {
              rowData: data,
              timespentCount: count,
              currentData: data,
            },
            () => {
              this.sortTableData();
            }
          );
        })
        .catch((error) => {
          Toast(
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
            "error"
          );
        });
    } else {
      this.setState({
        rowData: [],
        currentData: [],
        timespentCount: 0,
      });
    }
  }
  toggleClientUserConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          {data.is_active === 1 ? "De-Activation" : "Activation"}
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        {data.is_active === 1 ? (
          <div className="remove-body">
            <h2 className="font-weight-semibold mb-0">
              {constants.CLIENT_USER.INACTIVATE_USER_CONFIRM_MESSAGE}
            </h2>
            <div className="custom-select-new custom-select-dropdown box-shadow-none d-inline-block mt-3">
              <select onChange={this.handleReasonchange}>
                <option value="1" selected>
                  User Requested
                </option>
                <option value="2">Email bounced</option>
                <option value="3">ClearOut Invalid</option>
              </select>
            </div>
          </div>
        ) : (
          <div className="activate-body">
            <h2 className="font-weight-semibold mb-0">
              {constants.CLIENT_USER.ACTIVATE_USER_CONFIRM_MESSAGE}
            </h2>
          </div>
        )}
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent,
      dailogModalConfig: {
        type: data.is_active === 1 ? "de-activate_user" : "activate_user",
        id: this.state.userId,
      },
      dailogModalStyleType:
        data.is_active === 1 ? "danger-modal" : "success-modal",
    });
  };
  handleReasonchange = (e) => {
    let value = e.target.value;
    let reason;
    switch (value) {
      case "1":
        reason = "User Requested";
        break;
      case "2":
        reason = "Email bounced";
        break;
      case "3":
        reason = "ClearOut Invalid";
        break;
    }
    this.setState({
      selectReason: reason,
    });
  };
  toggleClientUser = (clientUserId, isActive) => {
    let arr = [];
    arr.push({ id: clientUserId });
    ClientServices.toggleClientUser(isActive, {
      userIds: arr,
      reason: isActive === 1 ? "" : this.state.selectReason,
    })
      .then((res) => {
        Toast(
          isActive
            ? constants.CLIENT_USER.ACTIVATE.SUCCESS
            : constants.CLIENT_USER.IN_ACTIVATE.SUCCESS,
          "success"
        );
        this.getUserDetails();
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  render() {
    let clients =
      this.props.client && this.props.client.clients
        ? this.props.client.clients
        : [];
    let users =
      this.props.client && this.props.client.users
        ? this.props.client.users
        : [];
    let tokenDetails = decodeJWT(localStorage.getItem("token"));
    let isAdmin =
      tokenDetails.role && tokenDetails.role.short_name === "admin"
        ? false
        : true;
    return (
      <>
        <aside className="main_content">
          <div className="m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height">
            <CustomBreadcrumb breadcrumbs={this.breadcrumbs()} />
            <div className="tbr-client-list">
              <div className="row gutter-8">
                <div className="col-xl-3 col-md-4">
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 col-xl-12">
                      <div className="card custom-card">
                        <div className="card-body">
                          <h5 className="font-weight-bold text-black2 mb-1">
                            # Pages Last Visited
                          </h5>
                          <div className="stats-group d-flex">
                            <div className="stats-block flex-grow-1 d-flex flex-column">
                              <h5 className="font-weight-bold text-blue">
                                {this.state.pageVisitsLogData &&
                                this.state.pageVisitsLogData.sevenDaysCount
                                  ? this.state.pageVisitsLogData.sevenDaysCount
                                  : "0"}
                              </h5>
                              <label>7 Days</label>
                            </div>
                            <div className="stats-block flex-grow-1 d-flex flex-column">
                              <h5 className="font-weight-bold text-blue">
                                {this.state.pageVisitsLogData &&
                                this.state.pageVisitsLogData.thirtyDaysCount
                                  ? this.state.pageVisitsLogData.thirtyDaysCount
                                  : "0"}
                              </h5>
                              <label>30 days</label>
                            </div>
                            <div className="stats-block flex-grow-1 d-flex flex-column">
                              <h5 className="font-weight-bold text-blue">
                                {this.state.pageVisitsLogData &&
                                this.state.pageVisitsLogData.nintyDaysCount
                                  ? this.state.pageVisitsLogData.nintyDaysCount
                                  : "0"}
                              </h5>
                              <label>90 Days</label>
                            </div>
                            <div className="stats-block flex-grow-1 d-flex flex-column">
                              <h5 className="font-weight-bold text-blue">
                                {this.state.pageVisitsLogData &&
                                this.state.pageVisitsLogData.allTotalCount
                                  ? this.state.pageVisitsLogData.allTotalCount
                                  : "0"}
                              </h5>
                              <label>All Time</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 col-xl-12">
                      <div className="card custom-card">
                        <div className="card-body">
                          <h5 className="font-weight-bold text-black2 mb-1">
                            # Sessions
                          </h5>
                          <div className="stats-group d-flex">
                            <div className="stats-block flex-grow-1 d-flex flex-column">
                              <h5 className="font-weight-bold text-blue">
                                {this.state.sessionsLogData &&
                                this.state.sessionsLogData.sevenDaysCount
                                  ? this.state.sessionsLogData.sevenDaysCount
                                  : "0"}
                              </h5>
                              <label>7 Days</label>
                            </div>
                            <div className="stats-block flex-grow-1 d-flex flex-column">
                              <h5 className="font-weight-bold text-blue">
                                {this.state.sessionsLogData &&
                                this.state.sessionsLogData.thirtyDaysCount
                                  ? this.state.sessionsLogData.thirtyDaysCount
                                  : "0"}
                              </h5>
                              <label>30 days</label>
                            </div>
                            <div className="stats-block flex-grow-1 d-flex flex-column">
                              <h5 className="font-weight-bold text-blue">
                                {this.state.sessionsLogData &&
                                this.state.sessionsLogData.nintyDaysCount
                                  ? this.state.sessionsLogData.nintyDaysCount
                                  : "0"}
                              </h5>
                              <label>90 Days</label>
                            </div>
                            <div className="stats-block flex-grow-1 d-flex flex-column">
                              <h5 className="font-weight-bold text-blue">
                                {this.state.sessionsLogData &&
                                this.state.sessionsLogData.allTotalCount
                                  ? this.state.sessionsLogData.allTotalCount
                                  : "0"}
                              </h5>
                              <label>All Time</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 col-xl-12">
                      <div className="card custom-card">
                        <div className="card-body">
                          <h5 className="font-weight-bold text-black2 mb-1">
                            # Time Spent
                          </h5>
                          <div className="stats-group d-flex">
                            <div className="stats-block flex-grow-1 d-flex flex-column">
                              <h5 className="font-weight-bold text-blue">
                                {this.state.timeSpentLogData &&
                                this.state.timeSpentLogData.sevenDaysCount
                                  ? this.timeconversionfordashboard(
                                      this.state.timeSpentLogData.sevenDaysCount
                                    )
                                  : "0"}
                              </h5>
                              <label>7 Days</label>
                            </div>
                            <div className="stats-block flex-grow-1 d-flex flex-column">
                              <h5 className="font-weight-bold text-blue">
                                {this.state.timeSpentLogData &&
                                this.state.timeSpentLogData.thirtyDaysCount
                                  ? this.timeconversionfordashboard(
                                      this.state.timeSpentLogData
                                        .thirtyDaysCount
                                    )
                                  : "0"}
                              </h5>
                              <label>30 days</label>
                            </div>
                            <div className="stats-block flex-grow-1 d-flex flex-column">
                              <h5 className="font-weight-bold text-blue">
                                {this.state.timeSpentLogData &&
                                this.state.timeSpentLogData.nintyDaysCount
                                  ? this.timeconversionfordashboard(
                                      this.state.timeSpentLogData.nintyDaysCount
                                    )
                                  : "0"}
                              </h5>
                              <label>90 Days</label>
                            </div>
                            <div className="stats-block flex-grow-1 d-flex flex-column">
                              <h5 className="font-weight-bold text-blue">
                                {this.state.timeSpentLogData &&
                                this.state.timeSpentLogData.allTotalCount
                                  ? this.timeconversionfordashboard(
                                      this.state.timeSpentLogData.allTotalCount
                                    )
                                  : "0"}
                              </h5>
                              <label>All Time</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="card custom-card login-details-card">
                        <div className="card-body">
                          <h5 className="font-weight-bold text-black2 mb-1">
                            Login Details
                          </h5>
                          <div className="stats-group d-flex flex-wrap">
                            <div className="stats-block d-flex align-items-center">
                              <h5 className="font-weight-bold text-green mr-2 stat">
                                {this.state.successLoginCount
                                  ? this.state.successLoginCount
                                  : "0"}
                              </h5>
                              <label className="mr-2">Successful</label>
                            </div>
                            <div className="stats-block d-flex align-items-center ml-2">
                              <h5 className="font-weight-bold text-red mr-2 stat">
                                {this.state.failureLoginCount
                                  ? this.state.failureLoginCount
                                  : "0"}
                              </h5>
                              <label>Failed</label>
                            </div>
                          </div>
                          <DynamicTableComponentWithScroll
                            columnData={loginDetailsTableHeader}
                            rowData={this.state.loginData}
                            fetchMore={this.getUserLoginDetails}
                            hasMore={this.state.hasMore}
                            colSpan={
                              loginDetailsTableHeader.filter(
                                (h) => h.visibility
                              ).length
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-9 col-md-8">
                  <div className="reports-right-block">
                    <div className="d-flex row flex-wrap client-list-filter-block">
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 clients_dropdown mb-18px">
                        <div className="custom-combobox-list with-noborder with-shadow-sm">
                          <CustomDropdownList
                            data={clients}
                            handleChange={this.handleClientChange}
                            className="reports_drop_down"
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 col-6 clients_dropdown">
                        <div className="custom-combobox-list with-noborder with-shadow-sm">
                          <CustomDropdownList
                            data={users}
                            handleChange={this.handleUserChange}
                            className="reports_drop_down"
                          />
                        </div>
                      </div>
                      <div className="col clients_dropdown">
                        <div className="d-flex">
                          <CustomDatePicker
                            className="custom_dates custom-combobox-list with-noborder with-shadow-sm"
                            id="start"
                            date={this.state.start_date}
                            onChangeHandler={this.changeFromDate}
                            min={
                              this.state.userData &&
                              this.state.userData?.created_at
                                ? moment
                                    .utc(this.state.userData?.created_at)
                                    .format("YYYY-MM-DD")
                                : null
                            }
                            max={this.state.end_date}
                          />
                          <CustomDatePicker
                            className="custom_dates custom-combobox-list with-noborder with-shadow-sm"
                            id="end"
                            date={this.state.end_date}
                            onChangeHandler={this.changeFromDate}
                            min={this.state.start_date}
                            max={moment.utc().format("YYYY-MM-DD")}
                          />
                        </div>
                      </div>
                    </div>
                    <h3 className="text-black2 main-h3 mb-0 icon-fs-17 caps sections-card-wrapper p-2">
                      {this.state.userData.first_name &&
                      this.state.userData.last_name
                        ? this.state.userData.first_name +
                          " " +
                          this.state.userData.last_name
                        : "User"}{" "}
                      (
                      {this.state.start_date
                        ? ComapnyDetailsDateFormatter(
                            this.state.start_date,
                            false
                          )
                        : "-"}{" "}
                      to{" "}
                      {this.state.end_date
                        ? ComapnyDetailsDateFormatter(
                            this.state.end_date,
                            false
                          )
                        : "-"}
                      )
                    </h3>
                    <div className="sections-card-wrapper user-details-card mt-3">
                      <div className="d-flex flex-wrap flex-xl-nowrap sections-card-row-1">
                        <div className="sections-card flex-grow-0 border-right border-bottom profile-card d-flex justify-content-center align-items-center">
                          <span className="material-icons">account_circle</span>
                        </div>
                        <div className="sections-card border-right border-bottom text-center">
                          <div className="d-flex flex-column">
                            <div className="font-weight-semibold mb-1">
                              {this.state.userData.first_name &&
                              this.state.userData.last_name
                                ? this.state.userData.first_name +
                                  " " +
                                  this.state.userData.last_name
                                : "-"}
                            </div>
                            <label>Name</label>
                          </div>
                        </div>
                        <div className="sections-card border-right border-bottom text-center">
                          <div className="d-flex flex-column">
                            <div className="font-weight-semibold mb-1">
                              {this.state.userData.client &&
                              this.state.userData.client.name
                                ? this.state.userData.client.name
                                : "-"}
                            </div>
                            <label>Client</label>
                          </div>
                        </div>
                        <div className="sections-card border-right border-bottom text-center">
                          <div className="d-flex flex-column">
                            <div className="font-weight-semibold mb-1">
                              <a
                                className="text-reset"
                                href={`mailto: ${
                                  this.state.userData &&
                                  this.state.userData.email
                                    ? this.state.userData.email
                                    : ""
                                }`}
                              >
                                {this.state.userData.email
                                  ? this.state.userData.email
                                  : "-"}
                              </a>
                            </div>
                            <label>Email Id</label>
                          </div>
                        </div>
                        <div className="sections-card border-right border-bottom text-center">
                          <div className="d-flex flex-column">
                            <div className="font-weight-semibold mb-1">
                              {this.state.userData.created_at &&
                              this.state.userData.created_at
                                ? ComapnyDetailsDateTimeFormatter(
                                    this.state.userData.created_at,
                                    false
                                  )
                                : "-"}
                            </div>
                            <label>User Created At</label>
                          </div>
                        </div>
                        <div className="sections-card border-right border-bottom text-center">
                          <div className="d-flex flex-column">
                            {this.state.userData.is_active === 1 ||
                            this.state.userData.is_active == 0 ? (
                              <div className="custom-control custom-switch success-toggle">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={`customSwitch${this.state.userData.id}`}
                                  checked={this.state.userData.is_active === 1}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    this.toggleClientUserConfirmationHandler(
                                      this.state.userData
                                    );
                                  }}
                                  disabled={!this.state.showActions}
                                />
                                <label
                                  className="custom-control-label"
                                  for={`customSwitch${this.state.userData.id}`}
                                ></label>
                              </div>
                            ) : this.state.userData?.is_active ? (
                              <label className="font-weight-semibold mb-1 text-red">
                                Pending
                              </label>
                            ) : (
                              "-"
                            )}
                            <label>Status</label>
                          </div>
                        </div>

                        {this.state.showActions && (
                          <AclAction
                            type={constants.ACL.PERMISSION_KEY}
                            entity={constants.ACL.REPORTS_USER_RESOURCE.TITLE}
                            action={constants.ACL.REPORTS_USER_RESOURCE.TAB}
                          >
                            <div
                              className="sections-card border-bottom text-center py-3"
                              tbrentitytype={constants.ACL.ENTITY_TYPE.DIV}
                            >
                              <div className="d-flex justify-content-center">
                                {this.state.userData.is_active == 1 ? (
                                  <>
                                    <span
                                      className="material-icons-outlined text-orange mx-2 cursor-pointer"
                                      onClick={this.emailValidationConfirmation}
                                      data-for={"tool-tip-email"}
                                      data-tip={"User Bounce Validation"}
                                      data-iscapture="true"
                                    >
                                      email
                                      <CustomReactTooltip
                                        id={"tool-tip-email"}
                                      />
                                    </span>
                                    <span
                                      className="material-icons-outlined text-primary mx-2 cursor-pointer"
                                      onClick={
                                        this.sendResetPasswordLinkConfirmation
                                      }
                                      data-for={"tool-tip-resetpassword"}
                                      data-tip={"Send Reset Password Link"}
                                    >
                                      lock_reset
                                      <CustomReactTooltip
                                        id={"tool-tip-resetpassword"}
                                      />
                                    </span>
                                  </>
                                ) : this.state.userData.is_active == 0 ? (
                                  <>
                                    <span
                                      className="material-icons-outlined text-primary mx-2 cursor-pointer"
                                      onClick={
                                        this.sendResetPasswordLinkConfirmation
                                      }
                                      data-for={"tool-tip-resetpassword"}
                                      data-tip={"Send Reset Password Link"}
                                    >
                                      lock_reset
                                      <CustomReactTooltip
                                        id={"tool-tip-resetpassword"}
                                      />
                                    </span>
                                  </>
                                ) : this.state.userData.first_name &&
                                  this.state.userData.client.name &&
                                  this.state.userData.email ? (
                                  <span
                                    className="material-icons-round text-primary mx-2 cursor-pointer"
                                    onClick={
                                      this.resendWelcomeEmailConfirmation
                                    }
                                    data-for={"tool-tip-send"}
                                    data-tip={"Resend Email Verificaiton Link"}
                                  >
                                    send
                                    <CustomReactTooltip
                                      id={"tool-tip-send"}
                                    />{" "}
                                  </span>
                                ) : (
                                  "-"
                                )}
                                {this.state.userData.first_name &&
                                  this.state.userData.client.name &&
                                  this.state.userData.email && (
                                    <AclAction
                                      type={constants.ACL.PERMISSION_KEY}
                                      entity={
                                        constants.ACL.REPORTS_USER_RESOURCE
                                          .TITLE
                                      }
                                      action={
                                        constants.ACL.REPORTS_USER_RESOURCE
                                          .ACTIONS.DELETE
                                      }
                                    >
                                      <span
                                        tbrentitytype={
                                          constants.ACL.ENTITY_TYPE.BUTTON
                                        }
                                        className="material-icons-outlined text-red mx-2 cursor-pointer "
                                        onClick={() =>
                                          this.removeUserConfirmationHandler(
                                            this.state.userData.id
                                          )
                                        }
                                        data-for={"tool-tip-delete"}
                                        data-tip={"Delete"}
                                      >
                                        delete
                                        <CustomReactTooltip
                                          id={"tool-tip-delete"}
                                        />{" "}
                                      </span>
                                    </AclAction>
                                  )}
                              </div>
                              <label>Actions</label>
                            </div>
                          </AclAction>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="btn-items-card-wrapper tabs-card-wrapper">
                      <div className="btn-items-card card custom-card nav-tabs-card mb-0">
                        <div className="card-body">
                          <div className="border-bottom pb-3 mb-3">
                            <div className="d-flex">
                              <Nav
                                tabs
                                tbrentitytype={
                                  constants.ACL.ENTITY_TYPE.NAVIGATION
                                }
                                className="custom-nav-tabs reports-nav-tabs"
                              >
                                {this.state.totalTabs.map((tab) => (
                                  <NavItem key={tab.KEY}>
                                    <NavLink
                                      className={classnames({
                                        active:
                                          this.state.selectedTab === tab.KEY,
                                      })}
                                      onClick={() => {
                                        this.onContentChangeTypeHandler(
                                          tab.KEY
                                        );
                                      }}
                                    >
                                      {tab.TAB_TITLE}
                                    </NavLink>
                                  </NavItem>
                                ))}
                              </Nav>
                            </div>
                          </div>

                          <TabContent activeTab={this.state.selectedTab}>
                            {this.state.selectedTab ===
                            constants.APPLICATION_ROUTE.REPORTS.LIST3.TABS[0]
                              .KEY ? (
                              <TabPane tabId={this.state.selectedTab}>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="reports-page-cards border p-2">
                                      <h5 className="cards_heading">
                                        Sessions & Page Views
                                      </h5>
                                      <Sessions_PageViews
                                        userId={this.state.userId}
                                        startDate={this.state.start_date}
                                        endDate={this.state.end_date}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-6">
                                    <div className="reports-page-cards border p-2">
                                      <h5 className="cards_heading">Boards</h5>
                                      <BoardsChart
                                        userId={this.state.userId}
                                        startDate={this.state.start_date}
                                        endDate={this.state.end_date}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-6">
                                    <div className="reports-page-cards border p-2">
                                      <h5 className="cards_heading">
                                        Module Wise Page Views Data
                                      </h5>
                                      <ModuleWiseDataChart
                                        userId={this.state.userId}
                                        startDate={this.state.start_date}
                                        endDate={this.state.end_date}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-6">
                                    <div className="reports-page-cards border p-2">
                                      <h5 className="cards_heading">
                                        Visualization
                                      </h5>
                                      <VisualizationChart
                                        userId={this.state.userId}
                                        startDate={this.state.start_date}
                                        endDate={this.state.end_date}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </TabPane>
                            ) : (
                              <TabPane tabId={this.state.selectedTab}>
                                <div className="custom-table-block user-detail-table nowrap-table-block sort-table-block center-th-align">
                                  <DynamicTableComponentNew
                                    columnData={
                                      this.state.selectedTab ===
                                      constants.APPLICATION_ROUTE.REPORTS.LIST3
                                        .TABS[1].KEY
                                        ? tableHeader
                                        : downloadsTableHeader
                                    }
                                    rowData={this.state.currentData}
                                    count={this.state.timespentCount}
                                    colSpan={
                                      tableHeader.filter((h) => h.visibility)
                                        .length
                                    }
                                    handleSort={this.handleSort}
                                    sortColumn={this.state.sortColumn}
                                    sortOrder={this.state.sortOrder}
                                    showPagination={false}
                                  />
                                </div>
                              </TabPane>
                            )}
                          </TabContent>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </aside>
        <DailogNew
          isOpen={this.state.dailogModal}
          accept={this.dailogModalAccept}
          decline={this.dailogModalDecline}
          header={this.state.dailogModalHeader}
          content={this.state.dailogModalContent}
          config={this.state.dailogModalConfig}
          modalStyleType={this.state.dailogModalStyleType}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
    setClients: (allClients) => {
      dispatch(clients(allClients));
    },
    setUsers: (allUsers) => {
      dispatch(users(allUsers));
    },
  };
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
    user: state.user,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Reports3);
