import { Axios } from "../lib/axios";
export const HistoricalFileServices = {
  getFiles,
  uploadHistoricalFile,
  getFileById,
  removeFile,
};
function getFiles(url) {
  return Axios.get(url);
}

function uploadHistoricalFile(id, body) {
  if (id) {
    return Axios.put(`/historical-files/update/${id}`, body);
  } else {
    return Axios.post(`/historical-files/add`, body);
  }
}

function getFileById(id) {
  return Axios.get(`historical-files/download/${id}`, { responseType: "blob" });
}

function removeFile(id) {
  return Axios.delete(`historical-files/delete/${id}`);
}
