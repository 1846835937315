/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import DynamicCategoryComponent from "./DynamicCategoryComponent";
import constants from "../../Utils/constants";

export default function ListLobs(props) {
  return (
    <DynamicCategoryComponent
      title={constants.APPLICATION_ROUTE.MANAGE_LOB_AND_SUB_CATEGORIES.NAME}
      successMessage={constants.MASTER_DATA_LOB.CREATE.SUCCESS}
      editMessage={constants.MASTER_DATA_LOB.EDIT.SUCCESS}
      inActiveMessage={constants.MASTER_DATA_LOB.IN_ACTIVATE.SUCCESS}
      activeMessage={constants.MASTER_DATA_LOB.ACTIVATE.SUCCESS}
      inActivateConfirmMessage={
        constants.MASTER_DATA_LOB.INACTIVATE_LOB_CONFIRM_MESSAGE
      }
      activateConfirmMessage={
        constants.MASTER_DATA_LOB.ACTIVATE_LOB_CONFIRM_MESSAGE
      }
      typeId={1}
      maxLevel={10}
      {...props}
    />
  );
}
