import React, { Component } from "react";
import ListManageContentTransactions from "../Pages/ListManageContentTransactions";
import constants from "../Utils/constants";
import { connect } from "react-redux";
import { activeMenuTab } from "../Store/Actions/User";
import CustomBreadcrumb from "../Components/CustomBreadcrumb";
import { formatRoute } from "react-router-named-routes";
import DocumentTitle from "../Utils/DocumentTitle";

class ListMyDrafts extends Component {
  componentDidMount = () => {
    this.props.activeMenuTab(
      constants.APPLICATION_ROUTE.MANAGE_CONTENT_MY_DRAFTS.ROUTE
    );
  };

  breadcrumbs = () => {
    let breadcrumbs = [
      // {
      //   title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,
      //   link: formatRoute(constants.APPLICATION_ROUTE.DASHBOARD.ROUTE, {}),
      //   is_active: false
      // },
      {
        title: constants.APPLICATION_ROUTE.COMPANY_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.COMPANY.LIST.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE, {}),
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.MANAGE_CONTENT_MY_DRAFTS.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  render() {
    DocumentTitle(constants.MANAGE_CONTENT_MY_DRAFTS.LIST.HEADER_TITLE);
    return (
      <aside className="main_content">
        <div className="m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height">
          <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0">
            <CustomBreadcrumb breadcrumbs={this.breadcrumbs()} />
          </div>

          <div className="">
            <ListManageContentTransactions
              {...this.props}
              type={"draft"}
              pageNo={constants.PAGINATION.DEFAULT_PAGE_NO}
              pageSize={constants.PAGINATION.DEFAULT_PAGE_SIZE}
            />
          </div>
        </div>
      </aside>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
  };
}
export default connect(null, mapDispatchToProps)(ListMyDrafts);
