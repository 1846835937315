import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import HelpCategories from "../Containers/HelpCategories";
import HelpDetails from "../Containers/HelpDetails";
import { HelpServices } from "../Services/HelpServices";
import { activeMenuTab } from "../Store/Actions/User";
import constants from "../Utils/constants";
import Toast from "../Utils/Toast";
import CustomBreadcrumb from "../Components/CustomBreadcrumb";

function Help() {
  const dispatch = useDispatch();
  const [helpData, setHelpData] = useState([]);
  const [helpCategory, setHelpCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [helpDataBasedOnCategory, setHelpDataBasedOnCategory] = useState([]);
  const [innerSidebarOpen, setInnerSidebarOpen] = useState(true);
  const showtoggle = React.useRef();

  const toggleInnerSidebar = () => {
    setInnerSidebarOpen(!innerSidebarOpen);
  };

  useEffect(() => {
    dispatch(activeMenuTab(constants.APPLICATION_ROUTE.HELP.LIST.ROUTE));
    loadCategories();
  }, [dispatch]);

  useEffect(
    () => {
      dispatch(activeMenuTab(constants.APPLICATION_ROUTE.HELP.LIST.ROUTE));
      getHelpData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getHelpData = () => {
    HelpServices.getHelp()
      .then((res) => {
        let data = res.data.data;
        if (helpCategory.length > 0) {
          if (selectedCategory && selectedCategory.item === undefined) {
            setSelectedCategory({ item: helpCategory[0].item }); // selecting first category by default
            setHelpDataBasedOnCategory(
              data.filter((val) => val.category === helpCategory[0].item)
            );
          } else {
            setHelpDataBasedOnCategory(
              data.filter((val) => val.category === selectedCategory.item)
            );
          }
          setHelpData(data);
        } else {
          setHelpData(data);
          setHelpDataBasedOnCategory(
            data.filter((obj) => obj.category === "Company List")
          );
          setSelectedCategory({ item: "Company List" });
        }
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  const loadCategories = () => {
    HelpServices.getCategory()
      .then((response) => {
        setHelpCategory(response.data.data);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  const categoryClicked = (e, selectedItem) => {
    let selectedCategory = helpCategory.find(
      (category) => category.item === selectedItem
    );
    setSelectedCategory(selectedCategory);
    HelpServices.getHelpByCategory(selectedItem)
      .then((res) => {
        setHelpDataBasedOnCategory(res.data.data);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  const breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.MASTER_DATA_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.HELP.LIST.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  return (
    <div
      className={`${
        innerSidebarOpen ? "opened-innersidebar" : "closed-innersidebar"
      } main_content fixed-title-main-content`}
    >
      <div className="m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height">
        <div className="d-flex align-items-center pt-0 help-page-title-sec fixed-title-section">
          <div className="breadcrumb-mb-0">
            <CustomBreadcrumb breadcrumbs={breadcrumbs()} />
          </div>
        </div>
        <HelpCategories
          helpCategory={helpCategory}
          categoryClicked={categoryClicked}
          selectedCategory={selectedCategory}
          toggleInnerSidebar={toggleInnerSidebar}
          showtoggle={showtoggle.current}
          enableaddbutton={helpDataBasedOnCategory.length === 1 ? true : false}
        />
        <HelpDetails
          ref={showtoggle}
          helpData={helpDataBasedOnCategory}
          selectedCategory={selectedCategory}
          getHelpData={getHelpData}
        />
      </div>
    </div>
  );
}
export default Help;
