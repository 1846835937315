import { store } from "../Store/index";
import constants from "./constants";
function isRole_Acc_mgr_or_salesAdmin(role_id) {
  const state = store.getState();
  let obj =
    state.home &&
    state.home.userRoles &&
    state.home.userRoles.find((element) => element.id == role_id);
  if (obj != undefined) {
    return (
      obj.short_name === constants.USER_ROLES.ACCOUNT_MANAGER.SLUG ||
      obj.short_name === constants.USER_ROLES.SALES_ADMIN.SLUG
    );
  }
  return false;
}

export default isRole_Acc_mgr_or_salesAdmin;
