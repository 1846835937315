const jwtDecode = require("jwt-decode");

const decodeJWT = (jwt) => {
  let result;
  if (jwt && jwt !== "") {
    try {
      result = jwtDecode(jwt);
    } catch (error) {
      result = {};
    }
  } else {
    result = {};
  }
  return result;
};

export default decodeJWT;
