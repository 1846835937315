import { store } from "../Store/index";
import constants from "./constants";
function isRoleAccountManager(role_id) {
  const state = store.getState();
  let obj =
    state.home &&
    state.home.userRoles &&
    state.home.userRoles.find((element) => element.id == role_id);
  if (obj != undefined) {
    return obj.short_name === constants.USER_ROLES.ACCOUNT_MANAGER.SLUG;
  }
  return false;
}

export default isRoleAccountManager;
