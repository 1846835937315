import { map } from "lodash";

export function parseCssToObj(cssText) {
  const cssObj = {};
  const rules = cssText.split(";");

  rules.forEach((rule) => {
    const [property, value] = rule.split(":");
    if (property && value) {
      cssObj[property.trim()] = value.trim();
    }
  });

  return cssObj;
}

export function cleanUrl(url) {
  // Split URL into base and query string
  const [baseUrl, queryString] = url.split("?");

  if (!queryString) {
    return url; // No query string, return as is
  }

  // Parse query string into key-value pairs
  const params = new URLSearchParams(queryString);
  const filteredParams = {};

  // Filter out null or other falsy values
  params.forEach((value, key) => {
    if (value !== "null" && value !== "undefined" && value) {
      filteredParams[key] = value;
    }
  });

  // Use lodash to create a cleaned query string
  const newQueryString = map(
    filteredParams,
    (value, key) => `${key}=${value}`
  ).join("&");

  // Reconstruct the URL
  return newQueryString ? `${baseUrl}?${newQueryString}` : baseUrl;
}

export const findParentIdsBottomToTop = (data, targetIds) => {
  const paths = []; // Store paths for each target ID

  const searchTree = (node, targetId, path = []) => {
    if (node.id === targetId) {
      return [...path, node.id]; // Return path if target ID is found
    }

    if (node.children) {
      for (const child of node.children) {
        const result = searchTree(child, targetId, [...path, node.id]);
        if (result) return result; // Return result if found in children
      }
    }

    return null; // Return null if target not found in this branch
  };

  // Loop through each target ID and find its path
  targetIds.forEach((targetId) => {
    for (const node of data) {
      const path = searchTree(node, targetId);
      if (path) {
        paths.push(path.reverse()); // Reverse to ensure bottom-to-top order
        break;
      }
    }
  });

  return paths;
};
