import React from "react";
import CancleIcon from "./CancleIcon.svg";
import CustomReactTooltip from "../../Components/CustomReactTooltip";

function SelectedCommonDiv({
  selectedData,
  setSelectedData,
  title,
  borderBottom,
}) {
  return (
    <div
      className="selected-div"
      style={{ borderBottom: borderBottom ? "2px solid #E0E0E0" : "none" }}
    >
      <span className="selected-span">Selected {title}</span>
      <div className="selected-span-div">
        {selectedData.length
          ? selectedData.map((el, index) => {
              return el.label?.length > 20 ? (
                <label
                  className="selected-lable text rct-title mb-0"
                  key={index}
                  data-for={`tooltip-${el.label}-active`}
                  data-tip={el.label}
                >
                  {el.label.substring(0, 20) + "..."}
                  <img
                    src={CancleIcon}
                    alt="cancle-icon"
                    className="cursor-pointer"
                    onClick={() =>
                      setSelectedData(
                        selectedData.filter((i) => i.value !== el.value)
                      )
                    }
                  />

                  <CustomReactTooltip
                    id={`tooltip-${el.label}-active`}
                    multiline={true}
                  />
                </label>
              ) : (
                <>
                  <span className="selected-lable" key={el.label}>
                    {el.label}{" "}
                    <img
                      src={CancleIcon}
                      alt="cancle-icon"
                      className="cursor-pointer"
                      onClick={() =>
                        setSelectedData(
                          selectedData.filter((i) => i.value !== el.value)
                        )
                      }
                    />
                  </span>
                </>
              );
            })
          : `No Selected ${title}`}
      </div>
    </div>
  );
}

export default SelectedCommonDiv;
