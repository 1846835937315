import React, { useEffect, useState, useRef } from "react";
import { ReportsServices } from "../../Services/Reports";
import constants from "../../Utils/constants";
import ReportsChart from "../ReportsChart";
import Toast from "../../Utils/Toast";

const seriesData = [
  {
    name: "Viewed",
    field_name: "visited",
  },
  {
    name: "Created",
    field_name: "created",
  },
];

export default function VisualizationChart(props) {
  const [chartData, setChartData] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  useEffect(() => {
    const { userId, startDate, endDate, isRoleMember, isPublished } = props;
    isRoleMember
      ? setSeriesData([{ name: "Viewed", field_name: "visited" }])
      : setSeriesData([
          { name: "Viewed", field_name: "visited" },
          { name: "Created", field_name: "created" },
        ]);
    if (userId && startDate && endDate) {
      let body = {
        user_id: userId,
        start_date: startDate,
        end_date: endDate,
        page_type: constants.GA.PAGE_TYPE.DATA_VISUALIZATION,
        isRoleMember: isRoleMember,
        is_published: isPublished,
      };
      ReportsServices.getModuleViewandCreateCount(body)
        .then((res) => {
          let data = res.data.data;
          data.sort((a, b) => {
            return new Date(a.date).getTime() - new Date(b.date).getTime();
          });
          setChartData(data);
        })
        .catch((error) => {
          Toast(
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
            "error"
          );
        });
    } else {
      setChartData([]);
    }
  }, [props.startDate, props.endDate, props.userId]);

  return chartData && chartData.length ? (
    <ReportsChart
      id={constants.APPLICATION_ROUTE.REPORTS.CHARTS.VISUALIZATION_CHART.ID}
      legend_id={
        constants.APPLICATION_ROUTE.REPORTS.CHARTS.VISUALIZATION_CHART.LEGEND_ID
      }
      chartData={chartData}
      series={seriesData}
      axis_type={constants.APPLICATION_ROUTE.REPORTS.CHARTS.AXIS_TYPES.DATE}
      isStacked={false}
      isFill={false}
      showBullets={true}
      series_type={constants.APPLICATION_ROUTE.REPORTS.CHARTS.SERIES_TYPES.LINE}
    />
  ) : (
    <h4 className="text-center">No Data Available</h4>
  );
}
