import React, { useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Collapsible from "react-collapsible";

const Dailog = (props) => {
  const { isOpen } = props;

  useEffect(() => {
    if (!isOpen) {
      document.querySelector("body").classList.remove("modal-open");
    }
  }, [isOpen]);
  return (
    <Modal isOpen={isOpen} toggle={props.decline} className={"modal-confirm"}>
      <ModalHeader toggle={props.decline}>{props.header}</ModalHeader>
      <ModalBody>
        {/* Show Data inside this div  */}
        <div className="selected-items-block element-block">
          <h6 className="text-grey block-title font-weight-normal mb-0">
            Selected Items
          </h6>
          <div className="p-2 selected-items-wrapper">
            Revenue is {props.axis ? props.axis.label : "-"}
            {props.chart_json &&
              props.chart_json.map((item, index) => {
                let parentId = item.value;
                // let isSegmentSelected = category.find((item) => item.is_selected).name === "Segments";
                return (
                  <div
                    className="custom-collapser plus-minus-collapser mb-3"
                    key={index}
                  >
                    <Collapsible
                      open={true}
                      triggerTagName={"div"}
                      triggerClassName={"collapse-trigger"}
                      triggerOpenedClassName={"collapse-trigger"}
                      trigger={
                        <div className="d-flex justify-content-between align-items-center">
                          <h6 className="font-weight-semibold text-black2 mb-0">
                            {item.label}
                          </h6>
                          <span className="material-icon"></span>
                        </div>
                      }
                    >
                      {item.children && item.children.length ? (
                        item.children !== undefined &&
                        item.children.map((item, index) => {
                          return (
                            <div className="collapse-panel-content" key={index}>
                              <div className="d-flex flex-wrap align-items-center selected-items-list">
                                <div className="selected-item d-flex align-items-center companies key={item.value}">
                                  <span>{item.label}</span>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <span className="mx-5px">No Items Selected</span>
                      )}
                    </Collapsible>
                  </div>
                );
              })}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          className={"btn-info"}
          onClick={props.decline}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Dailog;
