import { Axios } from "../lib/axios";
export const ManageContentServices = {
  getManageContentTransactions,
  getManageContentTransactionContent,
  saveTransactionContent,
};
function getManageContentTransactions(url) {
  return Axios.get(url);
}
function getManageContentTransactionContent(transaction_id) {
  return Axios.get(`transaction/${transaction_id}/type/company`);
}
function saveTransactionContent(transaction_id, content, action) {
  return Axios.post(`contentTransaction/${transaction_id}/${action}`, content);
}
