import { combineReducers } from "redux";
import * as DETAILS_REF from "./reducerReference/marketLandscapeDetailsReference";

const marketLandscapeCategories = DETAILS_REF.marketLandscapeCategories;
const marketLandscapeSegments = DETAILS_REF.marketLandscapeSegments;
const marketLandscapeDetails = DETAILS_REF.marketLandscapeDetails;
const categories = DETAILS_REF.categories;
const marketLandscapeContent = DETAILS_REF.marketLandscapeContent;
const marketLandscapeTemplates = DETAILS_REF.marketLandscapeTemplates;
const marketLandscapeTransactionDetails =
  DETAILS_REF.marketLandscapeTransactionDetails;

const marketLandscapeDetailsReducer = combineReducers({
  marketLandscapeCategories,
  marketLandscapeSegments,
  marketLandscapeDetails,
  categories,
  marketLandscapeContent,
  marketLandscapeTemplates,
  marketLandscapeTransactionDetails,
});

export default marketLandscapeDetailsReducer;
