import { market_forecast_details as DETAILS } from "../Reducers/actionConstants";

const marketForecastCategories = (payload) => {
  return {
    type: DETAILS.MARKET_FORECAST_CATEGORIES,
    payload,
  };
};

const marketForecastSegments = (payload) => {
  return {
    type: DETAILS.MARKET_FORECAST_SEGMENTS,
    payload,
  };
};

const marketForecastDetails = (payload) => {
  return {
    type: DETAILS.MARKET_FORECAST_DETAILS,
    payload,
  };
};

const categories = (payload) => {
  return {
    type: DETAILS.CATEGORIES,
    payload,
  };
};

const marketForecastContent = (payload) => {
  return {
    type: DETAILS.MARKET_FORECAST_CONTENT,
    payload,
  };
};

const marketForecastTemplates = (payload) => {
  return {
    type: DETAILS.MARKET_FORECAST_TEMPLATES,
    payload,
  };
};

const marketForecastTransactionDetails = (payload) => {
  return {
    type: DETAILS.MARKET_FORECAST_TRANSACTION_DETAILS,
    payload,
  };
};

export {
  marketForecastCategories,
  marketForecastSegments,
  marketForecastDetails,
  categories,
  marketForecastContent,
  marketForecastTemplates,
  marketForecastTransactionDetails,
};
