import React, { Component } from "react";
import XLSX from "xlsx";
import constants from "../Utils/constants";

class MultipleFileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      docError: false,
      preFilledLogo: false,
    };
  }

  uploadDocument = (doc) => {
    let allowedExtensions =
      this.props.type === "ppt"
        ? /(\.ppt|\.pptx)$/i
        : /(\.xls|\.xlsx|\.xlsm|\.ppt|\.pptx|\.pdf|\.doc|\.docx|)$/i;

    const processFile = (file) =>
      new Promise((resolve, reject) => {
        if (!file || !file.name || !allowedExtensions.exec(file.name)) {
          return reject(new Error("Invalid file format"));
        }

        if (
          !this.props.isExistingUsers &&
          file.size >= constants.COMPANY_DETAILS.MAX_PPT_SIZE
        ) {
          return reject(new Error("File size exceeds limit"));
        }

        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          resolve({ name: file.name, content: reader.result });
        };
        reader.onerror = (error) => reject(error);
      });

    // one time only 5 file loading
    const promises = Object.values(doc)
      .splice(0, 5 - this.props.value.length)
      .map((file) => processFile(file));

    Promise.all(promises)
      .then((docs) => {
        this.props.onChangeHandler(docs);
      })
      .catch((error) => {
        console.error("Error uploading documents:", error);
        this.setState({ docError: true });
      });
  };

  removeDocument = (i) => {
    this.props.onChangeHandler(
      this.props.value.filter((_, index) => index !== i),
      true
    );
  };

  render() {
    let temp = this.props.value ?? [];
    return (
      <div className={"row"}>
        <div className={"col-sm-12"}>
          {temp.map((value, index) => (
            <div>
              <span
                className={`document-name text-center d-inline-flex align-items-center`}
              >
                <span>{value.name ? value.name : ""}</span>
                <span
                  className="material-icons-outlined text-grey icon-fs-16 ml-2 cursor-pointer"
                  onClick={() => this.removeDocument(index)}
                >
                  delete
                </span>
              </span>
            </div>
          ))}

          <input
            type="file"
            accept={`${
              this.props.type === "ppt"
                ? ".ppt, .pptx"
                : ".xls, .xlsx, .xlsm, .doc, .docx, .pdf, .pptx, .ppt"
            }`}
            ref={(ref) => (this.upload = ref)}
            style={{ display: "none" }}
            onChange={() => this.uploadDocument(this.upload.files)}
            onClick={(event) => {
              event.target.value = null;
            }}
            multiple={true}
          />
        </div>
        <div className={"col-sm-12 upload-buttons"}>
          <button
            className={
              "btn btn-primary d-inline-flex align-items-center icon-text-primary-btn"
            }
            type="button"
            disabled={this.props.value.length > 4}
            onClick={() => this.upload.click()}
          >
            <span className="material-icons-round icon-fs-16 mr-1">search</span>
            {"Browse"}
          </button>
        </div>
      </div>
    );
  }
}

export default MultipleFileUpload;
