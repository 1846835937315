import React, { Component } from "react";
import constants from "../Utils/constants";
import { ClientServices } from "../Services/Client";
import { formatRoute } from "react-router-named-routes";
import DailogNew from "../Components/DailogNew";
import Notification from "../Components/Notification";
import CustomDropdownList from "../Components/CustomDropdownList";
import { connect } from "react-redux";
import { clients, clientRoles } from "../Store/Actions/Client";
import DocumentTitle from "../Utils/DocumentTitle";
import IsTBRIClient from "../Utils/IsTBRIClient";
import Toast from "../Utils/Toast";
import capitalize from "../Utils/Capitalize";
import isRoleAccountManager from "../Utils/isRoleAccountManager";
import { isEmpty } from "lodash";
import AclAction from "../Components/ACL/AclAction";

class ManageClientUser extends Component {
  state = {
    clientId: "",
    userFirstName: "",
    userLastName: "",
    userEmail: "",
    userContactNumber: "",
    userAddress: "",
    userRoleId: "",
    formErrors: {},
    dailogModal: false,
    dailogModalContent: "",
    dailogModalHeader: "",
    dailogModalConfig: {},
    dailogModalStyleType: "",
    isFormSubmitted: false,
    apiError: null,
    isExistingUsers: false,
    is_set_password: 1,
    is_sso: 0,
  };
  async componentDidMount() {
    let clientId = this.props.match.params.clientId
      ? this.props.match.params.clientId
      : null;
    let userId = this.props.match.params.id ? this.props.match.params.id : null;
    DocumentTitle(
      userId === null
        ? constants.APPLICATION_ROUTE.CLIENT_USER.CREATE.HEADER_TITLE
        : constants.APPLICATION_ROUTE.CLIENT_USER.EDIT.HEADER_TITLE
    );
    if (!isRoleAccountManager(this.props.home.loginUserSuccess.role_id)) {
      ClientServices.getAllClients()
        .then((res) => {
          let response = res && res.data && res.data.data;
          response = response.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase()
              ? 1
              : b.name.toLowerCase() > a.name.toLowerCase()
              ? -1
              : 0
          );
          response = response.filter((f) => !IsTBRIClient(f));
          response.forEach((r) => {
            r.is_selected = String(r.id) === clientId ? true : false;
          });
          this.props.setClients(response);
        })
        .catch((error) => {
          Toast(
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
            "error"
          );
        });
    } else {
      ClientServices.getAssignedClients("/getAssignedClients").then((res) => {
        let response = res && res.data && res.data.data;
        response = response.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : b.name.toLowerCase() > a.name.toLowerCase()
            ? -1
            : 0
        );
        response = response.filter((f) => !IsTBRIClient(f));
        response.forEach((r) => {
          r.is_selected = String(r.id) === clientId ? true : false;
        });
        this.props.setClients(response);
      });
    }
    await ClientServices.getClientUserRoles()
      .then((res) => {
        let response = res && res.data;
        response.forEach((r) => {
          r.is_selected = false;
        });
        this.props.setClientRoles(response);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
    if (userId !== null) this.prepopulateData(userId);
    const selectedClient = this.props.client.clients.find((c) => c.is_selected);
    this.setState({
      clientId: clientId,
      is_set_password: selectedClient?.is_set_password,
      is_sso: selectedClient?.is_sso,
    });
  }
  prepopulateData = (userId) => {
    ClientServices.getClientUserDetails(userId)
      .then((res) => {
        let response = res.data;
        this.setState(
          {
            userFirstName: response.first_name ? response.first_name : "",
            userLastName: response.last_name ? response.last_name : "",
            userEmail: response.email ? response.email : "",
            userContactNumber: response.mobile ? response.mobile : "",
            userAddress: response.address ? response.address : "",
            userRoleId:
              response.role && response.role.id ? response.role.id : "",
            isFormSubmitted: false,
            apiError: null,
            is_set_password: Boolean(response?.is_set_password || 0),
            is_sso: Boolean(response?.is_sso || 0),
          },
          () => {
            this.setRole();
          }
        );
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  handleClientChange = (e) => {
    // Create a new array with updated selection
    const clients = this.props.client.clients.map((c) => ({
      ...c,
      is_selected: c.id === e.id,
    }));

    // Update clients in the parent state
    this.props.setClients(clients);

    // Find the selected client
    const selectedClient = clients.find((c) => c.is_selected) || { id: "all" };

    // Update state with selected client ID
    this.setState(
      {
        clientId: selectedClient.id,
        is_set_password: selectedClient?.is_set_password,
        is_sso: selectedClient?.is_sso,
      },
      () => {
        // Validate form if it has been submitted
        if (this.state.isFormSubmitted) {
          this.isFormValid();
        }
      }
    );
  };

  setRole = () => {
    let clientRoles = [...this.props.client.clientRoles];
    clientRoles.forEach((c) => {
      c.is_selected = c.id === this.state.userRoleId ? true : false;
    });
    this.props.setClientRoles(clientRoles);
  };

  handleRoleChange = (e) => {
    this.setState(
      {
        userRoleId: e.id,
      },
      () => {
        this.setRole();
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  handleInputChange = (e) => {
    this.setState(
      {
        ...this.state,
        [e.target.id]: e.target.value,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  isFormValid = () => {
    let formError = false;
    let userFirstNameError = false;
    let userLastNameError = false;
    let userEmailError = false;
    let userClientIdError = false;
    let userRoleIdError = false;
    let userContactNumberError = false;
    let userInviteOptionError = false;

    if (this.state.userRoleId === "" || this.state.userRoleId === null) {
      userRoleIdError = true;
      formError = true;
    }
    if (
      this.state.clientId === "" ||
      this.state.clientId === null ||
      this.state.clientId === "all"
    ) {
      userClientIdError = true;
      formError = true;
    }
    if (isEmpty(this.state.userFirstName.trim())) {
      userFirstNameError = true;
      formError = true;
    }
    if (isEmpty(this.state.userLastName.trim())) {
      userLastNameError = true;
      formError = true;
    }
    if (isEmpty(this.state.userEmail.trim())) {
      userEmailError = true;
      formError = true;
    } else {
      var re = constants.REG_EX.EMAIL_REG_EX;
      userEmailError = !re.test(String(this.state.userEmail).toLowerCase());
      if (userEmailError === true) {
        formError = true;
      }
    }
    if (this.state.userContactNumber !== "") {
      var re_phone = constants.REG_EX.PHONE_REG_EX;
      userContactNumberError = !re_phone.test(this.state.userContactNumber);
      if (userContactNumberError === true) {
        formError = true;
      }
    }

    if (!this.state.is_set_password && !this.state.is_sso) {
      userInviteOptionError = true;
      formError = true;
    }

    this.setState({
      ...this.state,
      formErrors: {
        ...this.state.formErrors,
        userRoleIdError: userRoleIdError,
        userClientIdError: userClientIdError,
        userFirstNameError: userFirstNameError,
        userLastNameError: userLastNameError,
        userEmailError: userEmailError,
        userContactNumberError: userContactNumberError,
        userInviteOptionError: userInviteOptionError,
      },
      apiError: null,
    });
    return formError;
  };
  handleSubmit = () => {
    this.setState(
      {
        isFormSubmitted: true,
      },
      () => {
        if (!this.isFormValid()) {
          let clientId = this.state.clientId;
          let userId = this.props.match.params.id
            ? this.props.match.params.id
            : null;
          let prepareObject = {
            first_name: capitalize(this.state.userFirstName),
            last_name: capitalize(this.state.userLastName),
            role_id: this.state.userRoleId,
            email: this.state.userEmail,
            mobile: this.state.userContactNumber,
            address: this.state.userAddress,
            isExistingUsers: this.state.isExistingUsers ?? false,
            is_set_password: this.state.is_set_password ?? 0,
            is_sso: this.state.is_sso ?? 0,
          };
          if (userId === null) {
            //create
            ClientServices.addClientUser(clientId, prepareObject)
              .then((res) => {
                if (res.data.error !== undefined) {
                  this.setState({
                    apiError:
                      res && res.data && res.data.error
                        ? res.data.error
                        : constants.ERROR.SOMETHING_WENT_WRONG,
                  });
                } else {
                  this.saveClientUserSuccess();
                }
              })
              .catch((error) => {
                this.setState({
                  apiError:
                    error && error.data && error.data.error
                      ? error.data.error
                      : constants.ERROR.SOMETHING_WENT_WRONG,
                });
              });
          } else {
            ClientServices.editClientUser(userId, prepareObject)
              .then((res) => {
                this.saveClientUserSuccess();
              })
              .catch((error) => {
                this.setState({
                  apiError:
                    error && error.data && error.data.error
                      ? error.data.error
                      : constants.ERROR.SOMETHING_WENT_WRONG,
                });
              });
          }
        }
      }
    );
  };
  saveClientUserSuccess = () => {
    let userId = this.props.match.params.id ? this.props.match.params.id : null;
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Success</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="activate-body">
          <h2 className="font-weight-semibold mb-0">
            {userId === null
              ? constants.CLIENT_USER.CREATE.SUCCESS
              : constants.CLIENT_USER.EDIT.SUCCESS}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: false, id: null },
      dailogModalStyleType: "success-modal",
    });
    setTimeout(
      () =>
        this.props.history.push({
          pathname: !isRoleAccountManager(
            this.props.home.loginUserSuccess.role_id
          )
            ? formatRoute(constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE, {
                clientId: this.state.clientId,
              })
            : formatRoute(constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE, {
                clientId: this.state.clientId,
              }),
          search: "?selected_tab=assigned-users",
        }),
      1500
    );
  };

  existEmailerror = () => {
    let userId = this.props.match.params.id ? this.props.match.params.id : null;
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Already exists</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {constants.CLIENT_USER.ERROR.SUCCESS}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: false, id: null },
      dailogModalStyleType: "danger-modal",
    });
    setTimeout(
      () =>
        this.props.history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE,
            { clientId: this.state.clientId }
          ),
        }),
      1500
    );
  };
  dailogModalAccept = (config) => {
    this.setState(
      {
        dailogModal: false,
        dailogModalHeader: "",
        dailogModalContent: "",
        dailogModalConfig: {},
      },
      () => {
        switch (config.type) {
          case "remove_client_user":
            this.removeClientUser();
            break;
          default:
            break;
        }
      }
    );
  };
  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };
  resetForm = () => {
    let userId = this.props.match.params.id ? this.props.match.params.id : null;
    let clientId = this.props.match.params.clientId
      ? this.props.match.params.clientId
      : null;
    this.setState({
      ...this.state,
      formErrors: {
        ...this.state.formErrors,
        userRoleIdError: false,
        userClientIdError: false,
        userFirstNameError: false,
        userLastNameError: false,
        userEmailError: false,
        userContactNumberError: false,
      },
      apiError: null,
    });
    if (userId !== null) {
      this.prepopulateData(userId);
    } else {
      this.setState({
        clientId: clientId,
        userFirstName: "",
        userLastName: "",
        userEmail: "",
        userContactNumber: "",
        userAddress: "",
        userRoleId: "",
        formErrors: {},
        dailogModal: false,
        dailogModalContent: "",
        dailogModalHeader: "",
        dailogModalConfig: {},
        isFormSubmitted: false,
        apiError: null,
      });
    }
  };
  breadcrumbs = () => {
    let userId = this.props.match.params.id ? this.props.match.params.id : null;
    let clientId = this.props.match.params.clientId
      ? this.props.match.params.clientId
      : null;
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.DASHBOARD.ROUTE, {}),
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.CLIENT_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.CLIENT.LIST.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.CLIENT.LIST.ROUTE, {}),
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.CLIENT_USER.LIST.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE, {
          clientId: clientId,
        }),
        is_active: false,
      },
      {
        title: userId
          ? constants.APPLICATION_ROUTE.CLIENT_USER.EDIT.NAME
          : constants.APPLICATION_ROUTE.CLIENT_USER.CREATE.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  //Navigate to Client Users list page
  changeRoute = () => {
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE,
        { clientId: "all" }
      ),
    });
  };

  // This is for handling handleCheckboxInviteOption for clients
  handleCheckboxInviteOption = (e) => {
    const { id, checked } = e.target;
    this.setState({
      // Update state dynamically based on checkbox ID
      [id]: checked ? 1 : 0,
    });
  };

  // Remove Client user confirmation handler
  removeUserConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {constants.CLIENT_USER.REMOVE_USER_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_client_user" },
      dailogModalStyleType: "danger-modal",
    });
  };

  removeClientUser = () => {
    ClientServices.removeClientUserByID(this.props.match.params.id)
      .then((res) => {
        let dailogModalHeader = (
          <>
            <h3 className="text-darkblue2 font-weight-bold">Success</h3>
          </>
        );
        let dailogModalContent = (
          <>
            <div className="activate-body">
              <h2 className="font-weight-semibold mb-0">
                {constants.CLIENT.REMOVE.SUCCESS}
              </h2>
            </div>
          </>
        );
        this.setState({
          dailogModal: true,
          dailogModalHeader: dailogModalHeader,
          dailogModalContent: dailogModalContent,
          dailogModalConfig: { type: false, id: null },
          dailogModalStyleType: "success-modal",
        });
        setTimeout(
          () =>
            this.props.history.push({
              pathname: formatRoute(
                constants.APPLICATION_ROUTE.CLIENT.LIST.ROUTE
              ),
            }),
          1500
        );
      })
      .catch((error) => {
        this.getTableData();
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  render() {
    let clients =
      this.props.client && this.props.client.clients
        ? this.props.client.clients
        : [];
    let roles =
      this.props.client && this.props.client.clientRoles
        ? this.props.client.clientRoles
        : [];
    let userId = this.props.match.params.id ? this.props.match.params.id : null;
    return (
      <aside className="main_content">
        <div className="m-3 mb-0 bg-white px-3 br_8 main_content_height">
          <div className="manage-client">
            <div className="">
              <div className={"add-edit-form-card"}>
                <form className={"form-wrapper"}>
                  {this.state.apiError !== null && (
                    <Notification
                      color={"danger"}
                      message={this.state.apiError}
                    />
                  )}
                  <div className="card mt-3">
                    <div className="card-header title-cancel-header d-flex align-items-center">
                      <h3 className="text-darkblue2 mb-0 font-weight-bold">
                        {userId === null
                          ? constants.APPLICATION_ROUTE.CLIENT_USER.CREATE
                              .HEADER_TITLE
                          : constants.APPLICATION_ROUTE.CLIENT_USER.EDIT
                              .HEADER_TITLE}
                      </h3>
                      <div className="ml-auto manage-delete-button">
                        {this.props.match.params.id && (
                          <AclAction
                            type={constants.ACL.PERMISSION_KEY}
                            entity={constants.ACL.CLIENT_USER_RESOURCE.TITLE}
                            action={
                              constants.ACL.CLIENT_USER_RESOURCE.ACTIONS.DELETE
                            }
                          >
                            <button
                              tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                              type="button"
                              className="btn btn-custom btn-danger btn-no-background icon-text-btn title-btn d-inline-flex align-items-center px-2 font-weight-semibold"
                              onClick={this.removeUserConfirmationHandler}
                            >
                              <span className="mt-2px">Delete User</span>
                              <span className="material-icons icon-fs-16 ml-1">
                                delete
                              </span>
                            </button>
                          </AclAction>
                        )}
                      </div>
                      <span
                        className="material-icons-round text-darkblue2 ml-4 cursor-pointer"
                        onClick={this.changeRoute}
                      >
                        close
                      </span>
                    </div>
                    <div className="card-body">
                      <div className="card-body-header">
                        <h5 className="mb-0 font-weight-semibold">
                          User Information
                        </h5>
                      </div>
                      <div className="form-field-wrapper">
                        <div className="row">
                          <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                            <div className={"row"}>
                              <div className="col-lg-6 col-sm-6 form-group position-relative">
                                <label for="companies">
                                  Client <span className={"mandatory"}>* </span>
                                </label>
                                <div
                                  className={`${
                                    this.state.formErrors.userClientIdError
                                      ? "is-invalid"
                                      : ""
                                  } custom-combobox-list`}
                                >
                                  <CustomDropdownList
                                    isDisabled={userId === null ? false : true}
                                    data={clients}
                                    handleChange={this.handleClientChange}
                                    placeholder={"Select Client"}
                                  />
                                </div>
                                {this.state.formErrors.userClientIdError && (
                                  <div className="invalid-tooltip d-block">
                                    {constants.FORM.MANDATORY_FIELD}
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-6 col-sm-6 form-group position-relative">
                                <label for="segments">
                                  Role <span className={"mandatory"}>*</span>
                                </label>
                                <div
                                  className={`${
                                    this.state.formErrors.userRoleIdError
                                      ? "is-invalid"
                                      : ""
                                  } custom-combobox-list`}
                                >
                                  <CustomDropdownList
                                    data={roles}
                                    handleChange={this.handleRoleChange}
                                    placeholder={"Select Role"}
                                  />
                                </div>
                                {this.state.formErrors.userRoleIdError && (
                                  <div className="invalid-tooltip display-block d-block">
                                    {constants.FORM.MANDATORY_FIELD}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-6 form-group position-relative">
                                <label htmlFor="userFirstName">
                                  First Name{" "}
                                  <span className={"mandatory"}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className={`form-control capitalize ${
                                    this.state.formErrors.userFirstNameError ===
                                    true
                                      ? "is-invalid"
                                      : ""
                                  } ${
                                    this.state.formErrors.userFirstNameError ===
                                    false
                                      ? ""
                                      : ""
                                  }`}
                                  id="userFirstName"
                                  placeholder="First Name"
                                  value={this.state.userFirstName}
                                  onChange={this.handleInputChange}
                                />
                                {this.state.formErrors.userFirstNameError && (
                                  <div className="invalid-tooltip">
                                    {constants.FORM.MANDATORY_FIELD}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-6 form-group position-relative">
                                <label htmlFor="userLastName">
                                  Last Name{" "}
                                  <span className={"mandatory"}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className={`form-control capitalize ${
                                    this.state.formErrors.userLastNameError ===
                                    true
                                      ? "is-invalid"
                                      : ""
                                  } ${
                                    this.state.formErrors.userLastNameError ===
                                    false
                                      ? ""
                                      : ""
                                  }`}
                                  id="userLastName"
                                  placeholder="Last Name"
                                  value={this.state.userLastName}
                                  onChange={this.handleInputChange}
                                />
                                {this.state.formErrors.userLastNameError && (
                                  <div className="invalid-tooltip">
                                    {constants.FORM.MANDATORY_FIELD}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-6 form-group position-relative">
                                <label for="userEmail">
                                  Email <span className={"mandatory"}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.formErrors.userEmailError ===
                                    true
                                      ? "is-invalid"
                                      : ""
                                  } ${
                                    this.state.formErrors.userEmailError ===
                                    false
                                      ? ""
                                      : ""
                                  }`}
                                  id="userEmail"
                                  placeholder="Email (example@domain.com)"
                                  value={this.state.userEmail}
                                  onChange={this.handleInputChange}
                                />
                                {this.state.formErrors.userEmailError && (
                                  <div className="invalid-tooltip">
                                    {constants.FORM.INVALID_EMAIL}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-6 form-group position-relative">
                                <label for="userContactNumber">
                                  Contact No.
                                </label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.formErrors
                                      .userContactNumberError === true
                                      ? "is-invalid"
                                      : ""
                                  } ${
                                    this.state.formErrors
                                      .userContactNumberError === false
                                      ? ""
                                      : ""
                                  }`}
                                  id="userContactNumber"
                                  placeholder="Contact No. (99999999)"
                                  value={this.state.userContactNumber}
                                  onChange={this.handleInputChange}
                                />
                                {this.state.formErrors
                                  .userContactNumberError && (
                                  <div className="invalid-tooltip">
                                    {constants.FORM.INVALID_CONTACT_NUMBER}
                                  </div>
                                )}
                              </div>
                              {userId === null && (
                                <div className="col-sm-6 form-group position-relative mb-3">
                                  <label htmlFor="isExistingUsers">
                                    Existing User
                                  </label>
                                  <input
                                    type="checkbox"
                                    className="custom-checkbox-new"
                                    checked={this.state.isExistingUsers}
                                    id="isExistingUser"
                                    onChange={(e) => {
                                      this.setState({
                                        isExistingUsers:
                                          !this.state.isExistingUsers,
                                      });
                                    }}
                                  />
                                  <label
                                    className="form-check-label ml-1"
                                    htmlFor="isUserCountUnlimited"
                                    style={{ fontSize: "12px" }}
                                  >
                                    Yes
                                  </label>
                                </div>
                              )}
                              <div className="col-sm-6 form-group position-relative">
                                <div className="d-flex">
                                  <label
                                    className={`${
                                      this.state.formErrors
                                        .userInviteOptionError === true
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                  >
                                    Invitation Email login options{" "}
                                    <span className={"mandatory"}>*</span>
                                  </label>
                                  {/* Display the error message and info icon if there is an error */}
                                  {this.state.formErrors
                                    .userInviteOptionError && (
                                    <>
                                      <span
                                        className="material-icons-round icon-fs-16 cursor-pointer"
                                        style={{ color: "red" }}
                                      >
                                        info
                                      </span>
                                      <span
                                        className="ml-1"
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Please select at least one option
                                      </span>
                                    </>
                                  )}
                                </div>
                                <div className="d-flex flex-column flex-sm-row align-items-center mt-2">
                                  <div className="mr-sm-2 d-flex align-items-center w-100 w-sm-auto">
                                    <input
                                      className="form-check-input custom-checkbox-new ml-0 mt-0"
                                      type="checkbox"
                                      id="is_set_password"
                                      checked={this.state.is_set_password}
                                      onChange={this.handleCheckboxInviteOption}
                                    />
                                    <label
                                      className="form-check-label ml-1"
                                      htmlFor="is_set_password"
                                      style={{ fontSize: "12px" }}
                                    >
                                      Set Password
                                    </label>
                                  </div>
                                  <div className="mr-sm-2 d-flex align-items-center w-100 w-sm-auto">
                                    <input
                                      className="form-check-input custom-checkbox-new ml-0 mt-0"
                                      type="checkbox"
                                      id="is_sso"
                                      checked={this.state.is_sso}
                                      onChange={this.handleCheckboxInviteOption}
                                    />
                                    <label
                                      className="form-check-label ml-1"
                                      htmlFor="is_sso"
                                      style={{ fontSize: "12px" }}
                                    >
                                      SSO
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-12 form-group position-relative">
                                <label for="address">Address</label>
                                <textarea
                                  className={`form-control ${
                                    this.state.formErrors.addressError === true
                                      ? "is-invalid"
                                      : ""
                                  } ${
                                    this.state.formErrors.addressError === false
                                      ? ""
                                      : ""
                                  }`}
                                  id="userAddress"
                                  placeholder="Address"
                                  value={this.state.userAddress}
                                  onChange={this.handleInputChange}
                                />
                                {this.state.formErrors.addressError && (
                                  <div className="invalid-tooltip">
                                    {constants.FORM.MANDATORY_FIELD}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-submit-buttons">
                        <div className={"row"}>
                          <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                            <div className="text-right form-buttons">
                              <button
                                type="button"
                                className="btn btn-outline-primary modal-btn"
                                onClick={this.resetForm}
                              >
                                Reset
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary modal-btn"
                                onClick={this.handleSubmit}
                              >
                                {this.props.match.params.id ? `Update` : `Save`}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <DailogNew
          isOpen={this.state.dailogModal}
          accept={this.dailogModalAccept}
          decline={this.dailogModalDecline}
          header={this.state.dailogModalHeader}
          content={this.state.dailogModalContent}
          config={this.state.dailogModalConfig}
          modalStyleType={this.state.dailogModalStyleType}
        />
      </aside>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setClients: (allClients) => {
      dispatch(clients(allClients));
    },
    setClientRoles: (allClientRoles) => {
      dispatch(clientRoles(allClientRoles));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageClientUser);
