import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import CustomReactTooltip from "./CustomReactTooltip";
import CustomEllipsis from "../Utils/CustomEllipsis";
const PublishCalendarModalDetail = ({
  showPublishCalendarModalIsOpen,
  toggle,
  classNameModalHeader,
  borderLeftStyle,
  colorIconStyle,
  deleteCalendarEvent,
  eventDetails,
  editEventDetail,
  onClose,
}) => {
  const {
    title,
    reportType,
    segment,
    fullDate,
    company,
    reportFile,
    quarter,
    authors,
  } = eventDetails;
  let truncatedEventTitle = CustomEllipsis(title, 35);
  // This change is made to hide the 'topic'
  // let truncatedEventTopic = CustomEllipsis(eventTopic, 60);
  let truncetedCompanyText = CustomEllipsis(company, 60);
  let truncatedReportFileText = CustomEllipsis(reportFile, 128);
  let truncatedAuthorName = CustomEllipsis(authors, 42);

  return (
    <Modal
      isOpen={showPublishCalendarModalIsOpen}
      toggle={toggle}
      style={{ width: "500px", height: "350px", ...borderLeftStyle }}
      className={"modal-lg modal-w-header custom-calendar-modal primary-modal"}
    >
      <ModalHeader>
        <div className={classNameModalHeader}>
          <div className="d-flex justify-content-center align-items-center">
            <span
              className="material-icons-outlined icon-fs-22"
              style={{ ...colorIconStyle }}
            >
              date_range
            </span>
            <h2 className="ml-2 mb-0">Publish Event</h2>
          </div>
          <div className="d-flex align-items-center">
            <span
              className="btn-delete-publish-calendar"
              onClick={deleteCalendarEvent}
            >
              Delete
            </span>
            <span
              onClick={onClose}
              style={{
                color: "#555555",
                float: "right",
                cursor: "Pointer",
              }}
              className="material-icons"
            >
              close
            </span>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <div class="container">
          <div class="row">
            <div data-for="eventTitle" data-tip={title || "N/A"}>
              {title.length > 35 ? (
                <CustomReactTooltip id="eventTitle" />
              ) : null}
              <h2 className="published-event-segment-text">
                {truncatedEventTitle.text}
              </h2>
            </div>
          </div>
          {reportType === "Company Research" && (
            <div class="row">
              <div className="published-event-segment-div">
                <p>{segment}</p>
              </div>
              {/* 
              // This change is made to hide the 'topic'
              <div data-for="eventTopic" data-tip={eventTopic || "N/A"}>
              {eventTopic.length > 60 ? (
                <CustomReactTooltip id="eventTopic" />
              ) : null}
              <span className="ml-1">{truncatedEventTopic.text}</span>
            </div> */}
            </div>
          )}
          <div class="row justify-content-between align-items-center">
            <span className="show-event-publish-modal-text">{fullDate}</span>
            <span
              className="btn-edit-publish-calendar"
              onClick={() => editEventDetail(eventDetails.eventId)}
            >
              Edit
            </span>
          </div>
        </div>

        <div class="container mt-3">
          <div class="row inner-publish-modal-div-bg">
            <div class="col-12">
              {reportType === "Company Research" ? (
                <>
                  <div class="row">
                    <div class="col">
                      <span>Company:</span>
                    </div>
                  </div>
                  <div class="row">
                    <div data-for="selectedCompany" data-tip={company || "N/A"}>
                      {company?.length > 60 ? (
                        <CustomReactTooltip id="selectedCompany" />
                      ) : null}
                      <h2 className="col published-event-bold-text">
                        {truncetedCompanyText.text}
                      </h2>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div class="row">
                    <div class="col">
                      <span>Report:</span>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      data-for="selectedReportFile"
                      data-tip={reportFile || "N/A"}
                    >
                      {reportFile?.length > 128 ? (
                        <CustomReactTooltip id="selectedReportFile" />
                      ) : null}
                      <h2
                        className="col published-event-bold-text"
                        style={{ paddingRight: "14px" }}
                      >
                        {truncatedReportFileText.text}
                      </h2>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div class="row inner-publish-modal-div-bg mt-2">
            <div class="col-6">
              <div class="row">
                <div class="col">
                  <span>Quarter and Year:</span>
                </div>
              </div>
              <div class="row">
                <div class="col published-event-bold-text">
                  <span>{quarter}</span>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col">
                  <span>Author:</span>
                </div>
              </div>
              <div class="row">
                <div class="col published-event-bold-text">
                  <div data-for="displayAuthorName" data-tip={authors || "N/A"}>
                    {authors.length > 42 ? (
                      <CustomReactTooltip id="displayAuthorName" />
                    ) : null}
                    <span>{truncatedAuthorName.text}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PublishCalendarModalDetail;
