import React, { Component } from "react";
import constants from "../Utils/constants";
import AclAction from "../Components/ACL/AclAction";
import { ClientServices } from "../Services/Client";
import { UserServices } from "../Services/User";
import { formatRoute } from "react-router-named-routes";
import Notification from "../Components/Notification";
import CustomDropdownList from "../Components/CustomDropdownList";
import CustomMultiSelect from "../Components/CustomMultiSelect";
import { connect } from "react-redux";
import { clients, clientRoles } from "../Store/Actions/Client";
import { getTbriUsers, segments } from "../Store/Actions/User";
import DailogNew from "../Components/DailogNew";
import DocumentTitle from "../Utils/DocumentTitle";
import IsTBRIClient from "../Utils/IsTBRIClient";
import Toast from "../Utils/Toast";
import { ProjectServices } from "../Services/Project";
import { isEmpty, isNumber } from "lodash";

const initialState = {
  clientId: "",
  projectName: "",
  projectManager: "",
  projectSegment: "",
  description: "",
  formErrors: {},
  dailogModal: false,
  dailogModalContent: "",
  dailogModalHeader: "",
  dailogModalConfig: {},
  dailogModalStyleType: "",
  isFormSubmitted: false,
  apiError: null,
  showDeleteButton: false,
  additionalSegments: [],
  projectAdditionalSegments: [],
};
class ManageClientProject extends Component {
  state = { ...initialState };
  async componentDidMount() {
    let clientId = this.props?.match?.params?.clientId
      ? this.props?.match?.params?.clientId
      : null;
    let userId = this.props?.match?.params?.id
      ? this.props?.match?.params?.id
      : null;
    DocumentTitle(
      userId === null
        ? constants.APPLICATION_ROUTE.CLIENT_PROJECT.CREATE.HEADER_TITLE
        : constants.APPLICATION_ROUTE.CLIENT_PROJECT.EDIT.HEADER_TITLE
    );
    ClientServices.getAllClients()
      .then((res) => {
        let response =
          res && res.data && res.data.data && res.data.data.length > 0
            ? res.data.data
            : [];
        response = response.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : b.name.toLowerCase() > a.name.toLowerCase()
            ? -1
            : 0
        );
        response = response.filter((f) => !IsTBRIClient(f));
        response.forEach((r) => {
          r.is_selected = String(r.id) === clientId ? true : false;
        });
        this.props.setClients(response);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
    UserServices.getTbriUsers()
      .then((res) => {
        let response =
          res && res.data && res.data.data && res.data.data.length > 0
            ? res.data.data
            : [];
        response = response?.sort((a, b) =>
          a.first_name.toLowerCase() > b.first_name.toLowerCase()
            ? 1
            : b.first_name.toLowerCase() > a.first_name.toLowerCase()
            ? -1
            : 0
        );
        response.forEach((r) => {
          r.is_selected = false;
          r.name = `${r.first_name} ${r.last_name}`;
        });
        this.props.setTbriUsers(response);
        if (userId !== null) this.prepopulateData(userId);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
    ClientServices.getSegmentDetails(1)
      .then((res) => {
        let response =
          res && res.data && res.data.data && res.data.data.length > 0
            ? res.data.data
            : [];
        response.forEach((r) => {
          r.is_selected = false;
        });
        const segmentsWithoutER = response.filter(
          (r) => !r.name.includes("Earnings Response")
        );
        this.props.setSegments(segmentsWithoutER);

        this.setState({
          additionalSegments: segmentsWithoutER?.map((r) => ({
            ...r,
            is_selected: false,
          })),
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
    this.setState({
      clientId: clientId,
    });
  }
  prepopulateData = (projectId) => {
    ProjectServices.getProjectDetails(projectId)
      .then((res) => {
        let response = res?.data?.project;
        let admin_role_id =
          this.props.home.userRoles && this.props.home.userRoles.length > 0
            ? this.props.home.userRoles.filter(
                (role) => role.short_name === "admin"
              )[0].id
            : "";
        const { segment_id, supported_segments } = response;
        const supportedSegments = supported_segments
          ? JSON.parse(supported_segments)
          : [];
        this.setState(
          {
            projectName: response?.name ? response?.name : "",
            description: response?.description ? response?.description : "",
            projectManager: response?.project_manager
              ? response?.project_manager.id
              : "",
            segments: segment_id || null,
            projectAdditionalSegments: supportedSegments,
            isFormSubmitted: false,
            apiError: null,
            showDeleteButton:
              (res?.data?.published_count === 0 &&
                res?.data?.project?.created_by ===
                  this.props.home?.loginUserSuccess?.id) ||
              this.props.home?.loginUserSuccess?.role_id === admin_role_id,
          },
          () => {
            this.setProjectManager(this.state.projectManager);
            this.setSegmentsDropdown(this.state.segments);
            this.setAdditionalSegmentsDropdown(
              this.state.projectAdditionalSegments
            );
          }
        );
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  handleClientChange = (e) => {
    let clients = [...this.props.client.clients];
    clients.forEach((c) => {
      c.is_selected = c.id === e.id ? true : false;
    });
    this.props.setClients(clients);
    let clientId = clients?.find((c) => c.is_selected === true)
      ? clients?.find((c) => c.is_selected === true).id
      : "all";
    this.setState(
      {
        clientId: clientId,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };
  handleProjectManagerChange = (e) => {
    this.setProjectManager(e.id);
  };
  setProjectManager = (id) => {
    let getTbriUsers = [...this.props.home.getTbriUsers];
    getTbriUsers.forEach((c) => {
      c.is_selected = c.id === id ? true : false;
    });
    this.props.setTbriUsers(getTbriUsers);
    let projectManagerId = getTbriUsers.find((c) => c.is_selected === true)
      ? getTbriUsers.find((c) => c.is_selected === true).id
      : "";
    this.setState(
      {
        projectManager: projectManagerId,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };
  handleSegmentsChange = (e) => {
    this.setSegmentsDropdown(e.id);
  };

  setSegmentsDropdown = (id) => {
    let segments = [...this.props.home.segments];
    segments.forEach((c) => {
      c.is_selected = c.id === id;
    });
    this.props.setSegments(segments);
    this.setState(
      {
        projectSegment: segments,
        additionalSegments: this.state.additionalSegments.filter(
          (r) => r.id != id
        ),
        projectAdditionalSegments: this.state.projectAdditionalSegments.filter(
          (r) => r != id
        ),
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };
  handleInputChange = (e) => {
    this.setState(
      {
        ...this.state,
        [e.target.id]: e.target.value,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };
  isFormValid = () => {
    let formError = false;
    let projectNameError = false;
    let projectManagerError = false;
    let userClientIdError = false;
    if (!isNumber(this.state.projectManager)) {
      projectManagerError = true;
      formError = true;
    }
    if (
      this.state.clientId === "" ||
      this.state.clientId === null ||
      this.state.clientId === "all"
    ) {
      userClientIdError = true;
      formError = true;
    }
    if (isEmpty(this.state.projectName.trim())) {
      projectNameError = true;
      formError = true;
    }
    this.setState({
      ...this.state,
      formErrors: {
        ...this.state.formErrors,
        projectNameError: projectNameError,
        projectManagerError: projectManagerError,
        userClientIdError: userClientIdError,
      },
      apiError: null,
    });
    return formError;
  };
  handleSubmit = () => {
    this.setState(
      {
        isFormSubmitted: true,
      },
      () => {
        if (!this.isFormValid()) {
          let clientId = this.state.clientId;
          let projectId = this.props?.match?.params?.id
            ? this.props?.match?.params?.id
            : null;
          let prepareObject = {
            name: this.state.projectName,
            client_id: clientId,
            project_manager_id: this.state.projectManager,
            description: this.state.description,
            segment_id: this.props.home.segments.find((t) => t.is_selected)
              ? this.props.home.segments.find((t) => t.is_selected).id
              : undefined,
            additional_segments: this.state.projectAdditionalSegments,
          };
          if (projectId === null) {
            ProjectServices.addProject(clientId, prepareObject)
              .then((res) => {
                this.saveClientProjectSuccess();
              })
              .catch((error) => {
                this.setState({
                  apiError:
                    error && error.data && error.data.error
                      ? error.data.error
                      : constants.ERROR.SOMETHING_WENT_WRONG,
                });
              });
          } else {
            ProjectServices.editProject(projectId, prepareObject)
              .then((res) => {
                this.saveClientProjectSuccess();
              })
              .catch((error) => {
                this.setState({
                  apiError:
                    error && error.data && error.data.error
                      ? error.data.error
                      : constants.ERROR.SOMETHING_WENT_WRONG,
                });
              });
          }
        }
      }
    );
  };
  removeClientProjectConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {constants.CLIENT_PROJECT.REMOVE_PROJECT_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_project" },
      dailogModalStyleType: "danger-modal",
    });
  };
  removeClientProject = () => {
    ProjectServices.removeProject(this.props.match.params.id)
      .then((res) => {
        let dailogModalHeader = (
          <>
            <h3 className="text-darkblue2 font-weight-bold">Success</h3>
          </>
        );
        let dailogModalContent = (
          <>
            <div className="activate-body">
              <h2 className="font-weight-semibold mb-0">
                {constants.CLIENT_PROJECT.REMOVE.SUCCESS}
              </h2>
            </div>
          </>
        );
        this.setState({
          dailogModal: true,
          dailogModalHeader: dailogModalHeader,
          dailogModalContent: dailogModalContent,
          dailogModalConfig: { type: false, id: null },
          dailogModalStyleType: "success-modal",
        });
        setTimeout(
          () =>
            this.props.history.push({
              pathname: formatRoute(
                constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST.ROUTE,
                { clientId: this.state.clientId }
              ),
            }),
          1500
        );
      })
      .catch((error) => {
        this.getTableData();
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  saveClientProjectSuccess = () => {
    let projectId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Success</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="activate-body">
          <h2 className="font-weight-semibold mb-0">
            {projectId === null
              ? constants.CLIENT_PROJECT.CREATE.SUCCESS
              : constants.CLIENT_PROJECT.EDIT.SUCCESS}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: false, id: null },
      dailogModalStyleType: "success-modal",
    });
    setTimeout(
      () =>
        this.props.history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST.ROUTE,
            { clientId: "all" }
          ),
        }),
      1500
    );
  };
  dailogModalAccept = (config) => {
    this.setState(
      {
        dailogModal: false,
        dailogModalHeader: "",
        dailogModalContent: "",
        dailogModalConfig: {},
      },
      () => {
        switch (config.type) {
          case "remove_project":
            this.removeClientProject();
            break;
          default:
            break;
        }
      }
    );
  };
  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };
  resetForm = () => {
    let projectId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    let clientId = this.props.match.params.clientId
      ? this.props.match.params.clientId
      : null;
    this.setState({
      ...this.state,
      formErrors: {
        ...this.state.formErrors,
        projectNameError: false,
        projectManagerError: false,
        userClientIdError: false,
      },
      apiError: null,
    });
    if (projectId !== null) {
      this.prepopulateData(projectId);
    } else {
      let projectSegment = [...this.props.home.segments];
      projectSegment.forEach((e) => {
        e.is_selected = false;
      });
      let projectManager = [...this.props.home.getTbriUsers];
      projectManager.forEach((e) => {
        e.is_selected = false;
      });
      let clients = [...this.props.client.clients];
      clients.forEach((c) => {
        c.is_selected = false;
      });
      this.setState({
        clientId: clients,
        projectName: "",
        projectManager: projectManager,
        projectSegment: projectSegment,
        description: "",
      });
    }
  };
  breadcrumbs = () => {
    let projectId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    let clientId = this.props.match.params.clientId
      ? this.props.match.params.clientId
      : null;
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.DASHBOARD.ROUTE, {}),
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.PROJECT_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST.NAME,
        link: formatRoute(
          constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST.ROUTE,
          { clientId: clientId }
        ),
        is_active: false,
      },
      {
        title: projectId
          ? constants.APPLICATION_ROUTE.CLIENT_PROJECT.EDIT.NAME
          : constants.APPLICATION_ROUTE.CLIENT_PROJECT.CREATE.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  // Navigate to Projects list page
  changeRoute = () => {
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST.ROUTE,
        { clientId: "all" }
      ),
    });
  };

  handleAdditionalSegmentsChange = (e) => {
    let selectedSegments = e.map((a) => a.id);
    this.setAdditionalSegmentsDropdown(selectedSegments);
  };

  setAdditionalSegmentsDropdown = (selectedAdditionalSegments) => {
    let segmentsList = [...this.state.additionalSegments];
    segmentsList.forEach((c) => {
      c.is_selected = selectedAdditionalSegments.includes(c.id);
    });
    this.setState({
      additionalSegments: segmentsList,
      projectAdditionalSegments: selectedAdditionalSegments,
    });
  };

  render() {
    let clients =
      this.props.client && this.props.client.clients
        ? this.props.client.clients
        : [];
    let userId = this.props.match.params.id ? this.props.match.params.id : null;
    let tbriUsers =
      this.props.home && this.props.home.getTbriUsers
        ? this.props.home.getTbriUsers
        : [];
    let segments =
      this.props.home && this.props.home.segments
        ? this.props.home.segments
        : [];
    return (
      <aside className="main_content">
        <div className="m-3 mb-0 bg-white px-3 py-2 br_8 main_content_height">
          <div className="manage-client">
            <div className="">
              <div className={"row col-lg-12 col-sm-12 col-md-12 col-12 mx-0"}>
                <form className={"col-lg-12 col-sm-12 form-wrapper"}>
                  {this.state.apiError !== null && (
                    <Notification
                      color={"danger"}
                      message={this.state.apiError}
                    />
                  )}
                  <div className="card border-0">
                    <div className="card-header title-cancel-header d-flex align-items-center bg-transparent">
                      <h3 className="text-darkblue2 mb-0 font-weight-bold">
                        {userId === null
                          ? constants.APPLICATION_ROUTE.CLIENT_PROJECT.CREATE
                              .HEADER_TITLE
                          : constants.APPLICATION_ROUTE.CLIENT_PROJECT.EDIT
                              .HEADER_TITLE}
                      </h3>
                      <div className="ml-auto manage-delete-button">
                        {this.state.showDeleteButton ? (
                          <button
                            tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                            type="button"
                            className="btn btn-custom btn-danger btn-no-background icon-text-btn title-btn d-inline-flex align-items-center px-2 font-weight-semibold"
                            onClick={
                              this.removeClientProjectConfirmationHandler
                            }
                          >
                            <span className="mt-2px">Delete Project</span>
                            <span className="material-icons icon-fs-16 ml-1">
                              delete
                            </span>
                          </button>
                        ) : (
                          this.props.match.params.id && (
                            <AclAction
                              type={constants.ACL.PERMISSION_KEY}
                              entity={constants.ACL.PROJECT_RESOURCE.TITLE}
                              action={
                                constants.ACL.PROJECT_RESOURCE.ACTIONS.DELETE
                              }
                            >
                              <button
                                tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                                type="button"
                                className="btn btn-custom btn-danger btn-no-background icon-text-btn title-btn d-inline-flex align-items-center px-2 font-weight-semibold"
                                onClick={
                                  this.removeClientProjectConfirmationHandler
                                }
                              >
                                <span className="mt-2px">Delete Project</span>
                                <span className="material-icons icon-fs-16 ml-1">
                                  delete
                                </span>
                              </button>
                            </AclAction>
                          )
                        )}
                      </div>
                      <span
                        className="material-icons-round text-darkblue2 ml-4 cursor-pointer"
                        onClick={this.changeRoute}
                      >
                        close
                      </span>
                    </div>
                    <div className="card-body">
                      <div className="card-body-header">
                        <h5 className="mb-0 font-weight-semibold">
                          Project Information
                        </h5>
                      </div>
                      <div className="form-field-wrapper">
                        <div className="row">
                          <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                            <div className={"row"}>
                              <div className="col-lg-6 col-sm-6 form-group position-relative">
                                <label for="companies">
                                  Client <span className={"mandatory"}>* </span>
                                </label>
                                <div
                                  className={`${
                                    this.state.formErrors.userClientIdError
                                      ? "is-invalid"
                                      : ""
                                  } custom-combobox-list`}
                                >
                                  <CustomDropdownList
                                    isDisabled={userId === null ? false : true}
                                    data={clients}
                                    handleChange={this.handleClientChange}
                                    placeholder={"Select Client"}
                                  />
                                </div>
                                {this.state.formErrors.userClientIdError && (
                                  <div className="invalid-tooltip d-block">
                                    {constants.FORM.MANDATORY_FIELD}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-6 form-group position-relative">
                                <label htmlFor="userFirstName">
                                  Name <span className={"mandatory"}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.formErrors.projectNameError ===
                                    true
                                      ? "is-invalid"
                                      : ""
                                  } ${
                                    this.state.formErrors.projectNameError ===
                                    false
                                      ? ""
                                      : ""
                                  }`}
                                  id="projectName"
                                  placeholder="Project Name"
                                  value={this.state.projectName}
                                  onChange={this.handleInputChange}
                                />
                                {this.state.formErrors.projectNameError && (
                                  <div className="invalid-tooltip">
                                    {constants.FORM.MANDATORY_FIELD}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-6 form-group position-relative">
                                <label htmlFor="projectManagers">
                                  Project Manager{" "}
                                  <span className={"mandatory"}>*</span>
                                </label>
                                <div
                                  className={`${
                                    this.state.formErrors.projectManagerError
                                      ? "is-invalid"
                                      : ""
                                  } custom-combobox-list`}
                                >
                                  <CustomDropdownList
                                    data={tbriUsers}
                                    handleChange={
                                      this.handleProjectManagerChange
                                    }
                                    placeholder={"Select Project Manager"}
                                  />
                                </div>
                                {this.state.formErrors.projectManagerError && (
                                  <div className="invalid-tooltip d-block">
                                    {constants.FORM.MANDATORY_FIELD}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-6 form-group position-relative">
                                <label for="userEmail">Segment</label>
                                <div
                                  className={`${
                                    this.state.formErrors.segmentsError
                                      ? "is-invalid"
                                      : ""
                                  } custom-combobox-list`}
                                >
                                  <CustomDropdownList
                                    data={segments}
                                    handleChange={this.handleSegmentsChange}
                                    placeholder={"Select Segment"}
                                  />
                                </div>
                                {this.state.formErrors.segmentsError && (
                                  <div className="invalid-tooltip">
                                    {constants.FORM.INVALID_EMAIL}
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-6 col-sm-6 form-group position-relative">
                                <label for="status">Additional Segments </label>
                                <div className={`custom-multiselect`}>
                                  <CustomMultiSelect
                                    data={this.state.additionalSegments}
                                    handleChange={
                                      this.handleAdditionalSegmentsChange
                                    }
                                    placeholder={"Select Additional Segments"}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12 form-group position-relative">
                                <label for="address">Description</label>
                                <textarea
                                  className={`form-control ${
                                    this.state.formErrors.descriptionError ===
                                    true
                                      ? "is-invalid"
                                      : ""
                                  } ${
                                    this.state.formErrors.descriptionError ===
                                    false
                                      ? ""
                                      : ""
                                  }`}
                                  id="description"
                                  placeholder="Description"
                                  value={this.state.description}
                                  onChange={this.handleInputChange}
                                />
                                {this.state.formErrors.descriptionError && (
                                  <div className="invalid-tooltip">
                                    {constants.FORM.MANDATORY_FIELD}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-submit-buttons">
                        <div className={"row"}>
                          <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                            <div className="text-right form-buttons">
                              <button
                                type="button"
                                className="btn btn-outline-primary modal-btn"
                                onClick={this.resetForm}
                              >
                                Reset
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary modal-btn"
                                onClick={this.handleSubmit}
                              >
                                {this.props.match.params.id ? `Update` : `Save`}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <DailogNew
          isOpen={this.state.dailogModal}
          accept={this.dailogModalAccept}
          decline={this.dailogModalDecline}
          header={this.state.dailogModalHeader}
          content={this.state.dailogModalContent}
          config={this.state.dailogModalConfig}
          modalStyleType={this.state.dailogModalStyleType}
        />
      </aside>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setClients: (allClients) => {
      dispatch(clients(allClients));
    },
    setClientRoles: (allClientRoles) => {
      dispatch(clientRoles(allClientRoles));
    },
    setTbriUsers: (tbriUsers) => {
      dispatch(getTbriUsers(tbriUsers));
    },
    setSegments: (segmentsList) => {
      dispatch(segments(segmentsList));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageClientProject);
