import React, { useEffect, useState, useRef } from "react";
import { ReportsServices } from "../../Services/Reports";
import constants from "../../Utils/constants";
import ReportsChart from "../ReportsChart";
import Toast from "../../Utils/Toast";
const data = [
  {
    date: "2022/02/01",
    value: 3,
  },
  {
    date: "2022/03/01",
    value: 10,
  },
  {
    date: "2022/04/01",
    value: 15,
  },
];

const seriesdata = [
  {
    name: "Time Spent",
    field_name: "value",
  },
];

export default function BoardsChart(props) {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const { userId, startDate, endDate, isPublished } = props;
    if (userId && startDate && endDate) {
      //Backend
      let body = {
        user_id: userId,
        start_date: startDate,
        end_date: endDate,
        page_type: constants.GA.PAGE_TYPE.BOARD,
        is_published: isPublished,
      };
      ReportsServices.getTimeSpentOnPage(body)
        .then((res) => {
          let data = res.data && res.data.data;
          data.forEach((el) => {
            let min = parseInt(el.value / 60);
            let sec = el.value % 60;
            el.value = ((min * 100 + sec) / 100).toFixed(2);
          });
          setChartData(data);
        })
        .catch((error) => {
          Toast(
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
            "error"
          );
        });
    } else {
      setChartData([]);
    }
  }, [props.startDate, props.endDate, props.userId]);

  return chartData && chartData.length ? (
    <ReportsChart
      id={constants.APPLICATION_ROUTE.REPORTS.CHARTS.TIME_ON_BOARDS_CHART.ID}
      chartData={chartData}
      series={seriesdata}
      axis_type={constants.APPLICATION_ROUTE.REPORTS.CHARTS.AXIS_TYPES.DATE}
      isFill={true}
      series_type={constants.APPLICATION_ROUTE.REPORTS.CHARTS.SERIES_TYPES.LINE}
    />
  ) : (
    <h4>No Data Available</h4>
  );
}
